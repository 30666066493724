<template>
	<transition appear>
		<div class="wrapper">
			<p class="clipboard-msg" v-if="urlCopySuccess">{{$t('כתובת הועתקה ללוח')}}</p>
			<!-- <fp-icon-button v-for="(button, btnId) in buttons" :key="btnId" @click="$store.commit('toggleContactModal')"
				class="btn btn-secondary-outline" :symbol="button.iconName" :iconColor="button.iconColor"
				:iconBackGroundColor="button.iconBackGroundColor" :title="button.name">
				<span>{{ button.name }}</span>
			</fp-icon-button> -->
	
				<a v-if="!apartmentOnly" target="_blank" :href="`${whatsappBaseLink}${whatsapp.phone}&text=${whatsapp.text}${whatsappEncodedUrl}`">
					<fp-icon-button 
						class="btn btn-secondary-outline" 
						:symbol="'whatsapp'" 				
						:iconBackGroundColor="'rgba(8, 187, 5, 1)'" 
		
						>
						<span>{{ $t('שתפו דירה') }}</span>
					</fp-icon-button>
				</a>
				<fp-icon-button 
					class="btn btn-secondary-outline" 
					@click="copyToClipboard"
					:symbol="'copy'" 				
					:iconBackGroundColor="'transparent'" 

					>
					<span>{{ $t('העתיקו קישור') }}</span>
				</fp-icon-button>
				<a v-if="!apartmentOnly" target="_blank" :href="`${whatsappBaseLink}${whatsapp.phone}&text=${whatsapp.text}${whatsappEncodedUrlApartmentOnly}`">
					<fp-icon-button 
						class="btn btn-secondary-outline" 
						:symbol="'whatsapp'" 				
						:iconBackGroundColor="'rgba(8, 187, 5, 1)'" 
		
						>
						<span>{{ $t('דירה בלבד') }}</span>
					</fp-icon-button>
				</a>
				<fp-icon-button 
					class="btn btn-secondary-outline" 
					@click="copyApartmentOnly"
					:symbol="'copy'" 				
					:iconBackGroundColor="'transparent'" 

					>
					<span>{{ $t('קישור דירה בלבד') }}</span>
				</fp-icon-button>
		</div>

	</transition>
</template>

<script>

import FpIconButton from "@/components/buttons/FpIconButton"
import copy from 'copy-to-clipboard'
import {mapGetters} from "vuex";

export default {
	name: "ShareLinks",
	props: {
		apartment: {
			required: false,
			type: Object,
			default: null
		},

		buttons: {
			type: Array,
			
			default: function () {
				return [
					{
						name: 'שתפו דירה',
						iconName: "whatsapp",
						iconColor: "white",
						iconBackGroundColor: "rgba(8, 187, 5, 1)"
					},
					{
						name: 'קישור דירה',
						iconName: "copy",
						iconColor: "rgba(71, 71, 71, 1)",
						iconBackGroundColor: "transparent"
					},
					{
						name: 'שתפו עמוד',
						iconName: "whatsapp",
						iconColor: "white",
						iconBackGroundColor: "rgba(8, 187, 5, 1)"
					},
					{
						name: 'קישור עמוד',
						iconName: "copy",
						iconColor: "rgba(71, 71, 71, 1)",
						iconBackGroundColor: "transparent"
					},
				]
			}

		}
	},
	components: {
		FpIconButton,

	},
	data() {
		return {
			whatsapp: {
				text: '',
				phone: ''
			},
			urlCopySuccess: false
		}
	},
	computed: {
		whatsappEncodedUrl() {
			return encodeURI(this.shareUrl)
		},
		whatsappEncodedUrlApartmentOnly() {
			return encodeURI(`${this.shareUrl}/ao`)
		},
		whatsappBaseLink() {
			if (this.isMobile) {
				return "whatsapp://send?phone="
			}
			return "http://api.whatsapp.com/send?phone="
		},
		shareUrl() {
			const baseUrl = `${window.location.protocol}//${window.location.host}`
			// console.log(this.apartment)
			// if (this.shareDialogType === 'floor') {
			// 	const floorId = this.floorsPageCurrent?.id || this.shareDialogFloor?.id
			// 	return `${baseUrl}/projects/${this.currentProjectId}/${this.shareDialogType}s/${floorId}`
			// }
			// const apartmentId = this.apartmentsPageCurrent?.id || this.shareDialogApartment?.id
			return `${baseUrl}/projects/${this.currentProjectId}/apartments/${this.apartment.id}`
		},
		isApartment() {
			return this.shareDialogType === 'apartment'
		},
		...mapGetters(['shareDialogOpen', 'isMobile', 'apartmentOnly', 'currentProjectId', 'apartmentsPageCurrent', 'shareDialogApartment', 'shareDialogFloor', 'shareDialogType'])
	},
	methods: {
		currentUrl() {
			return window.location.href
		},
		async share() {
			if (typeof (navigator) === 'object' && typeof (navigator.share) === 'function') {
				await navigator.share({
					url: this.shareUrl
				})
			}
		},
		copyToClipboard() {
			this.urlCopySuccess = copy(this.shareUrl)
			setTimeout(() => {
				this.urlCopySuccess = false
			}, 2000)
		},
		close() {
			this.$store.dispatch('shareDialogToggle', { show: false })
		},
		copyApartmentOnly() {
			this.urlCopySuccess = copy(`${this.shareUrl}/ao`)
			setTimeout(() => {
				this.urlCopySuccess = false
			}, 2000)
		},
		async shareApartmentOnly() {
			if (typeof (navigator) === 'object' && typeof (navigator.share) === 'function') {
				await navigator.share({
					url: `${this.shareUrl}/ao`
				})
			}
		}
	}
}
</script>

<style scoped lang="scss">
.wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 20rem;
	border-radius: 5px;
	align-self: flex-start;
	justify-content: space-between;

	gap: 10px 0;
	.clipboard-msg {
		position: absolute;
		top: -2rem;
		right: 0;
	}
	button {
		flex: 0 0 calc(50% - .5rem);
		/* Don't grow, don't shrink, base width of 50% */
		border-radius: 6.25rem;
		color: 1px solid var(--grey-grey-3, #B3B3B3);
		box-sizing: border-box;
		/* So the border is included in the width */
		padding: 10px;
		background: #6B6C6D;
		box-sizing: border-box;
		/* creates a 20px gap between items */
	}

	&.v-enter-active,
	&.v-leave-active {
		transition: all .3s ease;
	}

	&.v-enter-from,
	&.v-leave-to {
		margin-top: -10rem;
	}
}
</style>
