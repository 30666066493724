<template>
    <div class="floating-button" :class="{'apartment-only': apartmentOnly, 'has-bottom-bar': hasBottomBar}">
        <fp-icon-button class="contact-btn" @click="$store.commit('toggleContactModal')" symbol="chat-bubbles" symbol-size="30" title="צרו קשר"></fp-icon-button>
    </div>
</template>

<script>
import FpIconButton from "@/components/buttons/FpIconButton";
import {mapGetters} from "vuex";
export default {
    name: "FloatingContactToggle",
    components: {FpIconButton},
    computed: {
        ...mapGetters(['contactModal','apartmentOnly', 'hasBottomBar'])
    }
}
</script>

<style lang="scss">

    .floating-button {
        position: fixed;
        bottom: 25px;
        left: 15px;
        z-index: 10;

				&.has-bottom-bar {
						bottom: 90px;
				}

				&.apartment-only {
						bottom: 10px;
				}

        button {
            background-color: var(--primary);
            border-radius: 30px;
            width: 57px;
            height: 57px;
            padding: 12px;
            border: solid 1px #fff;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .is-mobile {
        .floating-button {
            left: auto;
            right: 15px;
        }
    }
</style>
