<template>
    <label class="fp-select-label" v-if="$slots.label"><slot name="label"></slot></label>
    <div class="fp-select">
        <select v-bind="$attrs" @change="change"><slot></slot></select>
        <span class="caret"><fp-icon symbol="angel-down"></fp-icon></span>
    </div>
</template>

<script>
import FpIcon from "@/components/FpIcon";
export default {
    name: "FpSelect",
    props: {
        modelValue: {
            required: false
        }
    },
    components: {FpIcon},
    methods: {
        change(e){
            this.$emit('update:modelValue', e.target.value)
        }
    }
}
</script>

<style scoped lang="scss">
.fp-select-label {
    display: block;
    margin-bottom: 0.625rem;
}
.fp-select {
    position: relative;
    display: inline-block;
    background-color: #000;
    border-radius: 4px;

    span.caret {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%) scale(.6);
        background-color: #000;
        margin-top: 2px;
				z-index: -1;
    }

    select {
        min-width: 120px;
        height: 34px;
        border: solid 1px #fff;
        border-radius: 4px;
        background-color: #000;
        color: #fff;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0 10px;

        &:focus, &:hover {
            background-color: #1C1C1C;
        }

        &:focus + span.caret,
        &:hover + span.caret {
            background-color: #1C1C1C;
        }
    }
}

.fp-side-menu-wrapper + .fp-app-wrapper {
		.fp-select {
				background-color: transparent;
				border-radius: 4px;

				span.caret {
						background-color: transparent;
				}

				select {
						border-radius: 4px;
						background-color: rgba(0, 0, 0, 0.2);
						border-color: var(--primary);
						color: #fff;

						:deep(option) {
								color: #000;
						}

						&:focus,
						&:hover {
								background-color: rgba(0, 0, 0, 0.2);
								border-color: var(--primary-darker);
						}
				}

				select[multiple] {
						padding: 0;
						height: auto;

						:deep(option) {
								color: #fff;
								padding: 5px 10px;
								&:checked {
										background-color: #4b4b4b;
								}
						}

						~ .caret {
								display: none;
						}

						&::-webkit-scrollbar {
								width: 4px;
								background-color: #aaa;
						}

						&::-webkit-scrollbar-thumb {
								background: var(--primary);
						}

						/* scrollbar design for Edge and Firefox */
						& {
								scrollbar-width: thin;
								scrollbar-color: var(--primary) #aaa;
						}
				}
		}
}

.ltr {
		.fp-select {
				span.caret {
						left: auto;
						right: 10px;
				}
		}
}
</style>
