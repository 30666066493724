<template>
  <div class="dropdown" v-click-outside="closeDropdown">
    <button class="dropdown-toggle flex between align-center" @click="isOpen = !isOpen">
      <FpNewIcon :iconName="mainIconName" class="" size="1rem" />
      <!-- <slot name="header"></slot> -->
      <!-- <span>{{selectedOption || name}} </span>
         
      <span class="caret" :class="{ flip: isOpen }"></span> -->
    </button>



    <transition name="fade">
      <div class="dropdown-menu scrollable-y" v-show="isOpen">
        <slot />
        <div class="dropdown-item" v-for="(option, optInd) in options" :key="optInd" @click="selectOption(option)">
          <FpNewIcon :iconName="option" class="" size="1rem" />
        </div>

      </div>

    </transition>
  </div>
</template>

<script>
import FpNewIcon from '@/components/buttons/FpNewIcon.vue';
export default {
  components: {
    FpNewIcon
  },
  data() {
    return {
      isOpen: false,

    }
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    mainIconName: {
      type: String,
      default: 'info'

    },
    value: {
      type: String,

    },
    onChange: {
      type: Function,
      required: true
    },
    styleObject: {
      type: Object,
      default: () => ({ width: '100%' }),  // default is an empty object
    }
  },
  methods: {
    selectOption(option) {
      // console.log(option)
      // this.selectedOption = option.label || option;
      this.isOpen = false;
      this.onChange(option)
    },
    closeDropdown() {
      this.isOpen = false;
    }
  },
  // watch:{
  //     options(){
  //       this.selectedOption = this.name
  //     },
  //     value(){
  //       this.selectedOption = this.value
  //     }
  // },
}
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;

}

.dropdown-toggle {
  position: relative;

  border-radius: 6.25rem;


  z-index: 5;
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  background-color: black;
  outline: transparent none 0;

  &:focus-visible,
  &:focus-within,
  &:focus {

    outline: transparent none 0;
  }
}


.dropdown-menu {
  position: absolute;
  color: var(--white, #FFF) !important;
  width: 100%;
  box-sizing: border-box;
  top: 50%;
  background-color: rgb(71, 71, 71);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 4;
  padding-top: 1.5rem;
  border-radius: 6.25rem;
  max-height: 30rem;

}

.dropdown-item {
  color: white;
  border-radius: 6.25rem;
  text-align: center;
  width: 100%;
  padding: .4rem;
  line-height: 1.4rem;

}

.caret {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
  color: white;
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out;
}

.caret.flip {
  transform: rotate(-135deg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}</style>
