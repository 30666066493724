<template>
  <div class="checkbox-wrapper" @click.stop="$emit('update:modelValue', !checked)">
    <span class="circle" :class="{checked: checked}" :style="{width: size + 'px', height: size + 'px'}"></span>
  </div>
</template>

<script>
export default {
    name: "FpCheckbox",
    props: {
      modelValue: Boolean,
      // checked: {
      //   type: Boolean,
      //   default: false
      // },
      size: {
        type: Number,
        default: 20
      }
    },
    data() {
        return {
            checked: false
        }
    },
    watch: {
      // checked(newVal, oldVal) {
      //     if (newVal !== oldVal) {
      //         this.$emit('update:modelValue', newVal)
      //     }
      // },
      modelValue(newVal) {
        this.checked = newVal
      }
    },
    mounted() {
        if(typeof this.modelValue !== 'undefined'){
            this.checked = this.modelValue
        }
    }

    // props: {
    //     // checked: {
    //     // type: Boolean,
    //     // default: false
    //     // },

    //     modelValue: Boolean,
	// 			inline: {
	// 					default: false,
	// 					type: Boolean
	// 			}
    // },
    // data() {
    //     return {
    //         checked: false
    //     }
    // },
    // watch: {
    //     checked(newVal, oldVal) {
    //         if (newVal !== oldVal) {
    //             this.$emit('update:modelValue', newVal)
    //         }
    //     },
    //     modelValue(newVal) {
    //       this.checked = newVal
    //     }
    // },
    // mounted() {
    //     if(typeof this.modelValue !== 'undefined'){
    //         this.checked = this.modelValue
    //     }
    // }
};
</script>

<style scoped>
.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.circle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary-gray);
  background: transparent;
  transition: all 0.3s ease-in-out;
}

.circle.checked {
  background-color: white;
  border: none;
}
</style>
