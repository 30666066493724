<template>
    <div class="tabs-wrapper" v-if="tabs && tabsLength > 1">
				<slot class="logo" name="logo"></slot>
        <ul class="tabs">
            <li v-for="(tabLabel, tabValue) in tabs" :key="tabValue" :class="{active: modelValue === tabValue}">
                <a href="javascript:void(0)" @click="change(tabValue)">{{tabLabel}}</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "FpTabs",
    props: {
        tabs: {
            required: true
        },
        modelValue: {
            required: false
        }
    },
		computed: {
			tabsLength(){
					if(this.tabs)
						return Object.keys(this.tabs).length
					return 0
			}
		},
    methods: {
        change(tab){
            this.$emit('update:modelValue', tab)
        }
    }
}
</script>

<style scoped lang="scss">
.tabs-wrapper {
    text-align: center;

		.logo {
				margin: 0 1rem;
		}
}

.tabs {
    border-bottom: solid 1px #595C5E;
    display: inline-flex;
    align-items: flex-end;
    flex-flow: row nowrap;
    font-size: 1.25rem;
    margin-top: 0.9375rem;

    li :deep(a){
        padding: 0.9375rem;
        display: block;
    }

    li {
        border-bottom: solid 2px transparent;
        margin-bottom: -1px;
    }

    li.active {
        border-bottom-color: var(--primary-light);

        :deep(a) {
            color: var(--primary-light);
        }
    }
}
</style>
