<template>
  <div :class="{ isMobile: isMobile }" class="menu-button-wrapper flex align-center" @mouseenter="onMouseenter"
    @mouseleave="onMouseleave" @click="onClick">
    <!-- v-if="showSubMenu" -->
    <transition name="list">
      <div :class="{ showSubMenu: showSubMenu }" class="sub-menus-wrapper">

        <div class="sub-menu" :class="{ active: activeSubMenuIndex == index && active }"
          v-for="(subMenu, index) in subMenus" :key="index" @click.stop="onSubMenuClick(subMenu, index)" :style="{

            transitionDelay: `${index * 0.1}s`
          }">
          <span class="subMenuText">{{ subMenu }}</span>
        </div>
      </div>
    </transition>
    <FpAnimatedCircledIcon v-if="!isMobile" :r="40" :width="100" :height="100" :active="active" :strokeColor="colors.primary" baseStrokeColor="transparent">
      <slot />
    </FpAnimatedCircledIcon>
    <slot v-else />
    <span v-if="isMobile" class="caret" :class="{ flip: active }"><fp-icon symbol="angel-down" :size="17"></fp-icon></span>
  </div>
</template>

<script>
import FpAnimatedCircledIcon from "@/components/FpAnimatedCircledIcon.vue";
import FpIcon from "@/components/FpIcon";
import { mapGetters } from "vuex";
export default {
  name: "MenuButton",
  components: { FpAnimatedCircledIcon, FpIcon },
  props: {
    subMenus: {
      default: null,
      type: Array,
    },
    buttonText: {
      default: '',
      type: String,
    },
    size: {
      default: 'regular',
      type: String,
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    activeSubMenuIndex: {
      type: Number,
      required: false,
      default: 0
    },
  },
  computed: {
    ...mapGetters(['isMobile', 'colors'])
  },
  data() {
    return {
      showSubMenu: false,
    };
  },
  methods: {
    onSubMenuClick(item, index) {
      console.log("onSubMenuClick", item)
      this.$emit('subMenuClicked', index);
    },
    onMouseenter(){
      if (!this.isMobile){
        this.showSubMenu = true
      }
    },
    onMouseleave(){
      if (!this.isMobile){
        this.showSubMenu = this.active ? true : false
      }
    },
    onClick(){
      if (this.isMobile){
        this.showSubMenu = !this.showSubMenu
      }
    }
  },
  watch: {
    active(newVal) {
      if (!newVal) this.showSubMenu = false
      // this.showSubMenu = newVal
    }

  },
};
</script>

<style scoped lang="scss">  
.menu-button-wrapper {
  width: 12rem;
  height: 10rem;
  position: relative;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  box-sizing: border-box;
  &.isMobile {
    border: 1px solid #6B6C6D;
    height: 1.7rem;
    border-radius: 6.25rem;
    width: 5.8255rem;
    

  }

}



.sub-menus-wrapper.showSubMenu {

  div {
    opacity: 1;
  }

  div:nth-child(1) {
    top: 25%;
    left: 86%;

  }

  div:nth-child(2) {
    top: 45%;
    left: 99%;
  }

  div:nth-child(3) {
    top: 65%;
    left: 86%;
  }

  div:nth-child(4) {
    top: 25%;
    left: 7.3%;

  }

  div:nth-child(5) {
    top: 45%;
    left: -3.9%;
  }

  div:nth-child(6) {
    top: 65%;
    left: 11%;
  }

}




.sub-menu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, .7);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  color: #242424;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 0.68rem;
  font-style: normal;
  font-weight: 500;
  display: flex;
  margin: .3rem;
  transform-origin: center;
  transition: all .3s ease;
  opacity: 0;
  overflow: hidden;
  

  &:hover {
    scale: 1.05;
  }
}

.sub-menu.active {
  background-color: var(--primary);
  color: white;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s cubic-bezier(.55, .085, .68, .53);
}

.list-enter-from,
.list-leave-to {
  transform: translateX(-50%);
  opacity: 0;
}

.list-move {
  transition: transform 0.5s;
}

.is-mobile {
  &.menu-button-wrapper{
    &::before{
      content: '';
      width: 3px;
      height: 3px;
      position: absolute;
      right: -2px;
      top: 50%;
      background-color: #6B6C6D;
      border-radius: 50%;
    }
  }
  span.caret {
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%) scale(.6);
    
    margin-top: 2px;
}
  .sub-menus-wrapper {
    
    position: absolute;
    top: 0;
    left: -1px;
    width: calc(100% + 2px);
    text-align: center;

    border-radius: 1.125rem;
    padding-top: 2rem;
    box-sizing: border-box;
    opacity: 0;
    pointer-events: none;

    div {
      position: relative;
      text-align: center;
      right: 0;
      top:0;
    }
    &.showSubMenu {

      border: 1px solid var(--primary);
      background-color: black;
      opacity: 1;
      pointer-events: all;
      //background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 70%,  rgba(0, 0, 0, 0) 100%);
      .sub-menu {
        position: relative;
        transform: none;
        border-radius: 0;
        border-radius: 6.25rem;
        color: white;
        margin: 0;
        width: 100%;
        background-color: transparent;
        &::after{
          content: "";
          border-bottom: 1px solid grey;
          width: 70%;
          position: absolute;
          top: 97%;
        }
        &.active {
          .subMenuText {
            color: var(--primary);
            
          }
        }

      }
    

    
    }
  }


}

.ltr {
  .is-mobile {
    .sub-menus-wrapper {    

  
      div {
        position: relative;
        text-align: center;
        right: auto;
        left: 0 !important;
        top:0;
      }
    }
  }
}
</style>
