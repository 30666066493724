<template>
		<GDialog
				v-model="dialogState"
				max-width="600"
				:background="false"
		>
				<div class="dialog">
						<close-btn @click="doDismiss"></close-btn>
						<div class="dialog-body">
								<div class="admin apartment-edit">
										<h3 v-if="exists">{{ $t('עריכת דירה') }}</h3>
										<h3 v-else>{{ $t('הוספת דירה') }}</h3>
										<div class="blocks-form">
												<fp-input
														v-model="apartmentEdit.name"
														enforce="slugify"
														:inline="false"
														:label="$t('שם')"
												></fp-input>
												<fp-input
														v-model="apartmentEdit.title"
														:inline="false"
														:label="$t('כותרת')"
												></fp-input>
												<fp-input
														v-model="apartmentEdit.floor"
														:inline="false"
														:label="$t('קומה')"
												></fp-input>
												<fp-input
														v-model="apartmentEdit.floor_name"
														:inline="false"
														:label="$t('שם קומה')"
												></fp-input>
										</div>
								</div>
						</div>
						<div class="dialog-footer">
								<fp-button
										variant="primary-outline"
										size="small"
										@click="doDismiss"
								>{{$t('סגירה')}}</fp-button>
								<fp-button
										variant="primary"
										size="small"
										@click="doSave"
								>{{$t('שמירה')}}</fp-button>
						</div>
				</div>
		</GDialog>
</template>

<script>
import { defineComponent } from 'vue'
import CloseBtn from "@/components/buttons/CloseBtn.vue";
import FpButton from "@/components/buttons/FpButton.vue";
import FpInput from "@/components/FpInput.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import {clone, different} from "@/utils"

const emptyApartment = {
		buttons: [],
		name: "",
		title: "c1",
		available: "",
		floor: null,
		floor_name: "",
		type: "",
		type_floors: "",
		type_rooms: null,
		type_thumbnail: null,
		type_building: {
				name: "",
				buttons: null
		},
		type_dimensions: {
				apartment_sm: null,
				porch_sm: null
		},
		type_direction: "דרום מערב",
		more_details: [],
		project_ids: [],
		related_products: null,
		language: "he",
		translations: [
				/*{
						language: "en",
						id: 938
				}*/
		],
		status: "publish"
}

export default defineComponent({
		name: "ApartmentEdit",
		components: {FpInput, FpButton, CloseBtn},
		props: {
				modelValue: {
						type: Boolean,
						default: false,
				},
				apartment: {
						default: () => {
								return emptyApartment
						}
				},
				dismiss: {
						type: Function,
						required: false
				},
				save: {
						type: Function,
						required: false
				}
		},
		emits: ['update:modelValue'],
		created() {
				this.apartmentEdit = JSON.parse(JSON.stringify(emptyApartment))
		},
		data(){
				return {
						apartmentEdit: null,
						apartmentInitial: null
				}
		},
		computed: {
				dialogState: {
						get() {
								return this.modelValue;
						},
						set(value) {
								this.$emit('update:modelValue', value);
						},
				},
				exists(){
						return this.apartment && this.apartment.id
				}
		},
		methods: {
				doDismiss(){
						if(different(this.apartmentInitial, this.apartmentEdit)){
								this.$dialog.addDialog({
										component: ConfirmDialog,
										props: {
												message: this.$t('לצאת מבלי לשמור שינויים ?'),
												confirm: () => {
														this.closeDialog()
												}
										}
								})
						}else{
								this.closeDialog()
						}
				},
				doSave(){
						this.dialogState = false
						if(typeof(this.save) === 'function'){
								this.save(clone(this.apartmentEdit))
						}
				},
				closeDialog(){
						this.dialogState = false
						if(typeof(this.dismiss) === 'function'){
								this.dismiss()
						}
				}
		},
		mounted() {
				this.apartmentInitial = clone(this.apartment)
				this.apartmentEdit = clone(this.apartment)
				console.log({apartment: clone(this.apartment)})
		}
})
</script>

<style scoped>

</style>
