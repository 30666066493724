<template>
		<div class="floors-nav-arrows" v-if="nextFloor || previousFloor">
				<fp-icon-button
						symbol="angel-right"
						@click="goToPreviousFloorPage"
						:disabled="!previousFloor"
						:title="$t('לקומה הקודמת')"
				></fp-icon-button>
				<div class="separator"><fp-icon symbol="dot"></fp-icon></div>
				<fp-icon-button
						symbol="angel-left"
						@click="goToNextFloorPage"
						:disabled="!nextFloor"
						:title="$t('לקומה הבאה')"
				></fp-icon-button>
		</div>
    <div class="floor-modal" v-bind="$attrs">
        <template v-if="floor">
            <div class="modal-header">
								<close-btn @click.stop="close"></close-btn>
                <div class="modal-title">
										{{floor.title}}
                </div>
            </div>
            <div class="modal-content">
								<div
										class="apt-thumbnail" :class="{clickable: true}"
										v-if="showThumbnail && floor.thumbnail"
										tabindex="1" role="button" @click="goToFloorPage"
								>
									<img :src="floor.thumbnail" :alt="floor.name">
								</div>
								<div class="apt-buttons" v-if="floor.buttons && Array.isArray(floor.buttons)">
										<fp-button
												v-for="(button, btnInd) in floor.buttons"
												:key="btnInd"
												class="active-icon"
												:class="{active: isCurrentContent(button)}"
												expand="block"
												variant="primary-outline"
												@click="contentChange(button)"
										>{{button.label}}</fp-button>
								</div>
                <div class="apt-features">
                    <hr v-if="!isMobile">
										<floor-features-list :floor="floor"></floor-features-list>
                </div>
								<template v-if="showButtons">
										<div class="social-buttons">
												<fp-icon-button @click="$store.commit('toggleContactModal')" class="btn btn-secondary-outline" symbol="phone-ring" :title="$t('דבר עם סוכן')">{{$t('דבר עם סוכן')}}</fp-icon-button>
												<fp-icon-button @click="shareFloor" class="btn btn-secondary-outline" symbol="share" :title="$t('שיתוף')">{{$t('שיתוף')}}</fp-icon-button>
										</div>
								</template>
								<div v-if="showLink" class="link-button-wrapper">
										<fp-button @click="goToFloorPage">{{$t('מעבר לקומה')}}</fp-button>
								</div>
            </div>
        </template>
    </div>
</template>

<script>
import CloseBtn from "@/components/buttons/CloseBtn";
import {mapGetters, mapState} from "vuex";
import FpButton from "@/components/buttons/FpButton";
import FpIconButton from "@/components/buttons/FpIconButton";
import {delay} from "@/utils"
import FpIcon from "@/components/FpIcon";
import FloorFeaturesList from "@/components/FloorFeaturesList.vue";

export default {
    name: "FloorModal",
    props: {
        floor: {
            required: false,
            type: Object,
            default: null
        },
				nextFloor: {
						required: false,
						type: Object,
						default: null
				},
				previousFloor: {
						required: false,
						type: Object,
						default: null
				},
				showButtons: {
						type: Boolean,
						default: true
				},
				showLink: {
						type: Boolean,
						default: false
				},
				navigateOnButtonClick: {
						type: Boolean,
						default: false
				},
				showThumbnail: {
						type: Boolean,
						default: false
				}
    },
		emits: ['close', 'floor-clicked', 'next-floor-clicked', 'previous-floor-clicked'],
    components: {
				FloorFeaturesList,
				FpIcon,
        FpIconButton,
        FpButton,
        CloseBtn
    },
    computed: {
        ...mapState(['currentProjectId', 'floorContent']),
        ...mapGetters(['isMobile', 'isDesktop', 'isTablet'])
    },
    watch: {
        floor(floor){
						if(!this.isMobile){
								if(floor && floor.buttons && Array.isArray(floor.buttons) && floor.buttons[0]){
										this.contentChange(floor.buttons[0])
								}else if(this.floorContent){
										this.$store.commit('floorContent', null)
								}
						}
        }
    },
    data(){
        return {
            content: null
        }
    },
    methods: {
        close(){
						if(this.$route.name !== 'floors'){
								this.$router.push({name: 'floors', params: {id: this.currentProjectId}})
						}
						this.$emit('close')
        },
        isCurrentContent(button){
            return JSON.stringify(this.floorContent) === JSON.stringify(button);

        },
        async contentChange(content){
            if(this.floorContent){
                this.$store.commit('floorContent', null)
                await delay(300)
            }
            this.$store.commit('floorContent', content)
						if(this.navigateOnButtonClick){
								await this.$router.push({name: 'floor', params: {projectId: this.currentProjectId, floorId: this.floor.id}})
						}
        },
        shareFloor(){
            this.$store.dispatch('shareDialogToggle', {show: true, floor: this.floor})
        },
				goToFloorPage(){
					this.$emit('floor-clicked', this.floor)
				},
				goToNextFloorPage(){
						if(this.$route.name === 'floors'){
							this.$emit('next-floor-clicked', this.nextFloor)
						}
				},
				goToPreviousFloorPage(){
						if(this.$route.name === 'floors'){
								this.$emit('previous-floor-clicked', this.previousFloor)
						}
				}
    },
    mounted() {
        // console.log(this.floor)
    }
}
</script>

<style scoped lang="scss">
.floors-nav-arrows {
		width: 311px;
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
		padding: .5rem .5rem;
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.79) 0%, #000000 78.65%, #000000 100%);

		.separator svg {
				width: 10px;
		}
}
.floor-modal {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.79) 0%, #000000 78.65%, #000000 100%);
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    padding: 0.9375rem;
    width: 311px;
    position: relative;

    .social-buttons {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        flex-flow: row nowrap;
        gap: .8rem;
        align-items: center;

        button {
            color: #fff;
            border-radius: 5px;
						min-height: 0;
						font-size: 0.875rem;
						padding: 4px;
        }
    }

    .modal-header {
        padding-bottom: 0.9375rem;
				display: flex;
				flex-flow: row-reverse;
				justify-content: space-between;
				align-items: center;
				gap: 5px;

				.modal-title {
						display: flex;
						align-items: center;
				}
    }

    hr {
        border-color: #56595A;
        margin: 0;
    }

    .apt-price {
        padding: 0.9375rem 0.625rem;

        :deep(span.woocommerce-Price-currencySymbol) {
            margin: 0 0.3125rem;
        }
    }

		.link-button-wrapper {
				text-align: center;
				margin-top: 10px;
		}

		.apt-thumbnail {
				margin-bottom: 1.5rem;
		}

		.clickable {
				cursor: pointer;
		}
}

.is-mobile {
    .floor-modal {
        background-color: #000;

				.modal-header {
						flex-flow: row;
				}

        .modal-content {
            display: flex;
            flex-flow: column;

						.apt-buttons {
								display: grid;
								grid-template-columns: 1fr 1fr;
								gap: 15px;
								width: 100%;
								padding: 5px 0 10px;

								button {
										margin-bottom: 0;
								}
						}

						.apt-features {
								margin: 10px 0 5px;
								padding: 15px 0;
						}
        }

        .apt-price {
            border: solid 1px #B7B6B6;
            border-radius: 5px;
        }
    }
}

.fp-side-menu-wrapper + .fp-app-wrapper {

		.floor-modal {
				display: flex;
				flex-flow: column;
				border: none;
				border-radius: 0;

				.modal-content {
						display: flex;
						flex-flow: column;
						justify-content: flex-start;
						flex-grow: 1;

						> .apt-features:first-child {
								margin-top: calc(10vh);
						}
				}

				.apt-buttons,
				.social-buttons {
						display: grid;
						grid-template-columns: 1fr 1fr;
						column-gap: 10px;
				}

				.social-buttons {
						padding: 4rem 1rem 0;
						margin-top: 0;

						button.btn.btn-secondary-outline {
								padding: 4px;
								min-height: 0;
						}
				}


				/*.apt-buttons {
						margin: auto 0;
						flex-grow: 1;
						display: flex;
						flex-flow: column;
						align-items: stretch;

						.fp-nf-btn.expand-block {
								display: block;
						}

						.fp-nf-btn {
								color: #fff;
								font-size: 1.125rem;
								text-align: start;
								position: relative;
						}

						.fp-nf-btn::before {
								content: " | ";
								color: var(--primary);
								visibility: hidden;
								position: absolute;
								left: 100%;
								top: 0;
						}

						.fp-nf-btn.active,
						.fp-nf-btn:hover {
								font-weight: 500;
						}

						.fp-nf-btn.active::before,
						.fp-nf-btn:hover::before{
								visibility: visible;
						}
				}*/
		}

}
</style>
