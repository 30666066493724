<template >
    <div class="page privacyPage">
        <div class="tabs" v-if="project">
            <div class="flex">
                <div class="tab cursor-pointer" v-for="(tab, tabIndex) in project.legal_and_terms" :key="tabIndex" :class="{active: currentTab == tab}"
                    @click="currentTab = tab">
                    {{ $t(tab.title) }}
                </div>
            </div>
        </div>
        <div class="textWrapper" v-if="currentTab">
            <!-- <div class="header">           
                {{ currentTab.header }}
            </div> -->
            <div class="text scrollable-y" v-html="currentTab.content" />
                <!-- {{ currentTab.content }} -->

        </div>
</div></template>
<script>
// import { mapGetters } from "vuex"

export default {
    name: 'PrivacyAgreement',
    data() {
        return {
            currentTab: null
        }
    },
    computed: {
				project() {
					return this.$store.getters.currentProject
				},
    },
    mounted() {
				if(this.project && this.project.legal_and_terms.length > 0){
						this.currentTab = this.project.legal_and_terms[0]
				}
		},
}
</script>
<style scoped lang="scss">
    .privacyPage {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;  
        .tabs{
            border: 1px solid #B3B3B3;
            border-radius: 6.25rem;
            width: 25.625rem;
            height: 2.8125rem;
            flex-shrink: 0;
            margin-top: 6rem;
            .flex{
                height: 100%;
                align-items: center;
            }
            .tab{
                border-radius: 6.25rem;
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                &.active {
                    background-color: var(--primary);
                }
            }
    
        }

        .textWrapper {
            width: 60%;
            margin-top: 2rem;
            .header {
                font-size: 3rem;
                font-weight: 500;
                line-height: 3.75rem; /* 100% */
                margin-top: 3rem;
            }
            .text {
                font-size: 0.9375rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                max-height: 70vh;
            }

        }

    }
    .is-mobile{
        .privacyPage {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center; 
            padding: 0 1rem;
            .tabs{
                width: 90%;
               // margin-top: 0;
            }
            .textWrapper {
                width: 100%;
                .header {
                    font-size: 2rem;
                    font-weight: 500;
                    line-height: 2.2rem; /* 100% */
                    margin-top: 3rem;
                }
                .text {
                    margin-top: 1rem;
                    font-size: 1.2rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 2rem;
                }
    
            }
        }
    }
</style>    
