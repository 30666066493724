<template>
	<div class="admin projects-admin">
			<h3>{{ $t('ניהול פרוייקטים') }}</h3>
	</div>
</template>

<script>
export default {
		name: "ProjectsAdmin"
}
</script>

<style scoped>

</style>
