<template>
  <div class="dropdown" v-click-outside="closeDropdown">
    <button class="dropdown-toggle flex between align-center" @click="isOpen = !isOpen">
        <span :class="{selected: !!selectedOption}">{{selectedOption || name}} </span>
        <span class="caret" :class="{ flip: isOpen }"></span>
    </button>
    <div v-if="isOpen" class="dropdown-menu-connector"></div>
    <div
        class="dropdown-menu scrollable-y"
        v-show="isOpen"
        :style="styleObject"
    >
      <button class="dropdown-item" v-for="(option, optInd) in optionsWithEmpty" :key="optInd" @click="selectOption(option)">
        {{option.label || option}}
      </button>
    </div>
  </div>
</template>
<script>
export default {
		data() {
				return {
						isOpen: false,
						selectedOption: null,
				}
		},
		emits: ['change'],
		props: {
				options: {
						type: Array,
						required: true
				},
				name: {
						type: String,

				},
				value: {
						type: String,

				},
				styleObject: {
						type: Object,
						default: () => ({width: '100%'}),  // default is an empty object
				},
        emptyOption: {
          type: Boolean,
          default: false
        }
		},
		methods: {
				selectOption(option) {
						// console.log(option)
						this.selectedOption = option.label || option;
						this.isOpen = false;
						this.$emit('change', option)
				},
				closeDropdown() {
						this.isOpen = false;
				}
		},
    computed: {
      optionsWithEmpty() {
        if(this.emptyOption && this.name){
          return [{label: this.name, value: null}, ...this.options]
        }
        return this.options
      }
    },
		watch: {
				value() {
						this.selectedOption = this.value
				}
		},
    mounted() {
      if(!this.selectedOption && this.value){
        this.selectedOption = this.value
      }
    }
}
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-toggle {
  position:relative;
  color: #fff;
  border-radius: 6.25rem;
  border: 1px solid rgba(132, 132, 132, 0.90);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  background-color: black;
  outline: transparent none 0;
  z-index: 1;

    &:focus-visible,
    &:focus-within,
    &:focus {
        
        outline: transparent none 0;
    }

    span.selected {
      background-color: var(--primary);
    }

    span:not(.caret){
      border-radius: 20px;
      display: block;
      width: calc(100% - 30px);
      text-align: start;
      padding: 0 10px;
    }
}
.dropdown-toggle:hover {
  border: 1px solid rgba(132, 132, 132, 1);
}

.dropdown-menu-connector {
  position: absolute;
  height: 23px;
  background-color: #474747;
  margin-top: -15px;
  margin-bottom: -1px;
  z-index: 0;
  width: 100%;
}

.dropdown-menu {
  position: absolute;
  z-index: 2;
  color: #fff !important;
  width: 100%;
  box-sizing: border-box;
  top: 100%;
  background-color: rgb(71, 71, 71);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  padding: .5rem;
  border-radius: 0 0 6px 6px;
}
.dropdown-item{
    color: white;
    border-radius: 6.25rem;
    text-align: center;
    width: 100%;
    padding: .4rem;
    line-height: 1.4rem;
    &:hover {
      background-color: var(--primary)
    }
}
.caret {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
  color:white;
  transform: rotate(45deg);
  transition: transform 0.3s ease-in-out;
}

.caret.flip {
  transform: rotate(-135deg);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
