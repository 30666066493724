<template>
  <Slider
    class="fullWidth"
    v-model="sliderValue"
    :step="step"
    @change="updateValue"
  />
</template>

<script>
  import Slider from '@vueform/slider'

  export default {
    components: { Slider },
    props: {
        value: {
            type: Array,
            default: function(){
                return [1, 100]
            },
        },
        step: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            sliderValue: this.value, 
        };
    },
    methods: {
        updateValue(value) {
            this.$emit('update:value', value);
        }
    },
    watch: {
        value: function(newVal) {
            this.sliderValue = newVal;
        }
    }
  }
</script>
<style src="@vueform/slider/themes/default.css">

</style>