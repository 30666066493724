<template>
    <div class="loginWrapper">

        <!-- <label for="email">Email:</label> -->
        <input class="fullWidth text-align-right mb-1" v-model="email" type="email" id="email" placeholder="Email">
        <!-- <p v-if="!isEmailValid && email" style="color: red;">Invalid email format</p> -->

        <div class="flex align-center justify-between mt-1">
            <div class="w-75">
                <!-- <label for="password">Password:</label> -->
                <input v-model="password" type="password" id="password" placeholder="Password">
            </div>
            <FpNewIcon iconName="login" class="circledIcon cursor-pointer" size="2.2rem" />
        </div>


        <div class="rememberMe text-align-right flex align-center">
            <input type="checkbox" id="rememberMe" v-model="rememberMe">
            <label for="rememberMe">{{ $t('זכור אותי') }}</label>
        </div>

    </div>
</template>
  
<script>
import FpNewIcon from "@/components/buttons/FpNewIcon.vue"
export default {
    components: {
        FpNewIcon
    },
    data() {
        return {
            email: '',
            password: '',
            rememberMe: false
        };
    },
    computed: {
        isEmailValid() {
            const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return pattern.test(this.email);
        }
    },
    methods: {
        login() {
            if (this.email && this.isEmailValid && this.password) {
                console.log("Email:", this.email, "Password:", this.password, "Remember Me:", this.rememberMe);
                // Typically, send the email, password, and rememberMe flag to your backend for authentication.
            } else if (!this.isEmailValid) {
                console.error("Invalid email format!");
            } else {
                console.error("Both email and password fields are required!");
            }
        }
    }
};
</script>
  
<style scoped lang="scss">
.loginWrapper {
    input {
        border-radius: 6.25rem;
        border: 1px solid #FFF;
        height: 2.2rem;
        background-color: transparent !important;
        padding-right: .5rem;
        padding-left: 0;
    
    }
    
    .rememberMe {
        font-size: .8rem;
    
    }

}

.ltr {
    input {
        text-align: left;
        padding-right: 0;
        padding-left: .5rem;
    }
    .circledIcon {
        rotate: 180deg;
    }
}
</style>
  