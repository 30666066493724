<template>
		<div class="related-apartments">
				<div class="related-title">נכסים שאולי יעניינו אותך</div>
				<Swiper @afterInit="sliderInit"
								@slideChange="slideChange"
								:navigation="false"
								:autoHeight="false"
								:dots="true"
								:centeredSlides="isMobile"
								:slidesPerView="isMobile ? 1.2 : 'auto'"
								:spaceBetween="isMobile ? 12 : 0"
				>
						<SwiperSlide v-for="(apartment, index) in apartments" :key="index">
								<div class="related-apt">
										<apt-box
												:apt="apartment"
												:navigate-on-click="true"
												:show-compare="false"
												:show-details="false"
										></apt-box>
								</div>
						</SwiperSlide>
				</Swiper>
				<ul v-if="!isMobile" class="dots-nav">
						<li v-for="index in this.slidesCount" :key="index">
								<button @click="slideTo(index - 1)" type="button" class="dot" :class="{current: (currentSlideIndex + 1) === index}">{{index}}</button>
						</li>
				</ul>
		</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper';
import 'swiper/css';
import {mapGetters} from "vuex";
import AptBox from "@/components/AptBox";
SwiperCore.use([Autoplay, Navigation, Pagination]);

export default {
		name: "RelatedApartments",
		components: {
				AptBox,
				Swiper,
				SwiperSlide
		},
		props: {
				apartments: {
						required: false,
						default: null,
						type: Array
				}
		},
		computed: {
				showThumbnail(){
						return this.apartmentThumbnailDisplay && this.apartmentThumbnailDisplay.indexOf("related") > -1
				},
				...mapGetters(['isMobile', 'apartmentThumbnailDisplay'])
		},
		data(){
				return {
						swiper: null,
						nextDisabled: false,
						prevDisabled: false,
						slidesCount: null,
						currentSlideIndex: 0,
				}
		},
		methods: {
				sliderInit(swiper){
						this.swiper = swiper
						this.updateStatus()
				},
				updateStatus(){
						this.nextDisabled = false
						this.prevDisabled = false
						this.prevDisabled = this.swiper.isBeginning
						this.nextDisabled = this.swiper.isEnd
						this.currentSlideIndex = this.swiper.activeIndex
						this.slidesCount = this.swiper.slides.length
				},
				slideTo(index){
						if(this.swiper){
								this.swiper.slideTo(index)
								this.updateStatus()
						}
				},
				slideChange(e){
						if(e && typeof(e.activeIndex) === 'number'){
								if(this.currentSlideIndex !== e.activeIndex){
										this.currentSlideIndex = e.activeIndex
								}
						}
				}
		}
}
</script>

<style scoped lang="scss">
.related-apartments {
		margin: 20px 0;
		position: relative;
		padding-bottom: 45px;

		.related-title {
				margin-bottom: 15px;
				text-align: center;
		}

		.related-apt :deep(.apt-box){
				margin-left: auto;
				margin-right: auto;
		}

		.dots-nav {
				list-style: none;
				display: flex;
				gap: 10px;
				position: absolute;
				bottom: 10px;
				z-index: 10;
				left: 50%;
				transform: translateX(-50%);

				.dot {
						width: 15px;
						height: 15px;
						background-color: #000;
						border: solid 1px #fff;
						border-radius: 15px;
						opacity: .6;

						&.current {
								opacity: 1;
						}
				}
		}
}
</style>
