<template>
    <!-- <div class="gallery-container"> -->
      <transition name="fade" mode="in-out">
        
        <img :src="images[currentIndex]" :key="currentImageKey" alt="Gallery Image" 
            :class="{rounded: rounded}"/>
      </transition>
      <!-- <button @click="previousImage">Previous</button>
      <button @click="nextImage">Next</button> -->
    <!-- </div> -->
  </template>
  
  <script>
  export default {
    props: {
      images: {
        type: Array,
        required: true,
      },
      intervalDuration: {
        type: Number,
        default: 3000,
      },
      rounded: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        currentIndex: 0,
        interval: null,
        lastUpdate: Date.now(),
      };
    },
    computed: {
      currentImageKey() {
        return `${this.images[this.currentIndex]}?${this.lastUpdate}`;
      }
    },
    methods: {
      nextImage() {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
        this.lastUpdate = Date.now();
      },
      previousImage() {
        this.currentIndex =
          (this.currentIndex - 1 + this.images.length) % this.images.length;
        this.lastUpdate = Date.now();
      },
      startAutoSlide() {
        this.interval = setInterval(this.nextImage, this.intervalDuration);
      },
      stopAutoSlide() {
        clearInterval(this.interval);
        this.interval = null;
      },
    },
    mounted() {
      this.startAutoSlide();
    },
    beforeUnmount() {
      this.stopAutoSlide();
    },
  };
  </script>
  
  <style scoped lang="scss">
  .gallery-container {
    position: relative;
    width: 300px;
    height: 300px;
    overflow: hidden;
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    object-fit: cover;
    transition: opacity 1s;
    &.rounded{
        border-radius: 50%;
    }
  }
  
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-to, .fade-leave-from {
    opacity: 1;
  }
  </style>
  