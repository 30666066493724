<template>
    <div class="admin login modal">
        <div class="modal-header">
            <div class="modal-title">{{$t('התחברות')}}</div>
        </div>
        <form @submit.prevent="send">
            <fp-input
								name="username"
								:inline="false"
								:placeholder="$t('שם משתמש')"
								v-model="credentials.username"
						></fp-input>
            <fp-input
								name="password"
								type="password"
								:inline="false"
								:placeholder="$t('סיסמה')"
								v-model="credentials.password"
						></fp-input>
            <fp-button
								type="submit"
								variant="primary-outline"
						>{{$t('התחברות')}}</fp-button>
        </form>
    </div>
</template>

<script>
import FpButton from "@/components/buttons/FpButton";
import FpInput from "@/components/FpInput";
import {mapActions} from "vuex";
export default {
    name: "LoginPage",
    components: {FpButton, FpInput},
    data(){
        return {
            credentials: {
                username: null,
                password: null
            }
        }
    },
    methods: {
        async send(){
            const {user, token} = await this.login(this.credentials)
            if(user && token){
                    await this.$router.push('/fp-admin/apartments')
            }
        },
				...mapActions(['login'])
    }
}
</script>

<style scoped>

</style>
