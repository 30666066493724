<template>
	<ul class="middle-menu">
		<template v-for="(menuItem, itemIndex) in menu" :key="itemIndex">
			<li class="menu-item" :class="{
				active: isCurrent(menuItem)
			}">
				<Popper v-if="!isMobile" arrow hover :content="showTitles ? '' : menuItem.label" placement="left">
					<router-link v-if="menuItem.link === '/'" :to="`/`" @click.stop="handleMenuItemClick(menuItem)"
						v-slot="{ isExactActive, isActive }">
						<FpAnimatedCircledIcon :r="radius" :width="circleSize" :height="circleSize" :hoverMode="false"
							:hoverColor="showTitles ? null : '#6149F9'" :strokeColor="colors.primary" baseStrokeColor="#B3B3B3"
							:active="isActive">
							<fp-icon v-if="menuItem.animated_icon" :animated="menuItem.animated_icon" target=".menu-item"
								size="32"></fp-icon>
							<menu-icon v-else-if="menuItem.icon" :src="menuItem.icon" :src-hover="menuItem.icon_active"
								:alt="menuItem.label" :class="{ active: isExactActive || isActive }"></menu-icon>
							<transition>
								<span v-if="showTitles" class="m-label">{{ menuItem.label }}</span>
							</transition>
						</FpAnimatedCircledIcon>

					</router-link>
					<router-link v-else-if="menuItem.link" :to="`/projects/${currentProjectId}/${menuItem.link}`"
						@click.stop="handleMenuItemClick(menuItem)">

						<FpAnimatedCircledIcon :r="radius" :width="circleSize" :height="circleSize" :hoverMode="false"
							:active="isCurrent(menuItem)" :hoverColor="showTitles ? null : '#6149F9'" :strokeColor="colors.primary"
							baseStrokeColor="#B3B3B3">
							<fp-icon v-if="menuItem.animated_icon" :animated="menuItem.animated_icon" target=".menu-item"
								size="32"></fp-icon>
							<menu-icon v-else-if="menuItem.icon" :src="menuItem.icon" :src-hover="menuItem.icon_active"
								:alt="menuItem.label"></menu-icon>
							<transition>
								<span v-if="showTitles" class="m-label">{{ menuItem.label }}</span>
							</transition>
						</FpAnimatedCircledIcon>
					</router-link>
					<router-link v-else :to="`/projects/${currentProjectId}`"
						@click.stop="handleMenuItemClick(menuItem)">
						<FpAnimatedCircledIcon :r="radius" :width="circleSize" :height="circleSize" :hoverMode="false"
							:hoverColor="showTitles ? null : '#6149F9'" :active="isCurrent(menuItem)" :strokeColor="colors.primary"
							baseStrokeColor="#B3B3B3">
							<menu-icon v-if="menuItem.icon" :src="menuItem.icon" :src-hover="menuItem.icon_active"
								:alt="menuItem.label"></menu-icon>
							<transition>
								<span v-if="showTitles" class="m-label">{{ menuItem.label }}</span>
							</transition>
						</FpAnimatedCircledIcon>

					</router-link>

				</Popper>
				<div v-else class="mobileMenuItem">
					<router-link v-if="menuItem.link === '/'" :to="`/`" @click.stop="handleMenuItemClick(menuItem)"
						v-slot="{ isExactActive, isActive }">

						<fp-icon v-if="menuItem.animated_icon" :animated="menuItem.animated_icon" target=".menu-item"
							size="32"></fp-icon>
						<menu-icon v-else-if="menuItem.icon" :src="menuItem.icon" :src-hover="menuItem.icon_active"
							:alt="menuItem.label" :class="{ active: isExactActive || isActive }"></menu-icon>
						<transition>
							<span v-if="showTitles" class="m-label">{{ menuItem.label }}</span>
						</transition>


					</router-link>
					<router-link v-else-if="menuItem.link" :to="`/projects/${this.currentProjectId}/${menuItem.link}`"
						@click.stop="handleMenuItemClick(menuItem)">


						<fp-icon v-if="menuItem.animated_icon" :animated="menuItem.animated_icon" target=".menu-item"
							size="32"></fp-icon>
						<menu-icon v-else-if="menuItem.icon" :src="menuItem.icon" :src-hover="menuItem.icon_active"
							:alt="menuItem.label"></menu-icon>
						<transition>
							<span v-if="showTitles" class="m-label">{{ menuItem.label }}</span>
						</transition>

					</router-link>
					<router-link v-else :to="`/projects/${this.currentProjectId}`"
						@click.stop="handleMenuItemClick(menuItem)">
						<menu-icon v-if="menuItem.icon" :src="menuItem.icon" :src-hover="menuItem.icon_active"
							:alt="menuItem.label"></menu-icon>
						<transition>
							<span v-if="showTitles" class="m-label">{{ menuItem.label }}</span>
						</transition>


					</router-link>
				</div>
			</li>
		</template>
	</ul>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex"
// import FpNewIcon from "@/components/buttons/FpNewIcon.vue"
import FpIcon from "@/components/FpIcon.vue"
import FpAnimatedCircledIcon from "@/components/FpAnimatedCircledIcon.vue"
import MenuIcon from "@/components/MenuIcon.vue"
import Popper from "vue3-popper";
const radius = 50 * 0.85
const circelSize = 120 * 0.85
const smallRadius = 50 / 3.3 * 0.90
const smallCircelSize = 120 / 3.3 * 0.90
export default {
	name: "MenuBarMiddle",
	components: { FpIcon, MenuIcon, FpAnimatedCircledIcon, Popper },
	data() {
		return {
			radius: smallRadius,
			circleSize: smallCircelSize,
			currentMenuLabel: null
		}
	},
	props: {
		showTitles: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		...mapState(['menu']),
		...mapGetters(['currentProjectId', 'isMobile', 'colors'])
	},
	methods: {
		isCurrent(button) {
			let isActive = false
			// let isActive = this.$router.currentRoute.value.path.includes(button.link)
			if (this.currentMenuLabel) {
				isActive = button.label == this.currentMenuLabel

			} else isActive = false
			return isActive
		},
		handleMenuItemClick(menuItem) {
			this.currentMenuLabel = menuItem.label
			this.closeMobileMenu()

		},
		...mapActions(['closeMobileMenu'])
	},
	mounted() {

		// this.currentMenuLabel = this.menu[0].label
	},
	watch: {
		showTitles(newVal) {
			if (newVal) {
				setTimeout(() => {
					this.radius = radius
					this.circleSize = circelSize
				}, 100);
			} else {
				this.radius = smallRadius
				this.circleSize = smallCircelSize
			}

		}
	}
}
</script>

<style lang="scss">
.middle-menu {
	width: 100%;
	position: relative;
	z-index: 15;
}

.menu-item {
	transition: all .3s linear;

	.animatedCircledIcon {

		transform: rotate(270deg);

		.content {
			transform: rotate(-270deg);

			.menu-icon {
				width: 1.2rem;
			}
		}
	}

	.mobileMenuItem {
		.menu-icon {
			border-radius: 6.25rem;
			background: rgba(0, 0, 0, 0.80);
			width: 3.125rem;
			height: 3.125rem;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .3s all;

		}
		.router-link-active{
			.menu-icon{
				background: var(--primary);
				//scale: 1.1;
			}

		}

	}

	.is-mobile {
		.middle-menu {
			background: linear-gradient(180deg, rgba(28, 28, 28, 0.00) 0%, rgba(28, 28, 28, 0.90) 80.21%);


		}
	}


}

</style>
