<template>
    <div class="admin apartments-admin">
				<h3>{{$t('ניהול דירות')}}</h3>
				<div class="actions">
						<form ref="fileForm" action="">
								<label class="import-btn">
										<input type="file" accept="xlsx,xls" @input="loadExel">
										<fp-icon
												animated="upload"
												target=".import-btn"
												size="25"
										></fp-icon>
										<span>{{$t('ייבוא מאקסל')}}</span>
								</label>
						</form>
						<fp-button
								size="small"
								@click="exportProducts"
						>
								<fp-icon
										animated="download-save"
										target=".btn"
										size="25"
								></fp-icon>
								<span>{{$t('ייצוא לאקסל')}}</span>
						</fp-button>
						<fp-button v-if="hasBatchEdit" size="small" @click="showBatchEditDialog">{{$t('עריכת מסומנים')}}</fp-button>
				</div>
				<div class="actions">
						<div>
								<fp-select v-model="showLanguage">
										<template #label>{{$t('שפה')}}</template>
										<option value="all">{{$t('הכול')}}</option>
										<option value="en">{{$t('אנגלית')}}</option>
										<option value="he">{{$t('עברית')}}</option>
								</fp-select>
						</div>
						<fp-checkbox v-model="showOnlyDrafts">
								{{$t('הצגת רק טיוטה')}}
						</fp-checkbox>
				</div>
				<div>
						<fp-input name="search" v-model="searchQuery" :placeholder="$t('חיפוש')"></fp-input>
				</div>
				<div class="scrollable-y">
						<table class="products-manage-table">
								<thead>
								<tr>
										<th>{{ $t('מזהה ייחודי') }}</th>
										<th>{{ $t('שם') }}</th>
										<th>{{ $t('מצב') }}</th>
										<th>{{ $t('שפה') }}</th>
										<th>{{ $t('זמינות') }}</th>
										<th><a href="javascript:" @click="toggleBatchEditAll">{{ $t('סמן לעריכה') }}</a></th>
										<th>{{ $t('פעולות') }}</th>
								</tr>
								</thead>
								<tbody>
								<tr v-for="(product, productIndex) in filteredProducts" :key="productIndex">
										<td>
												<span><span># </span>{{product.id}}</span>
										</td>
										<td>
												{{(product.title || product.name)}}
										</td>
										<td>
												<span>{{ statusName(product.status) }}</span>
										</td>
										<td>
												<div class="language-box">
														<flag-icon :title="languageName(product.language)" :code="product.language"></flag-icon>
												</div>
										</td>
										<td>
												<span>{{product.available}}</span>
										</td>
										<td>
												<fp-checkbox v-model="batchEdit[product.id]"></fp-checkbox>
										</td>
										<td>
												<div class="actions">
														<fp-button
																variant="primary-outline"
																size="small"
																@click="editProduct(product)"
														>{{$t('עריכה')}}</fp-button>
														<fp-button
																variant="danger-outline"
																size="small"
																@click="removeProduct(product)"
														>{{$t('מחיקה')}}</fp-button>
												</div>
										</td>
								</tr>
								</tbody>
						</table>
				</div>
				<div v-if="doingImport" class="import-confirm-dialog">
						<close-btn @click="cancelImport"></close-btn>
						<div class="dialog-title">{{ $t('ייבוא דירות') }}</div>
						<div class="dialog-explanation">{{ $t('להלן דוגמה של 8 שורות מהקובץ שלך, וודא שלמות נתונים ואשר את ביצוע הייבוא.') }}</div>
						<div class="table-responsive scrollable-x">
								<table>
										<thead v-if="importHeaders">
											<tr>
													<td v-for="(header, headerIndex) in importHeaders" :key="headerIndex">{{header}}</td>
											</tr>
										</thead>
										<tbody v-if="exampleImportData">
												<tr v-for="(row, rowIndex) in exampleImportData" :key="rowIndex">
														<td v-for="(column, columnIndex) in row" :key="columnIndex"><span>{{column}}&nbsp;</span></td>
												</tr>
										</tbody>
								</table>
						</div>
						<div class="total-data-to-import">
								{{$t('סה"כ מספר שורות לייבוא')}}
								<span>{{importData?.length}}</span>
						</div>
						<form class="dialog-footer" @submit.prevent="doImport">
								<div class="mb-1">
										<fp-checkbox v-model="dataToImport.create_floor_page" name="create_floor_page">{{ $t('יצירת עמודי קומות') }}</fp-checkbox>
								</div>
                <div class="mb-1">
										<fp-checkbox v-model="dataToImport.update_headers" name="update_headers">{{ $t('עדכן שמות לעמודות') }}</fp-checkbox>
								</div>
                <div class="mb-1">
                  <fp-select v-model="dataToImport.excel_lang" name="excel_lang" required>
                    <template #label>{{$t('שפת אקסל')}}</template>
                    <option value="he">{{$t('עברית')}}</option>
                    <option value="en">{{$t('אנגלית')}}</option>
                  </fp-select>
                </div>
								<div class="mb-1">
										<fp-select v-model="dataToImport.project_ids" name="project_ids" multiple required>
												<template #label>{{$t('פרוייקטים')}}</template>
												<option
														v-for="(project, projectIndex) in projects"
														:key="projectIndex"
														:value="project.id"
														:selected="projectIndex === 0"
												>{{project.name}} <span v-if="project.language">({{project.language}})</span></option>
										</fp-select>
								</div>
								<div class="buttons">
										<fp-button type="submit" variant="primary-outline">ייבוא</fp-button>
										<fp-button variant="secondary-outline" @click="cancelImport">ביטול</fp-button>
								</div>
						</form>
				</div>
				<div v-if="productEdit" class="product-edit-dialog">
						<close-btn @click="productEdit = null"></close-btn>
						<form action="">
								<fp-input></fp-input>
						</form>
				</div>
				<div v-if="batchEditDialog" class="batch-edit-dialog">
						<close-btn @click="hideBatchEditDialog"></close-btn>
						<div class="dialog-content">
								<form action="" @submit.prevent="doBatchEdit">
										<div class="dialog-title">{{$t('עריכת מסומנים')}}</div>
										<div class="form-group">
												<label>{{$t('מצב')}}</label>
												<fp-select v-model="batchEditParams.status">
														<option value="unchanged">{{$t('ללא שינוי')}}</option>
														<option value="publish">{{$t('פורסם')}}</option>
														<option value="draft">{{$t('טיוטה')}}</option>
														<option value="trash">{{$t('נמחק')}}</option>
												</fp-select>
										</div>
										<div class="form-group">
												<fp-radio-group name="available" :options="availabilityOptions" v-model="batchEditParams.available">{{$t('זמינות')}}</fp-radio-group>
										</div>
										<div class="form-group">
												<fp-checkbox v-model="batchEditParams.delete">
														<span>{{$t('מחיקה לצמיתות')}}</span>
												</fp-checkbox>
										</div>
										<fp-button type="submit" variant="primary-outline" size="small">{{$t('ביצוע')}}</fp-button>
								</form>
						</div>
				</div>
    </div>
</template>
<script>
import FpButton from "@/components/buttons/FpButton.vue";
import FpCheckbox from "@/components/buttons/FpCheckbox.vue";
import FpSelect from "@/components/FpSelect.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import FpInput from "@/components/FpInput.vue";
import CloseBtn from "@/components/buttons/CloseBtn.vue";
import FpRadioGroup from "@/components/buttons/FpRadioGroup.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import AlertDialog from "@/components/dialogs/AlertDialog.vue";
import ApartmentEdit from "@/components/admin/ApartmentEdit.vue";
import {read, utils, writeFileXLSX} from "xlsx";
import FpIcon from "@/components/FpIcon.vue";
import FlagIcon from "@/components/icons/FlagIcon.vue";
import http from "@/http";

export default {
    name: "ApartmentsAdmin",
		components: {FlagIcon, FpIcon, FpRadioGroup, CloseBtn, FpInput, FpSelect, FpCheckbox, FpButton},
		data(){
				return {
						dataToImport: {
								headers: {},
								data: [],
								project_ids: [],
								create_floor_page: false,
                excel_lang: 'he',
                update_headers: true
						},
						batchEdit: {},
						batchEditAll: false,
						batchEditParams: {
								status: 'publish',
								available: 'unchanged',
								delete: false
						},
						availabilityAll: false,
						productEdit: null,
						batchEditDialog: false,
						searchQuery: '',
            importTableExcelLang: 'he',
						projects: [],
						products: [],
						showOnlyDrafts: false,
						showLanguage: 'all'
				}
		},
		computed: {
				apartmentStatuses(){
						if(this.apartmentDataView && this.apartmentDataView.apartment_statuses){
								return this.apartmentDataView.apartment_statuses;
						}
						return [];
				},
        importTableHeaders(){
          if(this.apartmentDataView && this.apartmentDataView.import_table_headers){
            return this.apartmentDataView.import_table_headers
          }
          return null
        },
				availabilityOptions(){
						const options = this.apartmentStatuses.map(s => ({label: s.status_name, value: s.status_name, color: s.status_color}))
						if(options.length === 0){
								options.push({
										label: this.$t('פנוי'),
										value: '1',
										color: '#00ff00'
								});
								options.push({
										label: this.$t('לא פנוי'),
										value: '0',
										color: '#ff0000'
								});
						}
						options.push({
								label: this.$t('ללא שינוי'),
								value: 'unchanged',
								color: '#000'
						})
						return options
				},
				doingImport(){
						return this.dataToImport?.data?.length > 0
				},
				importHeaders(){
						return this.dataToImport?.headers
				},
				importData(){
						return this.dataToImport?.data
				},
				exampleImportData(){
						return this.dataToImport?.data?.slice(0, 8)
				},
				filteredProducts(){
						let products = this.productsList;
						if(this.showOnlyDrafts){
								products = products.filter(p => p.status === 'draft')
						}
						if(this.showLanguage !== 'all') {
								return products.filter(p => p.language === this.showLanguage)
						}
						return products;
				},
				productsList:{
						get(){
								if(this.searchQuery){
										const products = [];
										Object.keys(this.products).forEach((k) => {
												const p = this.products[k]
												const idStr = p.id+""
												if(p.name.includes(this.searchQuery) || idStr.startsWith(this.searchQuery) || p.status === this.searchQuery){
														products.push(p)
												}
										})
										return products.sort((p1, p2) => p1['fp-sort'] - p2['fp-sort'])
								}
								return Object.entries(this.products).map(e => e[1]).sort((p1, p2) => p1['fp-sort'] - p2['fp-sort'])
						},
						set(value){
								this.updateProducts(value)
						}
				},
				hasBatchEdit(){
						return Object.keys(this.batchEdit).filter((key) => (this.batchEdit[key])).length > 0
				},
				exportColumnSlugs(){
						return [
							'catalog_number',
							'house_type',
							'average_lot_size',
							'flat_area',
							'basement_area',
							'built_square_meters',
							'garden_square_meters',
							'balcony_square_meters',
							'parking_lots',
							'land_price',
							'purchase_tax',
							'land_purchase_management_fee',
							'lawyer_fees',
							'costs_at_the_stage_of_purchasing_land',
							'planning_costs_after_obtaining_permits',
							'estimated_construction_cost',
							'construction_management_fee',
							'expected_costs_per_unit',
							'lot_number',
							'available',
							'apartment_type',
							'amount',
							'floor',
							'building_floors',
							'id',
							'rooms',
							'language_code',
						]
				},
				...mapGetters(['buildings','apartmentDataView']),
		},
		methods: {
				async loadExel(e){
						const files = e.target.files
						const data = await files[0].arrayBuffer();
						/* data is an ArrayBuffer */
						const workbook = read(data);
						if(workbook.SheetNames[0] && workbook.Sheets[workbook.SheetNames[0]]){
								const workSheet = workbook.Sheets[workbook.SheetNames[0]];
								const jsa = utils.sheet_to_json(workSheet, {header: "A", defval:""});
								this.dataToImport.headers = jsa[0]
								this.dataToImport.data = jsa.slice(1)
						}
				},
				cancelImport(){
						this.dataToImport.headers = {}
						this.dataToImport.data = []
						this.$refs.fileForm.reset()
				},
				async doImport(){
						this.$store.commit('globalLoadingAttract')
						const {data} = await this.$http.post('import/products', this.dataToImport)
						if(data){
								const {success} = data;
								this.$store.commit('globalLoadingSubtract')
								if(success){
										this.cancelImport()
										await this.loadProducts()
								}
						}
				},
				toggleBatchEditAll(){
						this.batchEdit = {}
						this.batchEditAll = !this.batchEditAll
						this.filteredProducts.forEach((product) => {
								this.batchEdit[product.id] = this.batchEditAll
						})
				},

				showBatchEditDialog(){
						this.batchEditDialog = true
				},
				hideBatchEditDialog(){
						this.batchEditDialog = false
				},
				async doBatchEdit(){
						const products = {}
						Object.keys(this.batchEdit).forEach((id) => {
								if(this.batchEdit[id]){
										//products[id] = JSON.parse(JSON.stringify(this.productsList[id]))
										products[id] = {id};
										products[id].available = this.batchEditParams.available
										products[id].status = this.batchEditParams.status
										if(this.batchEditParams.delete){
												products[id].delete = this.batchEditParams.delete
										}
								}
						})
						this.hideBatchEditDialog()
						await this.saveProducts({products})
						await this.loadProducts();
						this.resetBatchEdit()
				},
				resetBatchEdit() {
						this.batchEdit = {};
						this.batchEditParams = {
								status: 'publish',
								available: 'unchanged',
								delete: false
						}
				},
				removeProduct(product){
						this.$dialog.addDialog({
								component: ConfirmDialog,
								props: {
										message: this.$t('בטוח שברצונך למחוק דירה זו?'),
										confirm: async () => {
												if(await this.$store.dispatch('deleteProduct', {productId: product.id})){
														this.$dialog.addDialog({
																component: AlertDialog,
																props: {
																		message: this.$t('דירה נמחקה בהצלחה')
																}
														})
												}
										}
								}
						})
				},
				editProduct(apartment){
						this.$dialog.addDialog({
								component: ApartmentEdit,
								props: {
										apartment,
										save(answer){
												console.log({answer})
										}
								}
						})
				},
				async exportProducts(){
						this.$store.commit('globalLoadingAttract')
						const excel_lang = this.showLanguage || 'all'
						try {
								const {data} = await this.$http.get('export/products', {params: {projectId: this.currentProjectId, excel_lang}})
								if(data){
										const {headers, rows} = data
										if(rows && rows.length > 0){
												const rowsArrays = rows.map((row) => {
														return Object.values(row)
												});
                        if(headers && headers.length){
													rowsArrays.unshift(headers)
                        }
												this.downloadExcel(rowsArrays)
										}
								}
						}catch (e){
								console.warn(e)
						}
						this.$store.commit('globalLoadingSubtract')
				},
				downloadExcel(rows){
						const workbook = utils.book_new()
						const worksheet = utils.json_to_sheet(rows, {skipHeader: true})
						const filename = "export.xlsx"
						utils.book_append_sheet(workbook, worksheet, filename)
						writeFileXLSX(workbook, filename)
				},
				findDataFromProduct(product, slug, column){
						switch(slug){
								case 'catalog_number':
										return product.title || product.name
								case 'available':
									return product.available
								case 'floor':
									return product.floor_name || product.floor
								case 'apartment_type':
									return product.type
								case 'id':
									return product.id
								case 'lot_number':
								case 'building':
										return product.type_building?.name || ''
								case 'rooms':
										return product.type_rooms
								case 'plot':
										return product.plot
                case 'language_code':
										return product.language || ''
						}
						if(product && product.more_details && product.more_details.length){
								const result = product.more_details.find(md => {
										return (md.label === column || md.column_name === slug) && md.value
								})
								if(result && result.value){
										return result.value
								}
								switch(slug){
										case 'flat_area':
												return product.type_dimensions?.apartment_sm || null
										case 'balcony_square_meters':
												return product.type_dimensions?.porch_sm || null
								}
						}
						return null
				},
				statusName(status){
						const names = {
								publish: this.$t('פורסם'),
								draft: this.$t('טיוטה'),
								trash: this.$t('נמחק'),
						}
						if(status && names[status]){
								return names[status]
						}
						return ''
				},
				languageName(code){
						const names = {
								he: this.$t('עברית'),
								en: this.$t('אנגלית')
						}
						if(code && names[code]){
								return names[code]
						}
						return ''
				},
				async loadProjects(){
						this.$store.commit("globalLoadingAttract");
						const { data } = await http.get("projects", {params: {fp_lang: 'all'}});
						this.$store.commit("globalLoadingSubtract");
						if (data.projects) {
								this.projects = data.projects;
						}
				},
				async loadProducts(){
						this.$store.commit("globalLoadingAttract");
						const { data } = await http.get("products", {params: {fp_lang: 'all'}});
						this.$store.commit("globalLoadingSubtract");
						if (data.products) {
								this.products = data.products;
						}
				},
				...mapMutations({
						updateProducts: 'products'
				}),
				...mapActions(['saveProducts'])
		},
		async mounted() {
				await this.loadProjects()
				await this.loadProducts()
		}
}
</script>

<style scoped lang="scss">
.admin.apartments-admin {
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		gap: 15px;

		.form-group {
				> label:not(.fp-checkbox) {
						display: block;
						margin-bottom: 0.625rem;
				}
		}

		.import-btn {
				border-radius: 6.25rem;
				border: solid 1px var(--primary);
				padding: 3px 6px;
				gap: 5px;
				display: inline-flex;
				align-items: center;
				cursor: pointer;

				input {
						display: none;
				}
		}

		.actions {
				display: flex;
				flex-flow: row nowrap;
				gap: 10px;
				align-items: flex-end;
		}

		.import-confirm-dialog {
				position: absolute;
				top: 0;
				right: 0;
				background-color: #000;
				color: #fff;
				padding: 25px;
				max-width: 100%;
				box-shadow: 5px 5px 5px 0 #353535;
				border-radius: 4px;
				display: flex;
				flex-flow: column;
				gap: 10px;
				align-items: flex-start;
				width: calc(100% - 133px);

				.dialog-title {
						font-size: 1.8rem;
				}

				.table-responsive {
						overflow-x: auto;
						max-width: 100%;
						&::-webkit-scrollbar {
								height: 8px;
						}
				}

				table {
						border: solid 1px #fff;
						td {
								border: solid 1px;
								padding: 4px;
						}
				}

				.dialog-footer {
						.buttons {
								display: flex;
								gap: 10px;
						}
				}
		}

		.products-manage-table {
				thead {
						position: sticky;
						top: 0;
						background-color: #000;
				}

				th, td {
						padding: 10px;
						vertical-align: middle;
				}
		}

		.batch-edit-dialog,
    .columns-edit-dialog {
				position: fixed;
				top: 200px;
				left: 50%;
				z-index: 10;
				transform: translateX(-50%);
				background-color: #000;
				width: 330px;
				padding: 35px 35px;

				.dialog-title {
						font-size: 1.4rem;
				}

				.dialog-content form {
						display: flex;
						flex-flow: column;
						align-items: flex-start;
						gap: 15px;
				}

				button.close-btn {
						position: absolute;
						top: 10px;
						right: 5px;
				}
		}

    .columns-edit-dialog {
      max-width: 700px;
      width: 100%;
      max-height: calc(100vh - 250px);
      overflow-y: auto;
      box-shadow: 2px 2px 10px #555;
      border-radius: 0.6rem;
    }

		:deep(.fp-radio-group) .group-wrapper .btn-radio {
				padding: 0 5px;
		}

		.language-box {
				display: flex;
				flex-flow: column;
				align-items: center;
				gap: 5px;
		}
}

html[dir=rtl] {
		.admin.apartments-admin {
				.import-confirm-dialog {
						left: 0;
						right: auto;
				}
		}
}
</style>
