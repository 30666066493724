<template>
    <div class="stages-pane" v-if="showStages && isDesktop" :class="{open}">
        <div class="stages-wrapper">
            <project-stages @stage-selected="toggleStageDetails" :project="project"></project-stages>
        </div>
        <transition appear>
            <div class="stage-details" v-if="currentStage" v-html="currentStage.content"></div>
        </transition>
        <fp-icon-button class="stages-toggle" symbol="arrow-down" @click="toggle"></fp-icon-button>
    </div>
</template>

<script>
import ProjectStages from "@/components/ProjectStages";
import FpIconButton from "@/components/buttons/FpIconButton";
import {mapGetters} from "vuex";
export default {
    name: "StagesPane",
    components: {FpIconButton, ProjectStages},
    computed: {
        project(){
            return this.$store.getters.currentProject
        },
				showStages(){
						if(this.project && typeof(this.project.show_stages) === 'boolean'){
								return this.project.show_stages
						}
						return true;
				},
        ...mapGetters(['isMobile', 'isDesktop'])
    },
    data(){
        return {
            open: false,
            currentStage: null
        }
    },
    methods: {
        toggle(){
            this.open = !this.open
            if(!this.open){
                this.currentStage = null
                if(!this.isMobile && !this.$store.getters.projectModal){
                    this.$store.commit('toggleProjectModal', true)
                }
            }
        },
        toggleStageDetails(stage){
            if(stage && stage.content){
                if(stage && this.currentStage !== stage){
                    this.currentStage = stage
                    if(!this.isMobile && this.open && this.$store.getters.projectModal){
                        this.$store.commit('toggleProjectModal', false)
                    }
                }else{
                    this.currentStage = null
                    if(!this.isMobile && this.open && !this.$store.getters.projectModal){
                        this.$store.commit('toggleProjectModal', true)
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss">

    .stages-pane {
        position: fixed;
        top: -83px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0 0 5px 5px;
        border: solid 1px var(--primary-dark);
        transition: all ease .5s;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.86) 0%, #000000 100%);
        opacity: 0.9;
        max-width: 100vw;
        z-index: 5;

        .stages-toggle {
            position: absolute !important;
            margin-top: 1px;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            background-color: #000;
            width: 88px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 0 6px;
            border-radius: 0 0 5px 5px;
            z-index: 10;

            .pf-icon {
                transition: transform ease .5s;
                display: block;
            }
        }


        &.open {
            top: 0;

            .stages-toggle {
                .pf-icon {
                    transform: rotate(180deg);
                }
            }
        }

        .stages-wrapper {
            background-color: #000;
            padding: 18px 18px 15px 18px;
            box-sizing: content-box;
            position: relative;
            z-index: 1;

            /* scrollbar design for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                width: 2px;
                height: 2px;
                background-color: #aaa;
            }

            &::-webkit-scrollbar-thumb {
                background: var(--primary);
            }

            /* scrollbar design for Edge and Firefox */
            & {
                scrollbar-width: thin;
                scrollbar-color: var(--primary) #aaa;
            }
        }

        /*.stages-toggle .pf-icon {
            transition: transform ease .5s;
        }*/

        .stage-details {
            padding: 1.0625rem 1.0625rem 3rem;
            width: 804px;
            max-width: 100%;
            overflow: hidden;

            &.v-enter-active,
            &.v-leave-active {
                transition: transform .5s ease;
            }

            &.v-enter-from,
            &.v-leave-to {
                transform: translateY(-50vh);
            }

            img{
                display: block;
                margin: 15px auto;
            }
        }
    }

    .is-mobile {
        .stages-pane {
            background-image: none;
            background-color: #000;
            opacity: 1;
            border: none;
            position: relative;
            bottom: auto;
            left: auto;
            width: 100vw;
            transform: none;

            .stages-toggle {
                margin-top: 0;
            }

            .stages-wrapper {
                padding: 15px;
                height: auto;
                display: none;
            }

            &.open {
                top: auto;
                .stages-wrapper {
                    display: block;
                }
            }
        }
    }

		.fp-side-menu-wrapper + .fp-app-wrapper {
				.stages-pane {
						top: auto;
						bottom: -83px;

						.stages-toggle {
								top: auto;
								bottom: 100%;
								border-radius: 5px 5px 0 0;
								.pf-icon {
										transform: rotate(180deg);
								}
						}

						&.open {
								top: auto;
								bottom: 0;

								.stages-toggle {
										.pf-icon {
												transform: rotate(0deg);
										}
								}
						}

						.stage-details {

								&.v-enter-from,
								&.v-leave-to {
										transform: translateY(50vh);
								}
						}
				}
		}
</style>
