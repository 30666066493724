<template>
  <div class="animatedCircledIcon" @mouseover="mouseOverHandler" @mouseout="mouseOutHandler"
    
    :style="{
      width: width +'px',
      height: height +'px' 

    }"
    >
    <div class="disable-pointer-events">
      <svg :width="width" :height="height" :style="{ transform: `scale(${scale})` }">>

        <circle
          ref="mainCircle"
          class="circle-element-transition"
          :cx="center"
          :cy="center"
          :r="r"
          :stroke="active ? strokeColor : baseStrokeColor"
          stroke-width="1"
          fill="transparent"
        />

        <circle
          v-if="!hoverMode"
          v-bind:stroke="circleStroke"
          ref="circle"
          :cx="center"
          :cy="center"
          :r="r"
          :stroke-dasharray="circumference"
          stroke-width="1"
          fill="none"
          :style="{ strokeDashoffset }"
        />

        <circle
          v-if="r > 40"
          :cx="dotPosition.x"
          :cy="dotPosition.y"
          r="3"
          :fill="strokeColor"
        />

        <foreignObject :x="center - r" :y="center - r" :width="r * 2" :height="r * 2">
          <div class="content" >
            <slot />
          </div>
        </foreignObject>
      </svg>

    </div>
  </div>
</template>

<script>

export default {
  props: {
    r: {
      type: Number,
      required: false,
      default: 80,
    },
    width: {
      type: Number,
      required: false,
      default: 200,
    },
    height: {
      type: Number,
      required: false,
      default: 200,
    },
    strokeColor: {
      type: String,
      default: "black",
    },
    baseStrokeColor: {
      type: String,
      default: "blue",
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    hoverMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      circumference: 0,
      scale: 1,
      strokeDashoffset: 0,
      dotPosition: { x: 0, y: 0 },
      animation: null,
      isAnimating: false,
      circleStroke: this.baseStrokeColor, // initial stroke color
      mainCircleAnimation: null
      
    };
  },
  watch: {
    r(newVal, oldVal) {
      this.animateSizeChange(newVal, oldVal, 'r');
      this.reset()
    },
    active(){
      this.reset()
    }

  },

  computed: {
    center() {
      // Assuming that the circle is centered in the SVG
      return this.width / 2;
    },
  },
  mounted() {
    this.reset()
  },
  methods: {
    reset() {
      this.circumference = 2 * Math.PI * this.r;
      this.strokeOffset = this.circumference;
      this.circleStroke = this.active? this.strokeColor : this.baseStrokeColor
      this.calculateDotPosition(0);
    },
    animateSizeChange() {
      // if (this.mainCircleAnimation) {
      //   this.cancelAnimation() 
      // }
      // this.mainCircleAnimation = this.$refs.mainCircle.animate(
      //   [{ [attr]: oldVal }, { [attr]: newVal }],
      //   {
      //     duration: 200,
      //     fill: 'forwards',
      //     easing: 'ease-in-out'
      //   }
      // );
    },
    cancelAnimation() {
      if (this.mainCircleAnimation) {
        this.mainCircleAnimation.cancel();
        this.mainCircleAnimation = null;
      }
    },
    mouseOverHandler(){
      // console.log("mouseOverHandler", event.relatedTarget)
      if (this.hoverMode){
        this.$refs.mainCircle.setAttribute('stroke', this.strokeColor)

      }
      this.startAnimation();
    },
    mouseOutHandler(){
      // console.log("mouseOutHandler", event.relatedTarget)
      if (this.hoverMode){
        this.$refs.mainCircle.setAttribute('stroke', this.baseStrokeColor)

      }
      this.stopAnimation()
    },
    // mouseEnterHandler(event){
    //   console.log("mouseEnterHandler", event.relatedTarget)
    // },
    startAnimation() {
      if (!this.isAnimating ) {
        this.isAnimating = true;
        this.circleStroke = this.strokeColor
        // console.log("this.$refs.circle", this.$refs.circle)
        if (this.$refs.circle){
          // console.log("HEREEEEE", this.circumference)
          this.animation = this.$refs.circle.animate(
            [{ strokeDashoffset: this.circumference }, { strokeDashoffset: 0 }],
            { duration: 1000, fill: "forwards" }
          );
          this.animateDot();

        }
        
      }
    },
    stopAnimation() {

      this.isAnimating = false
      this.animation?.cancel();
      this.strokeOffset = this.circumference;
      this.circleStroke = this.active? this.strokeColor : this.baseStrokeColor
      this.calculateDotPosition(0);
    },
    calculateDotPosition(progress) {
      const angle = 2 * Math.PI * progress;
      this.dotPosition.x = this.center + this.r * Math.cos(angle);
      this.dotPosition.y = this.center + this.r * Math.sin(angle);
    },
    animateDot() {
      if (!this.animation) return;
      const progress = this.animation.currentTime / this.animation.effect.getTiming().duration;
      // console.log("progress", progress)
      this.calculateDotPosition(progress);
      if (progress < 1 && this.isAnimating) requestAnimationFrame(this.animateDot);
    },
  },
};
</script>

<style scoped>
.animatedCircledIcon{
  cursor: pointer;
  border-radius: 50%;

}
svg {  
  transition: width 0.3s ease;
}
.circle-element-transition {
  transition: stroke 0.2s ease-in-out;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

}
</style>