import "@lottiefiles/lottie-player";
import lottie from 'lottie-web';
import { defineLordIconElement, LordIconElement } from 'lord-icon-element';
import {publicPath} from "../vue.config";
// load icon data from json file
async function loadIconData(key) {
    const response = await fetch(`${publicPath}/licons/${key}.json`);
    return await response.json();
}

// load and register icons
(async () => {
    const [
        share,
        accessibility,
        disabled,
        account,
        arrowUp,
        arrowDown,
        article,
        play,
        anchor,
        expand,
        home,
        search,
        favoriteHeart,
        error,
        callPhone,
        email,
        exitRoom,
        location,
        visability,
        groups,
        link,
        tv,
        camera,
        locationUser,
        fitScreen,
        rotate,
        helpCenter,
        support,
        viewCarousel,
        school,
        viewHeadline,
        callMissed,
        mailEnvelopeOpen,
        attribution,
        photo,
        swipeLeftRight,
        tapOnce,
        edit,
        download,
        share2,
        artistPaintingColorPallette,
        erase,
        navigateThreeFingers,
        errorCrossSimpleOutline,
        escKeyOutline,
        analytics,
        inbox,
        pizzaSlice,
        lockClosed,
        cloud,
        podcasts,
        worldGlobeWikis,
        ticket,
        shopping,
        privacyShield,
        shoppingBag,
        trash,
        speed,
        check,
        info,
        build,
        book,
        trengingUp,
        trendingDown,
        swap,
        storeIcon,
        verified,
        bolt,
        upload,
        downloadSave,
        checkList
    ] = await Promise.all([
        loadIconData('1-share-outline'),
        loadIconData('2-accessibility-outline'),
        loadIconData('3-disabled-outline'),
        loadIconData('8-account-outline'),
        loadIconData('11-arrow-up-outline'),
        loadIconData('12-arrow-down-outline'),
        loadIconData('14-article-outline'),
        loadIconData('26-play-outline'),
        loadIconData('30-anchor-outline'),
        loadIconData('36-expand-outline'),
        loadIconData('41-home-outline'),
        loadIconData('42-search-outline'),
        loadIconData('48-favorite-heart-outline'),
        loadIconData('55-error-outline'),
        loadIconData('58-call-phone-outline'),
        loadIconData('59-email-outline'),
        loadIconData('83-exit-room-outline'),
        loadIconData('89-location-outline'),
        loadIconData('92-visability-outline'),
        loadIconData('96-groups-outline'),
        loadIconData('98-link-outline'),
        loadIconData('107-tv-outline'),
        loadIconData('123-camera-outline'),
        loadIconData('130-location-user-outline'),
        loadIconData('135-fit-screen-outline'),
        loadIconData('136-rotate-outline'),
        loadIconData('140-help-center-outline'),
        loadIconData('158-support-outline'),
        loadIconData('165-view-carousel-outline'),
        loadIconData('167-school-outline'),
        loadIconData('168-view-headline-outline'),
        loadIconData('181-call-missed-outline'),
        loadIconData('190-mail-envelope-open-outline'),
        loadIconData('199-attribution-outline'),
        loadIconData('72-photo-outline'),
        loadIconData('1444-swipe-left-right-lineal-black'),
        loadIconData('733-tap-once-lineal-black'),
        loadIconData('35-edit-outline'),
        loadIconData('199-download-2-outline'),
        loadIconData('751-share-outline'),
        loadIconData('771-artist-painting-color-pallette-outline'),
        loadIconData('1432-erase-outline'),
        loadIconData('1670-navigate-three-fingers-outline'),
        loadIconData('38-error-cross-simple-outline'),
        loadIconData('1298-esc-key-outline'),
        loadIconData("10-analytics-outline"),
        loadIconData("9-inbox-outline"),
        loadIconData("93-pizza-slice-outline"),
        loadIconData("90-lock-closed-outline"),
        loadIconData("84-cloud-outline"),
        loadIconData("74-podcasts-outline"),
        loadIconData("73-world-globe-wikis-outline"),
        loadIconData("70-ticket-outline"),
        loadIconData("6-shopping-outline"),
        loadIconData("66-privacy-shield-outline"),
        loadIconData("64-shopping-bag-outline"),
        loadIconData("39-trash-outline"),
        loadIconData("33-speed-outline"),
        loadIconData("31-check-outline"),
        loadIconData("28-info-outline"),
        loadIconData("22-build-outline"),
        loadIconData("19-book-outline"),
        loadIconData("160-trenging-up-outline"),
        loadIconData("159-trending-down-outline"),
        loadIconData("154-swap-outline"),
        loadIconData("153-store-outline"),
        loadIconData("126-verified-outline"),
        loadIconData("117-bolt-outline"),
        loadIconData("80-upload-outline"),
        loadIconData("81-download-save-outline"),
        loadIconData("78-check-list-outline"),
    ]);

    LordIconElement.registerIcon('share', share)
    LordIconElement.registerIcon('accessibility', accessibility)
    LordIconElement.registerIcon('disabled', disabled)
    LordIconElement.registerIcon('account', account)
    LordIconElement.registerIcon('arrow-up', arrowUp)
    LordIconElement.registerIcon('arrow-down', arrowDown)
    LordIconElement.registerIcon('article', article)
    LordIconElement.registerIcon('play', play)
    LordIconElement.registerIcon('anchor', anchor)
    LordIconElement.registerIcon('expand', expand)
    LordIconElement.registerIcon('home', home)
    LordIconElement.registerIcon('search', search)
    LordIconElement.registerIcon('favorite-heart', favoriteHeart)
    LordIconElement.registerIcon('error', error)
    LordIconElement.registerIcon('call-phone', callPhone)
    LordIconElement.registerIcon('email', email)
    LordIconElement.registerIcon('exit-room', exitRoom)
    LordIconElement.registerIcon('location', location)
    LordIconElement.registerIcon('visability', visability)
    LordIconElement.registerIcon('groups', groups)
    LordIconElement.registerIcon('link', link)
    LordIconElement.registerIcon('tv', tv)
    LordIconElement.registerIcon('camera', camera)
    LordIconElement.registerIcon('location-user', locationUser)
    LordIconElement.registerIcon('fit-screen', fitScreen)
    LordIconElement.registerIcon('rotate', rotate)
    LordIconElement.registerIcon('help-center', helpCenter)
    LordIconElement.registerIcon('support', support)
    LordIconElement.registerIcon('view-carousel', viewCarousel)
    LordIconElement.registerIcon('school', school)
    LordIconElement.registerIcon('view-headline', viewHeadline)
    LordIconElement.registerIcon('call-missed', callMissed)
    LordIconElement.registerIcon('mail-envelope-open', mailEnvelopeOpen)
    LordIconElement.registerIcon('attribution', attribution)
    LordIconElement.registerIcon('photo', photo)
    LordIconElement.registerIcon('swipe-left-right', swipeLeftRight)
    LordIconElement.registerIcon('tap-once', tapOnce)
    LordIconElement.registerIcon('edit', edit)
    LordIconElement.registerIcon('download', download)
    LordIconElement.registerIcon('share2', share2)
    LordIconElement.registerIcon('artist-painting-color-pallette', artistPaintingColorPallette)
    LordIconElement.registerIcon('erase', erase)
    LordIconElement.registerIcon('navigate-three-fingers', navigateThreeFingers)
    LordIconElement.registerIcon('close', errorCrossSimpleOutline)
    LordIconElement.registerIcon('esc-key', escKeyOutline)
    LordIconElement.registerIcon('analytics', analytics)
    LordIconElement.registerIcon('inbox', inbox)
    LordIconElement.registerIcon('pizza-slice', pizzaSlice)
    LordIconElement.registerIcon('lock-closed', lockClosed)
    LordIconElement.registerIcon('cloud', cloud)
    LordIconElement.registerIcon('podcasts', podcasts)
    LordIconElement.registerIcon('world-globe-wikis', worldGlobeWikis)
    LordIconElement.registerIcon('ticket', ticket)
    LordIconElement.registerIcon('shopping', shopping)
    LordIconElement.registerIcon('privacy-shield', privacyShield)
    LordIconElement.registerIcon('shopping-bag', shoppingBag)
    LordIconElement.registerIcon('trash', trash)
    LordIconElement.registerIcon('speed', speed)
    LordIconElement.registerIcon('check', check)
    LordIconElement.registerIcon('info', info)
    LordIconElement.registerIcon('build', build)
    LordIconElement.registerIcon('book', book)
    LordIconElement.registerIcon('trenging-up', trengingUp)
    LordIconElement.registerIcon('trending-down', trendingDown)
    LordIconElement.registerIcon('swap', swap)
    LordIconElement.registerIcon('store', storeIcon)
    LordIconElement.registerIcon('verified', verified)
    LordIconElement.registerIcon('bolt', bolt)
    LordIconElement.registerIcon('upload', upload)
    LordIconElement.registerIcon('download-save', downloadSave)
    LordIconElement.registerIcon('check-list', checkList)
})();

defineLordIconElement(lottie.loadAnimation);
