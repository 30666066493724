<template>
		<GDialog
				v-model="dialogState"
				max-width="300"
				:background="false"
		>
				<div class="dialog alert-dialog">
						<close-btn @click="doDismiss"></close-btn>
						<div class="alert-dialog-body">
								{{ message }}
						</div>
						<div class="alert-dialog-footer">
								<fp-button
										variant="primary-outline"
										size="small"
										@click="doDismiss"
								>{{$t('ביטול')}}</fp-button>
								<fp-button
										variant="primary"
										size="small"
										@click="doConfirm"
								>{{$t('אישור')}}</fp-button>
						</div>
				</div>
		</GDialog>
</template>

<script>
import { defineComponent } from 'vue'
import CloseBtn from "@/components/buttons/CloseBtn.vue"
import FpButton from "@/components/buttons/FpButton.vue"
export default defineComponent({
		name: 'ConfirmDialog',
		components: {FpButton, CloseBtn},
		props: {
				modelValue: {
						type: Boolean,
						default: false,
				},
				message: {
						type: String,
						required: true,
				},
				dismiss: {
						type: Function,
						required: false
				},
				confirm: {
						type: Function,
						required: false
				}
		},
		emits: ['update:modelValue'],
		computed: {
				dialogState: {
						get() {
								return this.modelValue;
						},
						set(value) {
								this.$emit('update:modelValue', value);
						},
				},
		},
		methods: {
				doDismiss(){
						this.dialogState = false
						if(typeof(this.dismiss) === 'function'){
								this.dismiss()
						}
				},
				doConfirm(){
						this.dialogState = false
						if(typeof(this.confirm) === 'function'){
								this.confirm()
						}
				}
		}
});
</script>
<style scoped lang="scss">
.alert-dialog {
		padding: 30px;
		backdrop-filter: blur(5px);
		background-color: rgba(0, 0, 0, 0.8);

		button.close-btn {
				position: absolute;
				top: 8px;
				left: 5px;
		}

		.alert-dialog-body {
				margin-bottom: 30px;
				text-align: center;
		}

		.alert-dialog-footer {
				display: flex;
				justify-content: center;
				gap: 10px;
		}
}
</style>
