<template>
	<div class="page apartment fade-in">
		<div class="centered-content" v-if="show">
			<transition appear>
				<content-modal
            v-if="currentButton"
            @close="closeContent"
            :show-back-button="false"
            :class="{
              'full': apartmentContentType === 'gallery',
              'tabs': hasTabs,
              'inner-tabs': hasInnerTabs
            }"
        >
					<template #header v-if="apartmentContent && apartmentContent.tabs_layout === 'top_tabs'">
						<span v-if="apartmentContent.label && apartmentContentType !== 'gallery'">{{ apartmentContent.label
						}}</span>
						<!-- <nav v-else-if="apartmentContent.tabs && apartmentContent.tabs.length > 1" class="header-tabs">
							<ul>
								<li v-for="(tab, tabIndex) in apartmentContent.tabs" :key="tabIndex">
									<button type="button" :class="{ active: currentContentTabIndex === tabIndex }"
										@click="changeContentTab(tabIndex)">{{ tab.label }}</button>
								</li>
							</ul>
						</nav> -->
					</template>
					<building-specs v-if="currentButton.acf_fc_layout === 'specifications'"
						:content="currentButton.tabs[currentContentTabIndex]" :viewType="isMobile ? 'mobile' : 'big'"></building-specs>

					<div class="gallery-slides" v-else-if="currentButton.acf_fc_layout === 'gallery' && apartmentContentCurrentTab">
				
							<div v-if="currentContentTabClickAction === 'edit' &&
								apartmentContentCurrentTab.gallery.length == 1" :class="`img-${apartmentContentCurrentTab.gallery[0].image_layout} image-edit`"
								@click="$store.dispatch('openImageEditor', apartmentContentCurrentTab.gallery[0].url)" tabindex="1">
								<img :src="apartmentContentCurrentTab.gallery[0].url" :alt="apartmentContentCurrentTab.gallery[0].alt">
								<click-here></click-here>
							</div>
							<masonry-gallery
								v-else-if="apartmentContentCurrentTab.acf_fc_layout === 'gallery' && apartmentContentCurrentTab.type === 'masonry'"
								:images="apartmentContentCurrentTab.gallery"
								:items-per-row="isMobile ? apartmentContentCurrentTab.mobile_items_per_row : apartmentContentCurrentTab.items_per_row"></masonry-gallery>
							<div class="horizontalSlider"
								v-else-if="apartmentContentCurrentTab.acf_fc_layout === 'gallery'">
								<slider-gallery :slides="apartmentContentCurrentTab.gallery">
									<template v-slot="{ slide }">

										<div v-if="slide.type === 'image'" class="ps-gal"
											:class="[`type-${slide.type}`, `img-${slide.image_layout}`]">
											<a :href="slide.url" :data-pswp-width="slide.width"
												:data-pswp-height="slide.height" target="_blank" rel="noreferrer">
												<img :src="slide.thumbnail" :alt="slide.alt">
											</a>
										</div>
										<div v-else :class="[`type-${slide.type}`, `img-${slide.image_layout}`]">
											<video :src="slide.url" controls></video>
										</div>
									</template>
								</slider-gallery>

							</div>
							<div class="iframe" v-else-if="apartmentContentCurrentTab.acf_fc_layout === 'iframe'">
								<iframe v-if="apartmentContentCurrentTab.src"
									:src="apartmentContentCurrentTab.src"></iframe>
								<illustration-only-disclaimer></illustration-only-disclaimer>
							</div>
							<masonry-gallery v-else-if="apartmentContentCurrentTab.acf_fc_layout === 'masonry'"
								:images="apartmentContentCurrentTab.gallery"
								:items-per-row="isMobile ? apartmentContentCurrentTab.mobile_items_per_row : apartmentContentCurrentTab.items_per_row"></masonry-gallery>
					
					</div>
					<div class="flipbook-wrapper" v-else-if="apartmentContentType === 'flipbook'">
						<fp-flip-book :pages="apartmentContent.images"></fp-flip-book>
					</div>
					<template #footer>
						<nav v-if="apartmentContent && apartmentContent.tabs_layout === 'bottom_buttons' && apartmentContent.tabs && apartmentContent.tabs.length > 1"
							class="footer-tabs">
							<fp-button v-for="(tab, tabIndex) in apartmentContent.tabs" :key="tabIndex"
								:class="{ active: currentContentTabIndex === tabIndex }" @click="changeContentTab(tabIndex)">{{
									tab.label }}</fp-button>
						</nav>
					</template>
				</content-modal>
			</transition>
			<div class="bottomBar">
				<div class="social-buttons flex between" v-if="product">
					<FpNewIcon v-if="!isMobile" iconName="building" class="circledIcon modelButton bordered cursor-pointer" size="1rem"
					@click="showModel = !showModel" />
					<Popper arrow click placement="top" v-if="!isMobile">
						<FpNewIcon iconName="shareButton2" class="circledIcon bordered cursor-pointer" size="1rem" />
						<template #content>
							<ShareLinks :apartment="product"/>
						</template>
					</Popper>

	
					<fp-icon-button v-if="!isMobile" @click="$store.commit('toggleApartmentRegistrationModal')" class="btn btn-secondary-outline"
						symbol="phone-ring" :iconBackGroundColor="colors.primary" :title="$t('הרשמה')">
						<span>{{ $t('הרשמה') }}</span>
					</fp-icon-button>


	
	
				</div>
				<div class="apartmentInfo flex align-center" v-if="product">
					<div class="floor">
						{{ product.type }}
					</div>
					<div class="details">
						<!-- <span class="detail flex align-center">
							<span class="value">{{ $t('קומה') }}</span>
							<span>{{ product.floor_name }}</span>
						</span>
						<span class="detail flex align-center">
							<span class="value">{{ product.type_rooms }}</span>
							<span>{{ $t('חדרים') }}</span>
						</span> -->
            <template v-for="(detail, detailIndex) in apartmentPageFieldsOrder" :key="detailIndex">
              <span v-if="detailValue(detail)" class="detail flex align-center">
                <span class="value">{{ detail.title }}</span>
                <span>{{ detailValue(detail) }}</span>
              </span>
            </template>
					</div>
					<div v-if="isMobile"  class="fpCircle" @click="$store.commit('toggleApartmentRegistrationModal')">

						<span>{{ $t('הרשמה') }}</span>
					</div>	
	
				</div>
				<div v-if="!isMobile" class="modelFrameWrapper" :class="{ showModel: showModel }">
					<div class="modelFrame">
						<model-frame width="400" height="95%"></model-frame>
	
					</div>
				</div>

			</div>
			<transition appear>
				<project-modal v-if="product" @button-clicked="projectButtonClicked" @subMenu-clicked="changeContentTab"
					:current-button="currentButton" :currentSubMenuIndex="currentContentTabIndex"
					:project="product"></project-modal>
			</transition>
		</div>
	</div>
</template>

<script>

import ProjectModal from "@/components/ProjectModal"
import {mapGetters, mapState} from "vuex"
import ContentModal from "@/components/ContentModal"
import BuildingSpecs from "@/components/BuildingSpecs"
import SliderGallery from "@/components/SliderGallery"
import FpFlipBook from "@/components/FpFlipBook"
import IllustrationOnlyDisclaimer from "@/components/IllustrationOnlyDisclaimer"
import MasonryGallery from "@/components/MasonryGallery"
import ShareLinks from "@/components/ShareLinks"
import FpButton from "@/components/buttons/FpButton"
import {delay} from "@/utils"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import ClickHere from "@/components/ClickHere.vue";
import FpNewIcon from '@/components/buttons/FpNewIcon.vue';
import FpIconButton from "@/components/buttons/FpIconButton"
import ModelFrame from "@/components/ModelFrame.vue"
import Popper from "vue3-popper";

export default {
	name: "ApartmentPage",
	components: {
		ClickHere,
		FpButton,
		MasonryGallery,
		IllustrationOnlyDisclaimer,
		ProjectModal,
		FpFlipBook, SliderGallery, BuildingSpecs, ContentModal,
		FpNewIcon,
		FpIconButton,
		ModelFrame,
		Popper,
		ShareLinks
	},
	computed: {
		hasTabs() {
			return this.apartmentContent && this.apartmentContent.tabs && this.apartmentContent.tabs.length > 1
		},
		hasInnerTabs() {
			return this.hasTabs && this.apartmentContentType === 'specifications'
		},
		product() {
			if (this.currentProductId && this.$store.state.products) {
        if(this.$store.state.products[this.currentProductId]){
          return this.$store.state.products[this.currentProductId]
        }
        if(Array.isArray(this.$store.state.products) && this.$store.state.products.length > 0){
          return this.$store.state.products.find(product => product.id === this.currentProductId)
        }
			}
			return null
		},
		apartmentContentCurrentTab() {
			if (this.currentButton && this.currentButton.tabs && this.currentButton.tabs[this.currentContentTabIndex] && this.currentButton.tabs[this.currentContentTabIndex].content) {
				if (this.currentButton.tabs[this.currentContentTabIndex].content[0]) {
					return this.currentButton.tabs[this.currentContentTabIndex].content[0]
				}
				// return this.apartmentContent.tabs[0].content[0]
			}
			return null
		},
		showThumbnail() {
			return this.apartmentThumbnailDisplay && this.apartmentThumbnailDisplay.indexOf("apartment_page") > -1
		},
		currentContentTabClickAction() {
			if (this.apartmentContentCurrentTab?.click_action) {
				return this.apartmentContentCurrentTab.click_action
			}
			return null
		},
		...mapState(['currentProductId', 'currentProjectId']),
		...mapGetters([
			'isMobile',
			'apartmentContent',
			'isSideMenu',
			'apartmentThumbnailDisplay',
			'hasWhatsappButton',
			'showFloatingContactButton',
			'bottomBarHeight',
			'apartmentContentType',
			'mobileButtonsHeight',
			'products',
			'currentProject',
			'colors',
			'apartmentPageFieldsOrder'
		])
	},
	watch: {
		apartmentContent() {
			this.changeContentTab(0)
		},
		products() {
			// this.loadProduct(this.$route)
		},
		currentProductId() {
			// this.loadProduct(this.$route)
		},
		showModel() {
			this.$frameBus.emit('message', {
				action: 'hover-apartment',
				apartment: this.product
			})
		}
	},
	data() {
		return {
			currentContentTabIndex: 0,
			lightbox: null,
			currentButton: null,
			showModel: false,
			show:false
		}
	},
	methods: {
		changeContentTab(tabIndex) {
			this.currentContentTabIndex = tabIndex

			this.destroyPs()
			this.$nextTick().then(() => {
				this.initPs()
			})
		},
		async projectButtonClicked(button) {
			this.currentContentTabIndex = 0
			if (this.currentButton) {
				this.currentButton = null
				await delay(500)
			}
			this.currentButton = button

			this.destroyPs()
			this.$nextTick().then(() => {
				this.initPs()
			})
		},
		async loadProduct(to) {
			const id = to.params.apartmentId ? to.params.apartmentId : null
			await this.$store.dispatch('loadProduct', id)
			this.openFirstContent()
		},
		closeContent() {
			this.$store.commit('apartmentContent', null)
		},
		openFirstContent() {
			if (!this.isMobile) {
				if (!this.apartmentContent) {
					if (this.product && this.product.buttons[0]) {
						const firstContent = this.product.buttons[0]
						this.$store.commit('apartmentContent', firstContent)
					} else if (this.apartmentContent) {
						this.$store.commit('apartmentContent', null)
					}
				}
			}
		},
		goToApartments() {
			this.$router.push({ name: 'apartments', params: { id: this.currentProjectId } })
		},
		initPs() {
			if (this.currentContentTabClickAction !== 'edit' || this.isMobile) {
				if (!this.lightbox) {
					this.lightbox = new PhotoSwipeLightbox({
						gallery: '.gallery-slides',
						children: '.ps-gal a',
						pswpModule: () => import('photoswipe'),
					});
					this.lightbox.init();
				}
			}
		},
		destroyPs() {
			if (this.lightbox) {
				this.lightbox.destroy();
				this.lightbox = null;
			}
		},
    detailValue(detail) {
      if(this.product && this.product.display_fields){
        return this.product.display_fields.find(field => field.field === detail.field)?.value
      }
      return null
    }
	},


	mounted() {
		this.show = false
		this.destroyPs()
		this.$nextTick().then(() => {
			this.initPs()
			this.show = true
		})


	},
	beforeRouteEnter(to, from, next) {
		next(async vm => {
			await vm.loadProduct(to)
		})
	},
	beforeRouteLeave() {
		this.currentButton = null
		this.closeContent()
	},
	async beforeRouteUpdate(to) {
		await this.loadProduct(to)
	},
}
</script>

<style lang="scss">
.page.apartment {
	display: flex;
	align-items: flex-start;

	background-color: black;

	.centered-content {

		flex-flow: row nowrap;
		align-items: flex-start;
		flex-grow: 1;
		gap: 15px;

	}

	.apartmentInfo {
		position: absolute;
		bottom: 0.8rem;
		right: 1rem;
		justify-content: space-between;
		z-index: 2;

		.floor {
			font-size: 4.75rem;
			font-style: normal;
			font-weight: 400;
			margin-left: .8rem;
		}

		.details {
			display: flex;
			width: 17vw;
			flex-wrap: wrap;

			.detail {
				background: #4D4D4D;
				border-radius: 6.25rem;
				padding: .5rem .8rem;
				width: max-content;
				margin: .3rem;
				flex-shrink: 0;
				font-size: 0.8rem;

				.value {
					margin-left: .2rem;
				}
			}
		}
	}

	.modelFrameWrapper {
		position: fixed;
		bottom: 6rem;
		left: -100rem;
		width: 300px;
		height: 400px;
		background-color: #4D4D4D;
		padding: .5rem;
		transition: left .3s ease;

		.modelFrame {
			position: relative;
			width: 284px;
			height: 385px;
			.model-frame-wrapper {
				left: 0 !important;

			}
		}

		&.showModel {
			left: 1rem;
		}

	}

	.social-buttons {
		position: absolute;
		bottom: 2.1rem;
		left: 1rem;
		width: 15rem;
		z-index: 22;

		.btn-secondary-outline {
			width: 10rem !important;
		}

		.modelButton {
	
			border-color: var(--primary);
		}

	}

	.horizontalSlider {
		height: 20rem;
	}
}

.apartment-modal-wrapper {

	&.v-enter-active,
	&.v-leave-active {
		transition: opacity .8s ease;
	}

	&.v-enter-from,
	&.v-leave-to {
		opacity: 0;
	}
}

.apartment-modal {

	flex-shrink: 0;
	flex-grow: 0;
}

.content-modal {
	height: 89vh;
	

	&.v-enter-active,
	&.v-leave-active {
		transition: opacity .8s ease;
	}

	&.v-enter-from,
	&.v-leave-to {
		opacity: 0;
	}

	:deep(.content-modal-header) {
		display: flex;
		width: 100%;

		&.tabs {
			background-color: #000;
			color: #fff;
		}

		nav.header-tabs {
			flex-grow: 1;

			ul {
				display: flex;
			}

			ul button {
				color: #fff;
				font-size: 18px;
				margin: 0 6px;

				&.active {
					color: var(--primary-light);
				}
			}
		}

	}

	:deep(nav.footer-tabs) {
		display: flex;
		gap: 15px;
		margin-bottom: 15px;
		z-index: 10;
		overflow-x: auto;
		margin-right: 10px;
		margin-left: 10px;

		.btn {
			background-color: #000;

			&:first-child {
				margin-right: auto;
			}

			&:last-child {
				margin-left: auto;
			}
		}
	}
	.gallery-slides {
		height: 100%;

		.image-edit {
			height: 100%;
			text-align: center;
			img {
				height: calc(100svh - 186px);
				width: auto;
			}
		}
	}
}
.lowResLaptop {
	.apartmentInfo {

		align-items: flex-start;

		flex-direction: column-reverse;
		.floor {
			font-size: 4.75rem;
			font-style: normal;
			font-weight: 400;
			margin-left: .8rem;
		}

		.details {
			display: flex;
			width: 17vw;
			flex-wrap: wrap;
			flex-direction: column;
			.detail {
				background: #4D4D4D;
				border-radius: 6.25rem;
				padding: .5rem .8rem;
				width: max-content;
				margin: .3rem;
				flex-shrink: 0;
				font-size: 0.8rem;

				.value {
					margin-left: .2rem;
				}
			}
		}
	}
}

.is-desktop,
.is-tablet {
  .page.apartment {
    .project-modal {
      position: sticky;
      bottom: 0;
    }

    .apartmentInfo {
      flex-flow: column-reverse;
    }
  }
}

.is-mobile {
	.page.apartment {
		padding: 0;
		.content-modal {
			z-index: 21 !important;
		}
		.apartmentInfo {
			position: fixed;
			bottom: 0rem;
			right: 0 !important;
			left: 0 !important;
			width: 100vw;
			z-index: 21 !important;
			padding: 0 .3rem 1rem .3rem ;
			justify-content: space-between;
			background: linear-gradient(180deg, rgba(28, 28, 28, 0.00) 0%, rgba(28, 28, 28, 0.90) 80.21%);
			.floor {
        display: none;
				font-size: 2.3rem;
				background-color: rgba(77, 77, 77, 1);
				color: black;
				border-radius: 50%;
				width: 4rem;
				height: 4rem;
				justify-content: center;
				align-items: center;
				margin-left: .5rem;
				
			}

			.details {
		
				width: 68vw;

	
				.detail {
					background: #4D4D4D;
					border-radius: 6.25rem;
					padding: .3rem .5rem;
					width: max-content;
					margin: .15rem;
					flex-shrink: 0;
					font-size: 0.8125rem;
					
	
					.value {
						margin-left: .2rem;
					}
				}
			}
		}
	}
	.fpCircle {

		z-index: 23 !important;
		width: 4rem;
		height: 4rem;
		font-weight: 700;
		font-size: 0.875rem;

	}
	.page.project {
		padding: 0;
		flex-flow: column;

		.centered-content {
			flex-flow: column;
			gap: 0;
		}
	}
	.content-modal :deep(.content-modal-header) {
		white-space: nowrap;
		max-width: 100vw;
		overflow-x: auto;
		
		.contextWrapper {

			max-width: 100%;
			max-height: 50vh;
			overflow-y: auto;
			padding-top: 4rem !important;
			padding-right: 1rem !important;
			white-space: normal;
			.projectHeader {
				font-size: 3rem;
				margin-bottom: 1.5rem;
				border-radius: 0;
				position: relative;
				z-index: 7;


			}
			.project-content {
				-webkit-column-count: 1;
				/* Chrome, Safari, Opera */
				-moz-column-count: 1;
				/* Firefox */
				column-count: 1;
		
				-webkit-column-gap: 0;
				/* Chrome, Safari, Opera */
				-moz-column-gap: 0;
				/* Firefox */
				column-gap: 0;
		
				-webkit-column-rule: 1px solid black;
				/* Chrome, Safari, Opera */
				-moz-column-rule: 1px solid black;
				/* Firefox */
				column-rule: 1px solid black;
				font-size: .9rem;
				font-style: normal;
				font-weight: 400;
				line-height: 1rem;
		
			}
		}
	}
	.project-current-button.full {
		.project-current-button-header {
			display: flex;
			border-bottom: none;
		}
	}
	.project-modal {
		padding: 0;
		border-radius: 0;
		border: none;
		margin-top: 0;
		background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, .81) 43.75%, rgba(0, 0, 0, 0.50) 55.13%, rgba(0, 0, 0, 0.3) 70%,  rgba(0, 0, 0, 0) 100%);
		z-index: 21;
	}
}

.fp-side-menu-wrapper+.fp-app-wrapper {
	.page.apartment {
		padding: 0;
		align-items: stretch;


		.centered-content {
			gap: 0;
			max-width: none;
			align-items: stretch;
			overflow: hidden;
			height: 100%;
		}

		:deep(.content-modal) {
			border: none;
			border-radius: 0;
			max-width: none;
			display: flex;
			flex-flow: column;
			align-items: stretch;

		}

	}
}

.ltr {

	.apartment-modal-wrapper {

		&.v-enter-from,
		&.v-leave-to {
			transform: translateX(-500px);
		}
	}

	.content-modal {

		&.v-enter-from,
		&.v-leave-to {
			transform: translateX(100vw);
		}

		:deep(nav.footer-tabs) {
			.btn {
				background-color: #000;

				&:first-child {
					margin-right: 10px;
					margin-left: auto;
				}

				&:last-child {
					margin-left: 10px;
					margin-right: auto;
				}
			}
		}
	}

	.apartmentInfo {
		right: auto !important;
		left: 1rem !important;

		.floor {
			margin-right: .8rem;
		}

		.details {

			.detail {

				.value {
					margin-right: .2rem;
				}
			}
		}
	}

	.modelFrameWrapper {
		
		left: auto;
		right: -100rem;

		transition: right .3s ease !important;

		.modelFrame {
			position: relative;
			width: 284px;
			height: 385px;
			.model-frame-wrapper {
				right: 0 !important;
				left: auto !important;

			}
		}

		&.showModel {
			right: 1rem;
			left: auto !important;
		}

	}

	.social-buttons {
		left: auto !important;
		right: 1rem !important;

	}
}
</style>
