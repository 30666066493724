<template>
    <transition appear>
        <div class="wrapper" :style="{
            width: circleSize + 'px',
            height: circleSize + 'px',
        }">
            <AnimatedCircle :radius="radius" :width="circleSize" :height="circleSize" :hoverMode="false"
            :animate="animate"
            :hoverColor="null" 
            :iterations="Infinity"
            :duration="3000"
            :active="false"
            :strokeColor="color" baseStrokeColor="black"
            />
    
            <div class="childCircle" :style="{
                width: circleSize * 0.80 + 'px',
                height: circleSize * 0.80 + 'px',
            }">
                <AnimatedCircle :radius="radius * 0.80" :width="circleSize * 0.80" :height="circleSize * 0.90" :hoverMode="false"
                :animate="animate"
                :hoverColor="null" 
                :iterations="Infinity"
                :startOffset="300"
                :duration="3000"
                :active="false"
                :strokeColor="color" baseStrokeColor="#000"
                />
    
            </div>
            <div class="childCircle" :style="{
                width: circleSize * 0.60 + 'px',
                height: circleSize * 0.60 + 'px',
            }">
                <AnimatedCircle :radius="radius * 0.60" :width="circleSize * 0.60" :height="circleSize * 0.90" :hoverMode="false"
                :animate="animate"
                :hoverColor="null" 
                :iterations="Infinity"
                :startOffset="600"
                :duration="3000"
                :active="false"
                :strokeColor="color" baseStrokeColor="#000"
                />
    
            </div>
        </div>

    </transition>
</template>
<script>
import AnimatedCircle from "@/components/AnimatedCircle.vue"
export default {
    name: 'AnimatedCircles',
    components: {
        AnimatedCircle
    },
    // data() {
    //     return {
    //         circleSize: 120 * 2,
    //         radius: 50 * 2
    //     }
    // },
    props: {
        radius: {
            type: Number,
            required: false,
            default: 50,
        },
        circleSize: {
            type: Number,
            required: false,
            default: 120,
        },
        animate: {
            type: Boolean,
            required: false,
            default: true,
        },
        color: {
            type: String,
            required: false,
            default: "#fff",
        },
    }
}
</script>
<style scoped lang="scss">
    .wrapper {
        position: relative;
        &.v-enter-active,
        &.v-leave-active {
            transition: opacity .6s ease;
        }
    
        &.v-enter-from,
        &.v-leave-to {
            opacity: 0;
        }
        .childCircle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>