<template>
		<transition appear>
				<div class="floors-filter" :class="{collapsed}" v-if="filterEnabled">
						<div class="filter-header" tabindex="1" role="button" @click="collapsed = !collapsed">
							<ul class="tabs" role="menu">
									<li class="text-right">סינון</li>
							</ul>
							<fp-icon-button :symbol="collapsed ? 'angel-down':'angel-up'"></fp-icon-button>
						</div>
						<!--<ul class="tabs" role="menu">
								<li v-for="(tab, ti) in tabs" :key="ti" @click="selectTab(tab)" :class="{active: tab.id === currentTab}" tabindex="0" role="tab">{{tab.label}}</li>
						</ul>-->
						<div v-if="!collapsed" class="tabs-contents">
							<div class="tab" v-if="currentTab === 'residence'">
									<form @submit.prevent="residenceFilter">
											<div class="row">
													<fp-radio-group
															v-if="settings.buildings.show"
															v-model="filters.residence.building"
															name="building"
															:options="options.building"
													>{{settings.buildings.label || $t('בניין')}}</fp-radio-group>
											</div>
											<div class="row">
													<div class="fp-select-wrapper" v-if="settings.floors.show">
															<fp-select v-model="filters.residence.floor" name="floor">
																	<template #label>{{settings.floors.label || $t('קומה')}}</template>
																	<option value="">{{$t('הכל')}}</option>
																	<option v-for="(option, optInd) in options.floor" :key="optInd" :value="option.value || option">{{option.label || option}}</option>
															</fp-select>
													</div>
											</div>
											<div v-if="isMobile" class="form-buttons">
													<fp-button type="submit" variant="primary-outline">{{$t('חפש')}}</fp-button>
													<a href="javascript:void(0)">{{$t('נקה הכל')}}</a>
											</div>
									</form>
							</div>
						</div>
				</div>
		</transition>
</template>

<script>
import FpRadioGroup from "@/components/buttons/FpRadioGroup";
import FpSelect from "@/components/FpSelect";
import {mapGetters} from "vuex";
import FpButton from "@/components/buttons/FpButton";
import FpIconButton from "@/components/buttons/FpIconButton";

const defaultSettings = {
    quarters: {
        label: "שכונה",
        show: true,
        options: []
    },
    plots: {
        label: "מגרש",
        show: true,
        options: []
    },
    buildings: {
        label: "בניין",
        show: true,
        options: []
    },
    rooms: {
        label: "חדרים",
        show: true,
        options: [
            "2",
            "3",
            "4",
            "5",
            "6"
        ]
    },
    apartment_types: {
        label: "טיפוס",
        show: true,
        options: []
    },
    floors: {
        label: "קומה",
        show: true,
        options: []
    }
}

export default {
    name: "FloorsFilter",
		emits: ['filter'],
    components: {FpIconButton, FpSelect, FpRadioGroup, FpButton},
    data(){
        return {
            currentTab: 'residence',
            tabs: [
                {
                    id: 'residence',
                    label: 'מגורים'
                },
                {
                    id: 'offices',
                    label: 'משרדים'
                },
                {
                    id: 'hotels',
                    label: 'מלונאות'
                }
            ],
            filters: {
                residence: {
                    building: null,
                    quarter: null,
                    plot: null,
                    rooms: null,
                    type: null,
                    floor: null,
                }
            },
            options: {
                building: ['1', '2', '3', '4'],
                floor: [1,2,3,4,5,6,7,8,9,10,11,12],
            },
            collapsed: true
        }
    },
    computed:{
        filterEnabled(){
            return (
							this.settings.floors.show ||
							this.settings.buildings.show
						)
        },
        settings(){
            if(this.$store.getters.settings && this.$store.getters.settings.floors_filter){
                return this.$store.getters.settings.floors_filter
            }
            return defaultSettings
        },
				buildingMandatory(){
						if(this.settings && this.settings.buildings && typeof(this.settings.buildings.mandatory) !== 'undefined'){
								return this.settings.buildings.mandatory
						}
						return false
				},
				defaultBuildingOption(){
						if(this.settings && this.settings.buildings && this.settings.buildings.default_building_option){
								return this.settings.buildings.default_building_option
						}
						return null
				},
        ...mapGetters(['isMobile', 'lowResLaptop', 'isSideMenu','filter'])
    },
    watch:{
        filters: {
            handler(newVal){
                if(!this.isMobile){
                    this.$emit('filter', newVal[this.currentTab])
                }
            },
            deep: true
        },
        settings(newVal){
            if(newVal){
                this.setOptions()
            }
        },
				filter(newVal){
						if(newVal){
							this.setOptions()
						}
				}
    },
    methods: {
        selectTab(tab){
            this.currentTab = tab.id
        },
        residenceFilter(){
            this.$emit('filter', this.filters[this.currentTab])
        },
        setOptions(){
            if(this.settings.buildings.options){
                this.options.building = this.settings.buildings.options
            }

            if(this.settings.floors.options){
                this.options.floor = this.settings.floors.options
            }

            if(this.settings.buildings && this.settings.buildings.data_source === 'dynamic' && this.filter?.buildings){
                this.options.building = this.filter?.buildings
            }

            if(this.settings.floors && this.settings.floors.data_source === 'dynamic' && this.filter?.floors){
                this.options.floor = this.filter?.floors
            }
        }
    },
    mounted() {
        this.setOptions()
				if(this.isSideMenu){
						this.collapsed = false
				}else{
						this.collapsed = !!this.lowResLaptop;
				}
    }
}
</script>

<style scoped lang="scss">

    .floors-filter {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 5px;
        width: 300px;
        max-width: calc(100vw - 2rem);
        flex-grow: 0;
        margin-bottom: auto;

        .filter-header {
          display: flex;
          flex-flow: row nowrap;
          border-bottom: solid 1px #fff;
        }

        .tabs {
            flex-grow: 1;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;

            li {
                padding: 15px;
                flex-grow: 1;
                text-align: center;
                cursor: pointer;

                &::after {
                    display: none;
                    content: "";
                    width: 100%;
                    height: 3px;
                    background-color: var(--primary);
                    position: absolute;
                    top: 100%;
                    left: 0;
                    margin-top: -1px;
                }

                &.active {
                    position: relative;
                    font-weight: 700;
                    color: var(--primary);

                    &::after {
                        display: block;
                    }
                }

            }
        }

        .tab {
            padding: 0.9375rem 1.875rem 1.875rem;

            .fp-radio-group {
                margin-bottom: 1rem;
            }
        }
    }

    .is-mobile {
        .floors-filter {
            width: 100%;
            max-width: none;
            border: none;
            border-radius: 0;
            background-image: url("@/assets/filter-bg.png");
            background-color: #000;
            background-position: left bottom;
            background-size: contain;
            background-repeat: no-repeat;

            :deep(.row) {
                display: flex;
                flex-flow: row wrap;
                gap: 1rem;
                justify-content: space-between;
                width: calc(100vw - 1.875rem * 2);
            }
        }

        .form-buttons {
            display: flex;
            gap: 1rem;
            align-items: center;
            margin-top: 1rem;
        }
    }

		.fp-side-menu-wrapper + .fp-app-wrapper {
				.floors-filter {
						flex-grow: 0;
						margin-bottom: 0;
						border-radius: 0;
						border: none;
						width: auto;
						min-width: 300px;
						backdrop-filter: blur(5px);
						background-color: rgba(0, 0, 0, 0.8);
						background-image: none;

						.fp-radio-group {
								display: flex;
								gap: 10px;
								align-items: center;
								justify-content: space-between;

								:deep(.fp-radio-group-label) {
										margin-bottom: 0;
								}
						}

						.fp-select-wrapper {
								display: flex;
								align-items: center;
								gap: 10px;
								justify-content: space-between;
						}
				}
		}
</style>
