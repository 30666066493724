<template>
		<div
			class="model-frame-wrapper"
			:class="{hidden}"
		>
		<transition appear v-if="show">
			<iframe
					ref="iframeRef"
					@mouseenter="mouseEnterIframe"
					@mouseleave="mouseLeaveIframe"
					class="frame-3d"
					:src="iframeSrc"
          :key="iframeKey"
			></iframe>

		</transition>
		</div>
</template>

<script>
import {mapGetters} from "vuex"
// import AnimatedCircles from "@/components/AnimatedCircles.vue"
export default {
		name: "ModelFrame",
		props: {
				width: {
						type: String,
						default: '200px'
				},
				height: {
						type: String,
						default: '200px'
				},
				src: {
						type: String,
						default: null
				},
				showResizeBtn: {
						type: Boolean,
						default: false
				},
		},
		data(){
				return {
						resizeBtn: false,
						hidden: true,
						show: true,
            iframeKey: 1
				}
		},
		// components: {
		// 		AnimatedCircles
		// },
		computed: {
				baseUrl(){
						return process.env.VUE_APP_BASE_URL
				},
				modelIframeUrl(){
						return process.env.VUE_APP_3D_MODEL_IFRAME
				},
				iframeSrc(){
						if(this.src){
								return this.src
						}

						if(this.modelIframeUrl.includes('http')){
								return this.modelIframeUrl;
						}

						if(window.location && window.location.host && window.location.host.indexOf('localhost') === -1){
								return `${window.location.protocol}//${window.location.host}${this.modelIframeUrl}`
						}else{
								return `${this.baseUrl}${this.modelIframeUrl}`
						}
				},
				inAdminPath(){
						return this.$route.path.includes('fp-admin')
				},
				...mapGetters([
						'ctrl360',
						'showApartments3DModel',
						'showApartment3DModel',
						'showHome3DModel',
						'colors',
            'locale'
				])
		},
		watch: {
				ctrl360(value){
						if(value){
								this.resizeBtn = true
						}else{
								this.resizeBtn = false
						}
				},
				'$route'(to){
						this.checkVisibility(to)
				},
        /*locale(){
          this.refreshIframe()
        }*/
		},
		methods: {
				mouseEnterIframe(){
						this.broadcastAction({
								action: 'mouse-on-3d-model'
						})
				},
				mouseLeaveIframe(){
						this.broadcastAction({
								action: 'mouse-on-floorplan'
						})
				},
				broadcastAction(data){
						if(this.$frameBus){
								this.$frameBus.emit('message', data);
						}
				},
				checkVisibility(route){
						if(route.name === 'apartment'){
			
								this.hidden = true
						}else if(route.name === 'apartments' && this.showApartments3DModel){
								this.hidden = false
						}else if(route.name === 'home' && this.showHome3DModel){
								this.hidden = false
						}else if(this.inAdminPath){
								this.hidden = false
						}
						// else if(route.fullPath.includes("area")){
						// 	this.broadcastAction({
						// 		action: 'show3dMap'
						// 	})
						// 	this.hidden = false
						// }
						else{
								this.hidden = true
						}
				},
				iframeResize(){
						const iframe = this.$refs.iframeRef
						if(iframe){
								// this.broadcastAction({
								// 		action: 'resize',
								// 		iframe: {
								// 				width: iframe.clientWidth,
								// 				height: iframe.clientHeight
								// 		}
								// })
						}
				},
        refreshIframe(){
          this.iframeKey++;
        }
		},
		mounted() {
				this.checkVisibility(this.$route)
				if(this.$refs.iframeRef){
						this.iframeResize();
						new ResizeObserver(this.iframeResize).observe(this.$refs.iframeRef)
				}
		}
}
</script>
<style scoped lang="scss">
.model-frame-wrapper {
		background-color: #000;
		transition: .3s all ease;
		&.in-home {
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 10;
				transition: all ease .5s;
		}

		&.hidden {
				left: 100%;
		}

		.frame-3d {
				width: 100%;
				height: 100%;
						&.v-enter-active,
		&.v-leave-active {
			transition: transform 1s ease;
		}

		&.v-enter-from,
		&.v-leave-to {
			transform: translateX(500px);
		}

		}
		.loadingSpinner {
				position: absolute !important;
				z-index: 10;
				top: 50svh;
				left: 50vw;
				transform: translate(-50%, -50%);

				
		}
}

</style>
