<template>
    <div class="floating-whatsapp-button"
         :style="buttonStyle"
    >
        <tel-link type="whatsapp"
                  :tel="contactDetails.whatsapp.phone"
                  :message="contactDetails.whatsapp.message"
        >
            <span>WhatsApp</span>
        </tel-link>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import TelLink from "@/components/TelLink";

export default {
    name: "FloatingWhatsappButton",
    components: {TelLink},
    computed: {
        buttons(){
            if(this.settings && this.settings.mobile_buttons){
                return this.settings.mobile_buttons
            }
            return null
        },
        buttonStyle(){
            const style = {}
            if(this.buttons && this.buttons.length){
                this.buttons.forEach((btn) => {
                    if(btn.min_height){
                        const mb = `${btn.min_height}px`
                        if(!style.marginBottom || parseInt(style.marginBottom) < parseInt(mb)){
                            style.marginBottom = mb
                        }
                    }
                    if(btn.font_size){
                        const fs = btn.font_size * 16
                        const mb = `${fs}px`
                        if(!style.marginBottom || parseInt(style.marginBottom) < parseInt(mb)){
                            style.marginBottom = mb
                        }
                    }
                })
            }
            return style
        },
        ...mapGetters(['settings', 'contactDetails'])
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
    .floating-whatsapp-button {
        position: fixed;
        z-index: 10;
        bottom: 80px;
        left: 10px;

        a.tel-link {
            background-color: #003200;
            padding: 5px 10px;
            border-radius: 30px;
            color: #fff;
            box-shadow: 2px 2px 2px 0 #000;
        }
    }
</style>
