<template>
    <div class="project-modal">
        <div class="project-buttons" v-if="project && project.buttons && project.buttons.length">
            <span class="active-icon"
                        v-for="(button, bIndex) in project.buttons"
                        :key="bIndex"
                        :class="{active: isCurrent(button)}"
                        variant="primary-outline"
                        @click.stop="buttonClicked(button)"
            >
                <MenuButton v-if="button.tabs && button.tabs.length > 1" 
                    :subMenus="button.tabs.map(tab => tab.label)" 
                    :buttonText="button.label" 
                    @subMenuClicked="subMenuIndex => subMenuClicked(subMenuIndex, button)"
                    :active="isCurrent(button)"
                    :activeSubMenuIndex="currentSubMenuIndex"
                    >
                        <span class="buttonText">{{button.label}}</span>
                    </MenuButton>

                <FpAnimatedCircledIcon 
                    v-else-if="!isMobile"
                    :r="40"
                    :width="100"
                    :height="100"
                    :active="isCurrent(button)"
                    :strokeColor="colors.primary"
                    baseStrokeColor="transperent">	
                    <span class="buttonText ">{{button.label}}</span>
                </FpAnimatedCircledIcon>


                <span v-else class="buttonText menu-button-wrapper" >{{button.label}}</span>
            </span>
        </div>
    </div>
</template>

<script>
// import FpButton from "@/components/buttons/FpButton";
import MenuButton from "@/components/buttons/MenuButton";
// import CloseBtn from "@/components/buttons/CloseBtn";
import clickSound from '@/assets/iPhoneClick.mp3'
import FpAnimatedCircledIcon from "@/components/FpAnimatedCircledIcon.vue"
import {mapGetters} from "vuex";
// import FpIcon from "@/components/FpIcon";
export default {
    name: "ProjectModal",
    components: {
        FpAnimatedCircledIcon,
        MenuButton
        // CloseBtn, 
        // FpIcon,
        // FpButton
        },
    data() {
        return {
            audio: new Audio(clickSound)
        }
    },
    props: {
        project: {
            type: Object
        },
        currentButton: {
            type: Object
        },
        currentSubMenuIndex: {
            type: Number
        }
    },
    computed:{
        useInnerTitle(){
            if(this.$store.getters.settings && this.$store.getters.settings.projects){
                return this.$store.getters.settings.projects.use_inner_title
            }
            return false
        },
				...mapGetters(['isMobile', 'colors'])
    },
    methods: {
        close(){
            this.$store.commit('toggleProjectModal', false)
            setTimeout(() => {
                this.$router.push('/')
            }, 500)
        },
        buttonClicked(button){
            if (this.isMobile){
                // this.audio.play();
            }
            if (!this.currentButton || this.currentButton.label != button.label){
                this.$emit('button-clicked', button)
                if (button.tabs && button.tabs.length > 1) {
                    this.$emit('subMenu-clicked', 0)
                }

            }
            // if (!this.isMobile){

            // } else {
            //     this.$emit('button-clicked', button)
            //     if (button.tabs.length == 1) {
            //         this.$emit('subMenu-clicked', 0)
            //     }
            // }
        },
        subMenuClicked(subMenuIndex, button){
            this.audio.play();
            if (this.currentButton && this.currentButton.label !== button.label){

                this.$emit('button-clicked', button)
            }
            this.$emit('subMenu-clicked', subMenuIndex)

       
        },

        isCurrent(button){
            if (this.currentButton){
                return button.label == this.currentButton.label

            } else return false
            // return JSON.stringify(button) === JSON.stringify(this.currentButton)
        }
    },
    mounted() {
        if(this.project && this.project.buttons && this.project.buttons[0]){
            this.buttonClicked(this.project.buttons[0])
        }
    }
}
</script>

<style scoped lang="scss">
    .project-modal {
        position: relative;
        width: 100%;
        
        padding: 0.9375rem;
        border-radius: 5px;
        max-height: none;
        position: relative;
        background-color: transparent;
        background: linear-gradient(0deg, #242424 0%, rgba(37, 37, 37, 0.95) 23.75%, rgba(37, 37, 37, 0.85) 48.33%, rgba(38, 38, 38, 0.70) 65.13%, rgba(39, 39, 39, 0.3) 90%,  rgba(39, 39, 39, 0) 100%);

        border: none;
        border-radius: 0;
        align-items: center;
        &.init-hidden {
            display: none;
        }

        .modal-header {
            font-size: 1.125rem;
            font-weight: 700;
            margin-bottom: 10px;
            min-height: 1.125rem;
        }

        .modal-content {
            line-height: 1.2;
        }

        .close-btn {
            position: absolute;
            top: 0.9375rem;
            left: 0.9375rem;
        }

        .project-icons {
            display: flex;
            flex-flow: row wrap;
            padding: 1.25rem;
            gap: 2.8125rem;
            align-items: flex-start;

            > span {
                flex-basis: 8.3125rem;
                display: flex;
                align-items: center;
                flex-flow: row nowrap;
                gap: 0.625rem;
            }
        }

        .project-content {
            font-size: 1.125rem;

            &:deep(p) {
                margin-bottom: 0.9375rem;
            }
        }

        .project-buttons {
                       
            display: flex;
            
            align-items: center;
            height: 100%;
            gap: .75rem;
            .active-icon {
                margin: 0 1.6rem;
                font-size: 1rem;
                height: 5rem;
                width: 5rem;
                text-align: center;
                border-radius: 50%;
                display: flex;
                justify-content: space-around;
                align-items: center;

                .buttonText {
                    position: relative;
                    font-size: 0.8rem;
                    box-sizing: border-box;
         
                
                /*&:after {
                    content: "";
                    transition: width .3s ease; 
                    width: 0;                    
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    border-bottom: 2px solid var(--primary)
                    }*/
                }
                
            }
            span {

            }
            /*.active{
                .buttonText{
                    &:after {
                    content: "";
                        width: 100%;                    
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        border-bottom: 2px solid var(--primary)
                    }
                }
            }*/
                
            
            &:deep(button) {
                min-height: 3rem;
                border-radius: 4px;
                font-size: 1rem;
								line-height: 1;
            }
        }
    }

    .is-mobile {
        .project-modal {
            .project-icons {
                padding: 1.25rem 0;
                gap: 1rem;

                > span {
                    flex-basis: calc(50% - .5rem);
                }
            }
            .project-buttons {
                gap: .2rem;
								margin-top: 4rem;
            }
            .active-icon {
                margin: 0 .3rem;
                font-size: 1rem;
                height: auto;
                width: auto;
          
                &.active {
                    .menu-button-wrapper {
                        border-color: var(--primary);

                    }
                }
                .menu-button-wrapper {
                    position: relative;
                    padding: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 10;
                    border: 1px solid #6B6C6D;
                    height: 1.7rem;
                    border-radius: 6.25rem;
                    width: 5.8255rem;
                }

            }
        }
    }

    .is-desktop,
    .is-tablet {
        .project-modal {
                display: flex;
                flex-flow: column;
                align-items: center;
                .modal-content {
                        display: flex;
                        flex-flow: column;
                        flex-grow: 1;
                }
        }
    }



    .ltr {
            .close-btn {
                    right: 0.9375rem;
                    left: auto;
            }
    }

</style>
