<template>
	<div class="admin mapbox-admin" v-if="currentProject">
		<h3>{{ $t('ניהול מפה') }}</h3>
		<MapBoxComponent class="mapbox" ref="mapBox" :modelUrl="modelUrl" :isMobile="isMobile" :isAdmin="true"
			:center="center" :projectDefaultView="currentProject.default_view" />
	</div>
</template>

<script>

import { mapGetters } from "vuex"
import MapBoxComponent from "@/components/MapBoxComponent.vue";

export default {
	name: "MapBoxAdmin",
	components: { MapBoxComponent },
	computed: {
		center() {
			console.log("this.contentPages", this.contentPages)
			let coords
			if (this.contentPages) {
				Object.entries(this.contentPages).forEach(e => {
					// console.log("e", e[1])
					console.log("e.map", e[1])
					if (e[1].map) {
						console.log(e[1].map)
						// coords = e[1].map.center_coordinates
						coords = {
							lat: parseFloat(e[1].map.center_coordinates_lat),
							lng: parseFloat(e[1].map.center_coordinates_lng),
						}



					}
				})

			}
			console.log({coords})
			return coords

		},
		modelUrl() {
			console.log("this.contentPages", this.contentPages)
			let url
			if (this.contentPages) {
				Object.entries(this.contentPages).forEach(e => {
					// console.log("e", e[1])
					console.log("e.map", e[1])
					if (e[1].area_model) {
						url = e[1].area_model



					}
				})

			}
			console.log({url})
			return url

		},
		...mapGetters(['accessToken', 'isMobile', 'currentProject', 'contentPages']),

	},
	methods: {
		listenFramebus() {
			this.$frameBus.on('message', this.onFramebusMessage)
		},
		unListenFramebus() {
			this.$frameBus.off('message', this.onFramebusMessage)
		},
		onFramebusMessage(data) {
			const { action } = data
			if (action === 'init') {
				this.$frameBus.emit('message', {
					action: 'i-am-admin',
					token: this.accessToken,
					show_view_actions: true
				})
				console.log('i-am-admin has sent')
			}
		},

	},
	mounted() {
		this.listenFramebus()
	},
	unmounted() {
		this.unListenFramebus()
	}
}
</script>

<style scoped lang="scss"></style>
