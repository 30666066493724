<script>
import { computed, ref, watch } from 'vue';
import { Collapse } from 'vue-collapsed';

export default {
  name: 'HelloWorld',
  components: { Collapse },
  props: {
    headerStyle: {
        type: Object,
        required: false,
        default: function(){
            return {}
        }
    },
    initialOpen: {
      type: Boolean,
      default: true
    }
  },
  
  setup(props) {
    const isOpen = ref(props.initialOpen);
    const toggleAttrs = computed(() => ({
      'aria-expanded': isOpen.value,
      'aria-controls': 'my-collapse-id',
      id: 'my-toggle-id',
    }));
    const collapseAttrs = {
      id: 'my-collapse-id',
      'aria-labelledby': 'my-toggle-id',
      role: 'region',
    };
    function handleCollapse() {
      isOpen.value = !isOpen.value;
    }
    watch(() => props.initialOpen, (newVal) => {
      isOpen.value = newVal;
    });
    const toggle = (open) => {
      if(typeof open === 'boolean') {
        isOpen.value = open;
      }else{
        isOpen.value = !isOpen.value;
      }
    };
    return {
      isOpen,
      toggleAttrs,
      collapseAttrs,
      handleCollapse,
      toggle
    };
  },
};
</script>

<template>
  <article class="AccordionHeaderWrapper">
    <div class="Section">
      <button
        :style="headerStyle"
        :class="[
          'Panel flex between align-center AccordionHeadeButton',
          {
            Active: isOpen,
          },
        ]"
        v-bind="toggleAttrs"
        @click="handleCollapse"
      >
        <slot name="header"></slot>
        <span class="icon" :class="{ rotate: isOpen }">
            <svg id="AccordionHeaderWrapperSvg"  viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="close_btn">
                <g id="Group 145">
                <circle id="Ellipse 22" cx="25" cy="25.9385" r="25" fill="#4D4D4D"/>
                <path id="Polygon 1" d="M24.9994 30.3828L19.0127 22.8696L30.986 22.8696L24.9994 30.3828Z" fill="white"/>
                </g>
                </g>
            </svg>
        </span>
      </button>
      <Collapse
        v-bind="collapseAttrs"
        as="section"
        :when="isOpen"
        class="Collapse"
      >
        <slot name="content"></slot>            
      </Collapse>
    </div>
  </article>
</template>
<style lang="scss" >


:root {
  --AccentColor: #41b883;
  --ForegroundColor: #c5c5c5;
  --ForegroundColorLight: #929292;
  --BackgroundColor: #1a1a1a;
  --BackgroundAlternateColor: #242424;
}




.Panel {
  width: 100%;
  font-size: 1rem;
  color: white;
  text-align: left;
  font-weight: 600;
  min-height: 64px;
  display: flex;
  align-items: center;
}

.AccordionHeaderWrapper .icon {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease;
  transform-origin: center;
    color: white;
}
.AccordionHeaderWrapper .icon.rotate {
  transform: rotate(180deg);
}

#AccordionHeaderWrapperSvg {
  width: 30px;
  height: 30px;
}
#AccordionHeaderWrapperSvg g{
  width: 30px;
  height: 30px;
}
.Section {
  background: var(--BackgroundAlternateColor);
  width: 100%;
  max-height: 100vh;
  display: flex;
  flex-flow: column;
  margin: 0;
}


.Section .AccordionHeadeButton {
  width: 100%;
  padding: 15px 10px;
  border: none;
  background: none;
  cursor: pointer;
}

.Collapse {
  transition: height var(--vc-auto-duration) cubic-bezier(0.37, 0, 0.63, 1);
  display: flex;
  flex-flow: column;
}

.Panel + .Collapse {
  max-height: calc(100vh - 64px);
}

.Collapse p {
  padding: 0 10px 10px;
  margin: 0;
  color: var(--ForegroundColorLight);
  font-size: 1rem;
}



</style>
