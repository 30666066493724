<template>
		<div class="bg-360" :style="{backgroundImage: mainBgImage}">
				<iframe v-if="mainBg && mainBg.src && mainBg.type === 'iframe'" :src="mainBg.src"></iframe>
				<video autoplay loop muted v-else-if="mainBg && mainBg.src && mainBg.type === 'video'" :src="mainBg.src"></video>
				<div ref="image-360" class="image-360"></div>
				
		</div>
</template>

<script>
import {mapGetters} from "vuex"
import {ImagePanorama, Viewer} from "panolens"

export default {
		name: "AppBg",
		data(){
				return {
						showBuildingModel: false
				}
		},
		computed: {
				mainBgImage(){
						if(this.mainBg && this.mainBg.image){
								return `url(${this.mainBg.image})`
						}
						return 'none'
				},
				...mapGetters(['mainBg', 'showHome3DModel'])
		},
		watch: {
				mainBg(newVal){
					console.log("mainBg", newVal)
						if(newVal && newVal.image_360){
								this.init360ImagePlayer(newVal.image_360)
						}
				},
		},
		methods:{
				init360ImagePlayer(src){
						const container = this.$refs['image-360']
						if(container){
								container.classList.add('init')
								const panorama = new ImagePanorama(src)
								const viewer = new Viewer({
										container
								})
								viewer.add(panorama)
						}
				},
		}
}
</script>

<style scoped>

</style>
