<template>
<div class="click-here" :class="{hide}">
		<lord-icon
				icon="tap-once"
				trigger="loop"
				colors="primary:#ffffff"
				style="width: 100px; height: 100px;"
		></lord-icon>
</div>
</template>

<script>
import {appUrl} from "@/http"

export default {
		name: "ClickHere",
		props: {
				timeout:{
						required: false,
						type: Number,
						default: 5
				}
		},
		data(){
				return {
						hide: false
				}
		},
		computed: {
				appUrl(){
						return appUrl
				}
		},
		methods: {
				autoHide(){
						setTimeout(() => {
								this.hide = true
						}, (this.timeout * 1000))
				},
				show(){
						this.hide = false
						this.autoHide()
				}
		},
		mounted() {
				this.autoHide()
		}
}
</script>

<style scoped>

</style>
