import "./style.scss"
import { createApp } from "vue"
import App from "@/App.vue"
import ModelApp from "@/ModelApp.vue";
import store from "@/store"
import router from "@/router"
import i18n from "@/i18n"
import VueAxios from "vue-axios"
import http from "@/http"
import "./lordicons"
import {svgSpritePlugin} from "vue-svg-sprite"
import PerfectScrollbar from "vue3-perfect-scrollbar"
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"
import "gitart-vue-dialog/dist/style.css"
import { GDialog } from "gitart-vue-dialog"
import { plugin as dialogPlugin } from "gitart-vue-dialog"
import frameBus from "@/framebus";
import clickSound from '@/assets/iPhoneClick.mp3'
import VueApexCharts from "vue3-apexcharts";

const appContainer = document.getElementById('floorplan-app')
const audio = new Audio(clickSound);
let appType = 'standard'
if(appContainer.dataset && appContainer.dataset.appType && appContainer.dataset.appType.indexOf('<?') === -1){
    appType = appContainer.dataset.appType
}else if(process.env.VUE_APP_TYPE) {
    appType = process.env.VUE_APP_TYPE
}
// const mobileMediaQuery = window.matchMedia('(max-width: 992px)')

// const app = appType === '3d-model' && !mobileMediaQuery.matches ? createApp(ModelApp) : createApp(App)
const app = appType === '3d-model' ? createApp(ModelApp) : createApp(App)
function isMobile() {
  // return true
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}
if (isMobile()) {
  document.addEventListener('click', function() {      
      audio.play();
  });
}
const clickOutside = {
    beforeMount: (el, binding) => {
      el.clickOutsideEvent = (event) => {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          binding.value();
        }
      };
      document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted: (el) => {
      document.removeEventListener('click', el.clickOutsideEvent);
    },
  };
app.directive('click-outside', clickOutside);
const stopPropagationDirective = {
  beforeMount(el) {
    el._clickEventHandler = (event) => {
      event.stopPropagation();
    };
    el.addEventListener('click', el._clickEventHandler);
  },
  unmounted(el) {
    el.removeEventListener('click', el._clickEventHandler);
  }
};

app.directive('stop-propagation', stopPropagationDirective);
if(appType === '3d-model'){
    store.commit('isModelApp', true)
}
app.config.globalProperties.$frameBus = frameBus
app.use(svgSpritePlugin, {
    url: require('./assets/icons-sprite.svg'),
    class: 'pf-icon',
})
app.use(VueApexCharts);
app.use(VueAxios, http)
app.use(i18n)
app.use(store)
app.use(router)
app.use(PerfectScrollbar)
app.use(dialogPlugin, {})
app.component('GDialog', GDialog)
app.mount(appContainer)

