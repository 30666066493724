// const webpack = require('webpack')

module.exports = {
  runtimeCompiler: true,
  filenameHashing: true,
  publicPath: "/wp-content/plugins/mooz360-fp/model-app",
  outputDir: "../model-app",
  // configureWebpack: {
  //     plugins: [

  //     ],
  //   },

  chainWebpack: (config) => {
    // console.log(config)
    config.module
      .rule("vue")
      .use("vue-loader")
      .tap((options) => ({
        ...options,
        compilerOptions: {
          isCustomElement: (tag) =>
            ["lord-icon", "lottie-player"].includes(tag),
        },
      }));
    config.module
      .rule("glb")
      .test(/.(glb|gltf)$/)
      .use("file-loader")
      .loader("file-loader")
    //   .options({
    //     name: "assets/models/[name].[hash:8].[ext]",
    //   });
  },
};
