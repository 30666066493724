<template>
    <div class="page content-page">
        <transition appear>
            <div class="content-modal" v-if="post">
                <close-btn @click.stop="close"></close-btn>
                <div class="post-header">
                    <div class="post-icon" v-if="post.thumbnail">
                        <img :src="post.thumbnail" :alt="post.post_name">
                    </div>
                    <div class="post-title" v-html="post.post_title"></div>
                </div>
                <div class="post-content" v-html="post.post_content"></div>
            </div>
        </transition>
    </div>
</template>

<script>
import CloseBtn from "@/components/buttons/CloseBtn";
import {mapState} from "vuex";

export default {
    name: "ContentPage",
    components: {CloseBtn},
    computed:{
        post(){
            if(this.pages && this.pages[this.postName]){
                return this.pages[this.postName]
            }
            return null
        },
				currentPageName:{},
				postType(){
						if(this.post && this.post.post_type){
								return this.post.post_type
						}
						return 'content'
				},
        ...mapState(['pages'])
    },
    data(){
        return {
            postName: null
        }
    },
    methods: {
        async loadContent(to){
            const post_name = to.params.postName ? to.params.postName : null
            if(post_name){
                this.postName = post_name
                await this.$store.dispatch('loadPage', {pageName: post_name})
            }
        },
        close(){
            this.$router.push({name: 'home'})
        }
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.loadContent(to)
        })
    },
    beforeRouteUpdate(to){
        this.loadContent(to)
    }
}
</script>

<style scoped lang="scss">

.content-page {
    display: flex;
    justify-content: center;
    padding: 0 2.6875rem;

    &.v-enter-active,
    &.v-leave-active {
        transition: transform 1s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateY(100svh);
    }

    :deep h1 {
        font-size: 4rem;
    }

    :deep h2 {
        font-size: 3rem;
    }

    :deep h3 {
        font-size: 2.5rem;
    }

    :deep h4 {
        font-size: 2rem;
    }

    :deep h5 {
        font-size: 1.8rem;
    }

    :deep {
        line-height: 1.4;
        letter-spacing: .5px;
    }
}

.content-modal {
    position: relative;
    height: 720px;
    max-height: calc(100svh - 160px);
    width: 890px;
    max-width: calc(100% - 2rem);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: stretch;
    flex-flow: column nowrap;

    .close-btn {
        position: absolute;
        top: 1.875rem;
        left: 1.875rem;
    }

    .post-header {
        margin-top: 3.75rem;
        margin-bottom: 1.8rem;
        margin-right: 4.0625rem;
        margin-left: 4.0625rem;
        display: flex;
        align-items: center;
        line-height: 1;

        .post-title {
            font-weight: 700;
            font-size: 1.875rem;
        }

        .post-icon + .post-title {
            margin: 0 0.625rem;
        }
    }

    .post-content {
        margin: 0 4.0625rem 6.25rem;
        padding: 0 0.625rem;
        overflow: auto;
        /* scrollbar design for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            width: 8px;
            background-color: #aaa;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--primary);
        }

        /* scrollbar design for Edge and Firefox */
        & {
            scrollbar-width: thin;
            scrollbar-color: var(--primary) #aaa;
        }
    }
}

.is-mobile,
.is-tablet {
    .content-modal {
        max-height: calc(100svh - 190px);

        .post-content {
            margin: 0;
        }
    }
}

.is-mobile {
    .content-page {
        padding: 0;

        .post-content {
            overflow: visible;
        }
    }
    .content-modal {
        height: auto;
        width: 100vw;
        max-width: none;
        max-height: none;
        border-radius: 0;
        border: none;
        background-color: rgba(0, 0, 0, 0.86);
        background-image: none;
        padding-bottom: 6rem;
    }
}

</style>
