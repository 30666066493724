<template>
  <div class="chart-container">

  
      <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>

  </div>
  </template>
  
  <script>
  
  import { formatNumber } from "@/utils"
  export default {
    name: 'ExpensesChart',
    components: {
      //   apexchart: VueApexCharts,
    },
    props: {
      selectedTerm: {
        type: String,
        default: 'Short term'
      },
      selectedVila: {
        type: Number,
        default: 3
      },
      yearlyIncome: {
        type: Number,
        default: 0
      },

    },
    data() {
      return {
          yearsLease: 29,
          yearlyMaintenance: {
            3: 100000,
            4: 120000
          },          
          managementFeesPercent: {
            shortTerm: 0.3,
            longTerm: 0.2
          },
          chartOptions: {
            chart: {
              width: 200,
              type: 'pie',
            },
            labels: [
              'Authorised fees', 
              'Maintenance fees', 
              // 'Maintenance Fees- lease period', 
              'Management fees', 
              // 'Management fees - rental route-Long term rental Yearly'
            ],
            theme: {
              monochrome: {
                enabled: true
              }
            },
            legend: {
              display: true, // Ensure the legend is displayed
              position: 'bottom', // Position the legend at the bottom
              formatter: function(seriesName, opts) {
                return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex], "&#3647"].join('');
              },
              labels: {
                colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'], // Set the text color of the legend items to white
              },
              markers: {
                width: 10,
                height: 10,
              },
              itemMargin: {
                vertical: 5 // Adjust the vertical margin between legend items
              },
  
            },
            responsive: [{
              // breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
        }
    }, 
    computed: {
      series() {
        return [
            66000, 
            this.yearlyMaintenance[this.selectedVila], 
            // this.yearlyMaintenance * this.yearsLease, 
            this.yearlyIncome * this.managementFeesPercent[this.selectedTerm], 
 
          ]

      },
      totalExpenses() {
        return this.series.reduce((a, b) => a + b, 0)
      },

    },
    watch: {
      totalExpenses: {
        handler: function (val) {
          this.$emit('onExpensesChanged', val)
        },
        deep: true
      }
    },
    mounted() {
      this.$emit('onExpensesChanged', this.totalExpenses)
    },
    methods: {
      formatNumber(num) {
      return formatNumber(num)
    }
    }
  }
  </script>
  
  <style>
  .chart-container {
    width: 27rem;
    direction: ltr;
  }

  .apexcharts-legend-series {
    display: block !important;
  }
  </style>