<template>
    <div class="content-modal">
        <div class="content-modal-header">
          <div v-if="showBackButton">
              <close-btn v-if="isMobile" @click="close"></close-btn>
              <fp-icon-button v-else class="back-btn" symbol="angel-right" @click="close"></fp-icon-button>
          </div>
            <slot name="header" class="flex-grow-1"></slot>
        </div>
        <div class="content-modal-content scrollable-y">
            <slot></slot>
        </div>
				<!-- <div class="content-modal-footer">
						<teleport v-if="isMobile" to="#mobileHeaderBottom">
								<transition :duration="800" appear name="footer">
									<slot v-if="$slots.footer" name="footer"></slot>
								</transition>
						</teleport>
						<template v-else>
								<transition :duration="800" appear name="footer">
										<slot v-if="$slots.footer" name="footer"></slot>
								</transition>
						</template>
				</div> -->
    </div>
</template>

<script>
import FpIconButton from "@/components/buttons/FpIconButton";
import {mapGetters} from "vuex";
import CloseBtn from "@/components/buttons/CloseBtn";
export default {
    name: "ContentModal",
    components: {CloseBtn, FpIconButton},
    props: {
        full: {
            type: Boolean,
            default: false
        },
        showBackButton: {
            type: Boolean,
            default: true
        }
    },
		computed: {
				...mapGetters(['isMobile'])
		},
    methods: {
        close(){
            this.$emit('close')
        }
    }
}
</script>

<style scoped lang="scss">
    .content-modal {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.86) 0%, #000000 100%);

        box-sizing: border-box;

        position: relative;
        flex-grow: 1;
        max-width: 100vw;
        height: 89vh;

        .content-modal-content {
            /*height: 100%;*/
            max-height: 100%;
            flex-grow: 1;
            overflow-x: hidden;
            overflow-y: auto;
            
        }

        .content-modal-footer {
                position: absolute;
                bottom: 35px;
                right: 0;
                left: 0;
                z-index: 10;
        }

        &.full .content-modal-content,
        &.full .content-modal-header {
            padding: 0;
        }

        &.full:not(.tabs) .content-modal-header {

            z-index: 10;
            width: auto;

            button.back-btn {
                width: 42px;
                height: 41px;
                background-color: #000;
                justify-content: center;
                margin: 20px;
                border-radius: 10px;
                border: solid 1px #fff;
            }
        }
        :deep(.iframe) {
            position: absolute !important;
            top: 0;
            width: 100%;
            height: 100svh;
            //height: -webkit-fill-available;
            
            iframe {
                width: 100%;
                height: 100%;
            }
            &.youtubeIframe{
                height: 90svh !important;
                margin-top: 3rem;
    
                }
        
        }



    }

    .is-mobile {
        .content-modal {
            width: 100vw;
            max-width: none;
            border-radius: 0;
            border: none;
            background-color: #000;
            background-image: none;
            position: fixed;
            top: 57px;
            left: 0;
            right: 0;
            height: calc(100svh - 57px);
        
            padding-bottom: 50px;

            :deep(.iframe) {
               position: absolute;
               left: 0;
               width: 100vw;
               height: 100%;
               iframe {
                width: 100%;
                height: 100%;
               }
               &.youtubeIframe{
                height: 90% !important;
                margin-top: 3rem;

                }
            }

        }
        .content-modal.full {
            .content-modal-header {
                position: relative;
            }
        }
        button.back-btn {
            display: none;
        }
    }


</style>
