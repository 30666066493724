<template>
	<div class="scroll-gallery">
			<div v-if="sections" class="sections">
					<div v-if="stickySection" class="section sticky">
							<div class="section-content">
									<div v-html="stickySection"></div>
							</div>
					</div>
					<div v-for="(section, sectionIndex) of sections" :key="sectionIndex" class="section" :class="{'under-sticky': sectionIndex === 0}">
							<div class="image" :style="{backgroundImage: `url('${section.url}')`, backgroundAttachment: imagesEffect}"></div>
					</div>
					<template v-if="onTopStickySection">
							<div v-for="(section, sectionIndex) of onTopStickySection"
									:key="sectionIndex"
									class="section on-top-sticky"
									:style="{backgroundImage: section.image ? `url('${section.image.url}')`:'none'}"
							>
									<div class="section-content">
											<div v-html="stickySection"></div>
									</div>
							</div>
					</template>
			</div>
	</div>
</template>

<script>
export default {
		name: "ScrollGallery",
		props: {
				content: {
						required: true
				}
		},
		computed: {
				sections(){
						if(this.content && this.content.sections){
								return this.content.sections
						}
						return null
				},
				stickySection(){
						if(this.content && this.content.sticky_section){
								return this.content.sticky_section
						}
						return null
				},
				imagesEffect(){
						if(this.content && this.content.images_effect){
								return this.content.images_effect
						}
						return 'fixed'
				},
				onTopStickySection(){
						if(this.content && this.content.on_top_sticky_sections){
								return this.content.on_top_sticky_sections
						}
						return null
				}
		}
}
</script>

<style scoped lang="scss">

.scroll-gallery {
	.sections {
			position: relative;
			.section {
					display: flex;
					flex-flow: row nowrap;
					justify-content: flex-end;
					width: 100%;
					height: 100%;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;

					.section-content {
							height: 100%;
							width: 50%;
							backdrop-filter: blur(6px);
							background-color: rgba(0, 0, 0, .30);
							display: flex;
							flex-flow: column;
							justify-content: center;
							align-items: flex-start;
							padding: 1rem 2rem;
					}

					.image {
							width: 50%;
							height: 100%;
							background-size: cover;
							background-position: center;
							background-attachment: fixed;
					}

					&.sticky {
							position: sticky;
							top: 0;
							z-index: 1;
							justify-content: flex-start;
					}

					&.under-sticky {
							position: absolute;
							top: 0;
					}

					&.on-top-sticky {
							position: relative;
							z-index: 2;

							.section-content {
									width: 100%;

									> div {
											width: 500px;
											max-width: 100%;
											margin: 0 auto;
									}
							}
					}
			}
	}
}

.is-mobile {
		.scroll-gallery {
				.sections {
						.section {
								.image {
										width: 100%;
								}

								&.under-sticky {
										top: 35vh;
								}

								.section-content {
										overflow-y: auto;
										justify-content: start;
								}

								&.sticky {
										.section-content {
												height: 35vh;
												width: 100%;
										}
								}

								&:last-child {
										.section-content {
												padding: 1rem 2rem 5rem;
										}
								}
						}
				}
		}
}

</style>
