<template>
    <div class="tabs-content fade-in">
        <transition appear>
            <div class="entrepreneur-modal" :class="{tabs: tabsSwitchMethod === 'tabs'}">
                <!-- <close-btn @click.stop="close"></close-btn> -->
                <div v-bind:id="!isMobile" class="headerBackgroundText gradientText mb-3">DEVELOPERS</div>
                <div class="entrepreneur-modal-content" v-if="loaded">
                    <div v-if="contentPage">
												<div v-if="contentPage.content" v-html="contentPage.content" class="entrepreneur-content"></div>
                        <div v-if="tabsSwitchMethod === 'select' && tabsMenu.length > 1" class="selectWrapper">
                            <fp-custom-drop-down
																name="כל היזמים"
																:options="tabsMenu"
																@change="setSelectedItem"
														></fp-custom-drop-down>
                        </div>
                        <div ref="entrepreneurTabsContent" class="entrepreneur-tabs-content">
														<template v-for="(tabObj, tabIndex) in tabs" :key="tabIndex">
																<div v-if="tabIndex === tab" class="tab">
                                  <a
                                    v-if="tabObj.image && tabObj.image.url"
                                    :href="tabObj.image.url"
                                    :data-pswp-width="tabObj.image.width"
                                    :data-pswp-height="tabObj.image.height"
                                    target="_blank"
                                  >
                                    <img :src="tabObj.image.url" :alt="tabObj.label">
                                  </a>
                                  <div v-else v-html="tabObj.content"></div>
                                </div>
														</template>
                        </div>
                    </div>
                    <div v-else>
                        <div class="entrepreneur-intro">
                            בחרנו את הכי טוב בשבילך
                        </div>
                        <fp-tabs v-model="tab" :tabs="{entrepreneur: 'יזם', architect: 'אדריכל'}"></fp-tabs>
                        <div class="entrepreneur-tabs-content scrollable-y">
                            <div class="tab" v-if="tab === 'entrepreneur'">
                                <entrepreneur-info :entrepreneurs="entrepreneurs"></entrepreneur-info>
                            </div>
                            <div class="tab" v-else-if="tab === 'architect'">
                                <entrepreneur-info :entrepreneurs="architects"></entrepreneur-info>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <loading-data></loading-data>
                </div>
								<div class="project-buttons" v-if="tabsSwitchMethod === 'tabs' && tabs && tabs.length">
										<span class="active-icon"
													v-for="(tab, bIndex) in tabs"
													:key="bIndex"
													:class="{active: isCurrent(bIndex)}"
													variant="primary-outline"
													@click.stop="buttonClicked(bIndex)"
										>
												<FpAnimatedCircledIcon
														v-if="!isMobile"
														:r="40"
														:width="100"
														:height="100"
														:active="isCurrent(bIndex)"
														:strokeColor="colors.primary"
														baseStrokeColor="transperent">
														<span class="buttonText ">{{tab.label}}</span>
												</FpAnimatedCircledIcon>

												<span v-else class="buttonText menu-button-wrapper" >{{tab.label}}</span>
										</span>
								</div>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import EntrepreneurInfo from "@/components/EntrepreneurInfo";
import FpTabs from "@/components/FpTabs";
import LoadingData from "@/components/LoadingData";
import FpCustomDropDown from "@/components/buttons/FpCustomDropDown";
import FpAnimatedCircledIcon from "@/components/FpAnimatedCircledIcon.vue";
import PhotoSwipeLightbox from "photoswipe/lightbox";
//import panzoom from "panzoom";

export default {
    name: "TabsContentPage",
    components: {
				FpAnimatedCircledIcon,
				LoadingData,
				FpTabs,
				EntrepreneurInfo,
				FpCustomDropDown
		},
    computed:{
        tabsMenu(){
            const tabs = []
            if(this.tabs){
                this.tabs.forEach(tab => {
                    tabs.push(tab.label)
                })
             
            }
            return tabs
           
        },
        tabs(){
            if(this.contentPage && this.contentPage.tabs){
                return this.contentPage.tabs
            }
            return null
        },
				tabsSwitchMethod(){
						if(this.contentPage && this.contentPage.tabs_switch_method){
								return this.contentPage.tabs_switch_method
						}
						return 'select'
				},
        ...mapState(['entrepreneurs', 'architects']),
        ...mapGetters(['contentPage', 'isMobile', 'colors'])
    },
    data(){
        return {
            tab: 0,
            loaded: false,
            lightbox: null,
						//panzoomInstance: null
        }
    },
    methods: {
        close(){
            this.$router.push({name: 'home'})
        },
        async loadPost(postId){
            if(postId){
                await this.$store.dispatch('loadContent', {postId})
            }else{
                await this.$store.dispatch('entrepreneursAndArchitects')
            }
            this.loaded = true
            this.$nextTick().then(() => {
              this.initPs();
            });
        },
				setSelectedItem(item){
						this.tab = this.tabsMenu.indexOf(item)
				},
				buttonClicked(index){
						this.tab = index
            //this.initPanzoom();
				},
				isCurrent(index){
						return this.tab === index
				},
/*
				initPanzoom(){
            if(this.panzoomInstance){
                console.log('panzoomInstance.dispose')
                this.panzoomInstance.dispose();
                this.panzoomInstance = null;
                if(this.$refs.entrepreneurTabsContent){
                  this.$refs.entrepreneurTabsContent.style.transform = 'none';
                }
            }
            this.$nextTick(() => {
              const interval = setInterval(() => {
                  if (this.$refs.entrepreneurTabsContent) {
                      if(this.tabsSwitchMethod === 'tabs' && !this.panzoomInstance){
                          console.log('panzoomInstance.init')
                          clearInterval(interval)
                          this.panzoomInstance = panzoom(this.$refs.entrepreneurTabsContent,{
                              minZoom: 1,
                              maxZoom: 5
                          });
                      }
                  }
              }, 500);
            })
				}
*/
        initPs(){
          if (!this.lightbox) {
            this.lightbox = new PhotoSwipeLightbox({
              gallery: '.entrepreneur-tabs-content',
              children: '.tab a',
              pswpModule: () => import('photoswipe'),
            });
            this.lightbox.init();
          }
        },
        destroyPs(){
          if (this.lightbox) {
            this.lightbox.destroy();
            this.lightbox = null;
          }
        }
    },
    beforeRouteEnter(to, from, next) {
        const postId = to.params.postId
        next(vm => {
            vm.loadPost(postId)
        })
    },
    beforeRouteUpdate(to, from, next) {
        const postId = to.params.postId
        this.loadPost(postId)
        next()
    },
    beforeRouteLeave(){
        this.$store.commit('currentContentId', null)
				/*if(this.panzoomInstance){
						this.panzoomInstance.dispose()
				}*/
    },
    updated() {
        this.tab = 0;
        // this.initPanzoom();
    },
    mounted() {
				// this.initPanzoom();
		},
    unmounted(){
        //this.destroyPs();
    }
}
</script>

<style scoped lang="scss">
.headerBackgroundText {
    left: 7rem;
    top: -2rem;
    
}
.page.entrepreneurs {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
		.tab {

				&.v-enter-active,
				&.v-leave-active {
						transition: opacity .5s ease;
				}

				&.v-enter-from,
				&.v-leave-to {
						opacity: 0;
				}
		}
}
.entrepreneur-modal {
    position: relative;
    height: 720px;
    max-height: calc(100svh - 160px);
    width: 1300px;
    max-width: calc(100% - 2rem);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    padding-top: 2.875rem;
    display: flex;
    flex-direction: column;

		&.tabs {
				height: 100svh;
				max-height: 100svh;
				padding-top: 0;
				.entrepreneur-modal-content {
						margin-top: 0;
						overflow: hidden;
				}

				.headerBackgroundText {
						display: none;
				}

				:deep(.entrepreneur-about) {
						width: auto;
						max-width: none;

						img {
								width: 100%;
						}

						img.aligncenter {
								display: block;
								margin: auto;
						}
				}

				.project-buttons {
						margin-top: auto;
						display: flex;
						justify-content: center;
						margin-bottom: 30px;
				}

        .tab{
          img {
            max-height: calc(100svh - 130px);
            display: block;
            margin: auto;
          }
        }
		}

    &.v-enter-active,
    &.v-leave-active {
        transition: transform 1s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateY(100svh);
    }

    .close-btn {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .entrepreneur-intro {
        font-size: 1.875rem;
        font-weight: 700;
        text-align: center;
    }

		.entrepreneur-content {
				margin-top: 30px;
		}
}
.selectWrapper{
    position: absolute;
    top: 5rem;
    right: 5rem;
    width: 12rem;
    height: 5rem;
}
.is-desktop,
.is-tablet {
    .entrepreneur-modal-content {
        max-height: 100%;
        overflow-y: auto;
        margin-top: 3rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        /* scrollbar design for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            width: 8px;
            background-color: #aaa;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--primary);
        }

        /* scrollbar design for Edge and Firefox */
        & {
            scrollbar-width: thin;
            scrollbar-color: var(--primary) #aaa;
        }
    }
    .style-thumb {
        position: absolute;
        bottom: 14rem;
        left: 50%;
        transform: translateX(-50%);
        width: 20rem;
       
    }
}

.is-mobile {
		.page.entrepreneurs {
				align-items: flex-start;
		}
    .entrepreneur-modal {
        max-width: none;
        overflow-y: auto;
        width: 100vw;
        min-height: calc(100svh - 140px);
        border: none;
        border-radius: 0;
        height: auto;
        background-color: #000;
        padding-bottom: 3rem;

				&.tabs .project-buttons {
						gap: 10px;

						.buttonText {
								border: solid 1px;
								border-radius: 40px;
								padding: 8px 20px;
						}
				}
    }
}

.fp-side-menu-wrapper + .fp-app-wrapper {
		.entrepreneur-modal {
				border-radius: 0;
				border: none;
				background-color: transparent;
				background-image: linear-gradient(to left, rgb(0 0 0 / 80%) 50%,rgb(0 0 0 / 90%) 100%);
				backdrop-filter: blur(5px);
				width: 100%;
				//height: 100svh;

				max-height: none;
				max-width: none;
		}

		.tabs-wrapper {
				display: flex;
				align-items: flex-end;
				justify-content: center;
				column-gap: 2rem;
		}
}

</style>
