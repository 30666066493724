<template>
    <span class="menu-icon" :class="{'has-hover': this.srcHover}">
        <img v-if="src" :src="src" v-bind="$attrs">
        <img v-if="srcHover" :src="srcHover" v-bind="$attrs">
    </span>
</template>

<script>
export default {
    name: "MenuIcon",
    props: {
        src: {
            required: false,
            default: null
        },
        srcHover: {
            required: false,
            default: null
        }
    }
}
</script>

<style scoped>
    .menu-icon img + img {
        display: none;
    }

    .menu-icon.has-hover:hover img,
    .menu-icon.has-hover.active img{
        display: none;
    }

    .menu-icon.has-hover:hover img + img,
    .menu-icon.has-hover.active img + img{
        display: block;
    }
</style>
