export const delay = (ms) => {
    return new Promise(res => setTimeout(res, ms))
}

export const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj))
}
export const equal = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export const different = (obj1, obj2) => {
    return !equal(obj1, obj2)
}

export function formatNumber(number) {
    return number.toLocaleString('en-US');
  }
export default {
    delay,
    clone,
    equal,
    different,
    formatNumber
}
