<template>
    <router-link v-if="to" custom :to="to" v-slot="{ navigate, isExactActive, isActive }">
        <button v-bind="$attrs" @click="navigate" type="button" class="fp-icon-button" :class="{'router-link': isActive, 'router-link-exact-active': isExactActive}">
            <lord-icon v-if="animated"
                    :icon="animated"
                    trigger="hover"
                    colors="primary:#ffffff"
                    :state="state"
                    target=".fp-icon-button"
            ></lord-icon>
            <svg-sprite v-else-if="symbol && symbolType === 'plain'" :symbol="symbol" :size="symbolSize" />
            <gradiant-icon v-else-if="symbol && symbolType === 'gradiant'" :symbol="symbol" :size="symbolSize" />
            <slot></slot>
        </button>
    </router-link>
    <button v-else v-bind="$attrs" class="fp-icon-button">
        <div class="icon"
            :style="{
                backgroundColor: iconBackGroundColor,
                color: iconColor,
                height: height,
                width: height,
            }">
            <FpNewIcon :iconName="symbol" class="" size="1rem"/>
				<!-- <lord-icon
						v-if="animated"
						:icon="animated"
						trigger="hover"
						colors=""
						:state="state"
						target=".fp-icon-button"
						:style="{width: `${symbolSize}px`, height: `${symbolSize}px`}"
				></lord-icon>
                <svg-sprite v-else-if="symbol && symbolType === 'plain'" :symbol="symbol" :size="symbolSize" />
                <gradiant-icon v-else-if="symbol && symbolType === 'gradiant'" :symbol="symbol" :size="symbolSize" /> -->
        </div>
        <div class="text">
        <slot></slot>
        </div>
    </button>
</template>

<script>
import GradiantIcon from "@/components/GradiantIcon";
import FpNewIcon from '@/components/buttons/FpNewIcon.vue';
export default {
    name: "FpIconButton",
    components: {GradiantIcon, FpNewIcon},
    props: {
        symbol: {
            type: String
        },
        animated: {
                type: String
        },
        state: {
                type: String,
                default: 'hover'
        },
        height: {
                type: String,
                default: '2.1rem'
        },
        symbolSize: {
            default: '20'
        },
        symbolType: {
            type: String,
            default: 'plain'
        },
        iconBackGroundColor: {
            type: String,
            default: ''
        },
        iconColor: {
            type: String,
            default: 'white'
        },
        to: {
            default: null
        }
    }
}
</script>

<style scoped lang="scss">
    button.fp-icon-button {
        position: relative;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        min-height: 0;
        font-size: 0.875rem;
        border-radius: 6.25rem;
        height: 2.125rem;
        gap: 10px;

				lord-icon {
						flex-shrink: 0;
				}

				&[disabled] {
						opacity: .5;
						cursor: not-allowed;
				}
        .icon{
            
            position: absolute;
            right: 0;
			font-size: 0.875rem;
			border-radius: 6.25rem;

            display: flex;
            align-items: center;
            justify-content: center;
            transition: .3s ease;
        }
        .text {

        }
        &:hover .icon {
            right: calc(100% - 2.125rem);
        }
        &:hover  {
            background: var(--primary) !important;
        }
        /*svg {
            fill: #fff;
            stroke: #fff;
        }

        &:focus svg,&:hover svg {
            fill: #00C3B8;
            stroke: #00C3B8;
        }*/
    }

		.ltr button.fp-icon-button{
				svg,img {
						transform: scaleX(-1);
				}
		}
</style>
