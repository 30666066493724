<template>
    <div class="page main-page" :class="[fadeIn ? 'fade-in' : '']">
        <!--<div class="logo">
            <main-logo></main-logo>
        </div>-->
        <app-bg></app-bg>

        <weather-widget v-if="isDesktop"></weather-widget>
        <div  v-if="isDesktop" class="timeBasedMessageWrapper">
			<TimeBasedMessage />
		</div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import WeatherWidget from "@/components/WeatherWidget";
import TimeBasedMessage from '@/components/TimeBasedMessage.vue';
import AppBg from "@/components/AppBg.vue";
export default {
    name: "MainPage",
    components: { WeatherWidget, TimeBasedMessage, AppBg },
    data() {
        return {
            fadeddIn: false
        }
    },
    computed: {
        fadeIn() {
            return this.fadeddIn
        },
        ...mapState(['mainInit', 'currentProjectId']),
        ...mapGetters(['isDesktop'])
    },
    watch: {
        currentProjectId(newValue) {
            if (newValue && this.isDesktop) {
                this.$store.commit('mainInit')
                //this.$router.push({name: 'projects', params: {projectId: this.$store.getters.currentProjectId}})
            }
        }
    },
    mounted() {
        if (this.mainInit) {
            this.fadeddIn = true
        } else {
            if (!this.fadeddIn) {
                this.fadeddIn = true
            }
        }
    }
}
</script>

<style scoped lang="scss">
.timeBasedMessageWrapper {
	display: block;
	position: absolute;
	right: 3.5rem;
	top: 1.4rem;
    z-index: 6;
	svg {
		width: 1rem;
	}
}

.ltr {
    .timeBasedMessageWrapper {
        left: 3.5rem;
        right: auto;
    }
}
</style>
