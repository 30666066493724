<template>
  <div>
    <!-- <div ref="threeContainer"></div> -->
    <div ref="mapContainer" class="map"></div>
    <div class="settings" v-if="isAdmin">
      <div class="flex justify-between">
        <span>Height</span>
        <input placeholder="ModelHeight" type="number" 
            v-model="modelHeight"
            @change="e => handleModelHeight(+e.target.value)"
            />
      </div>
      <div class="flex justify-between">
        <span>Lat</span>
        <input placeholder="Lat" type="number" 
            v-model="lat"
            @change="e => handleModelLat(+e.target.value)"
            />
      </div>
      <div class="flex justify-between">
        <span>Lon</span>
        <input placeholder="Lon" type="number" 
            v-model="lon"
            @change="e => handleModelLon(+e.target.value)"
            />
      </div>
      <div class="flex justify-between">
        <span>Scale</span>
        <input placeholder="scale" type="number" 
            v-model="scale"
            @change="e => handleScale(+e.target.value)"
            />
      </div>
      <div class="flex justify-between">
        <span>Rotate</span>
        <input placeholder="scale" type="number" 
            v-model="rotationY"
            @change="e => handleRotate(+e.target.value)"
            />
      </div>
      <button @click="saveMapBoxSettings">Save</button>
      <button @click="showMap">Show map</button>
      <button @click="hideMap">Hide map</button>
      <button @click="saveView">Save view</button>
      <button @click="resetView">Reset view</button>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
// import {mapGetters} from "vuex";
import mapboxgl from 'mapbox-gl';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
// import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';

import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
// import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";

export default {
  data() {
    return {
      modelHeight: 0,
      lat: 0,
      lon: 0,
      scale: 1,
      model: null,
      rotationY: 0,
    };
  },
  props: {
    mapboxToken: {
      type: String,
      required: false,
      default: "pk.eyJ1IjoieWFtaXRoYWZha290IiwiYSI6ImNsaWhsMnBpczBxaTkzZHFyZDdpaWp4c3MifQ.N2Batt6nrfHv-rjUo_EYAQ"
    },
    modelUrl: {
      type: String,
      required: false,
    },
    center: {
      type: Object,
      required: false,
      default: () => {return {}}

    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    projectDefaultView: {
      type: Object,
      required: false,
      default: () => {return {}}
    }
  },
  mounted() {
    console.log("mounted", this.projectDefaultView)
    this.modelHeight = this.projectDefaultView.modelHeight || 0
    this.lon = this.projectDefaultView.lon || 0
    this.lat = this.projectDefaultView.lat || 0
    this.rotationY = this.projectDefaultView.rotationY || 0
    this.scale = this.projectDefaultView.mapboxModelScale || 1
    const modelUrl = this.modelUrl ? this.modelUrl : this.$store.getters.contentPage.area_model
    const projectDefaultView = this.projectDefaultView
    window.addEventListener('resize', this.onWindowResize);
    mapboxgl.accessToken = this.mapboxToken;
    if (!mapboxgl.getRTLTextPluginStatus() || mapboxgl.getRTLTextPluginStatus() !== 'loaded') {
      mapboxgl.setRTLTextPlugin(
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
        null,
        true // Lazy load the plugin
      );

    }
    let mapCenter = [this.center.lng, this.center.lat]
    this.camera = new THREE.Camera();
    const camera = this.camera
    this.map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
      style: 'mapbox://styles/yamithafakot/cliijhdzu004r01qpb352586a',
      zoom: 17,
      center: mapCenter,
      pitch: 60,
      lightingEnabled: false,
      // scrollZoom: false,
      antialias: true // create the gl context with MSAA antialiasing, so custom layers are antialiased
    });
    const map = this.map
    // map.dragPan.disable();
    // map.touchZoomRotate.enable();

    // map.addControl(new mapboxgl.NavigationControl(), 'top-left');

    // Add custom behavior for left mouse button to rotate the map
    // let isDragging = false;
    // let startMousePosition;
    // let startBearing;
    // let startPitch;

    // map.on('mousedown', function (e) {
    //   if (e.originalEvent.button === 0) { // Left mouse button
    //     isDragging = true;

    //     startMousePosition = [e.originalEvent.clientX, e.originalEvent.clientY];
    //     startBearing = map.getBearing();
    //     startPitch = map.getPitch();

    //     document.addEventListener('mousemove', onMouseMove);
    //     document.addEventListener('mouseup', onMouseUp);
    //   }
    // });

    // function onMouseMove(e) {
    //   if (!isDragging) return;

    //   const currentMousePosition = [e.clientX, e.clientY];

    //   // Differences in mouse positions
    //   const dx = startMousePosition[0] - currentMousePosition[0];
    //   const dy = startMousePosition[1] - currentMousePosition[1];

    //   // Set the map's bearing (rotation around the y-axis) and pitch (rotation around the x-axis)
    //   map.setBearing(startBearing - dx * 0.5);  // Adjust the multiplier for sensitivity
    //   map.setPitch(Math.min(90, startPitch + dy * 0.5));  // Limit the pitch to 60 degrees for a better experience
    // }

    // function onMouseUp(e) {
    //   if (e.button === 0) { // Left mouse button
    //     isDragging = false;

    //     document.removeEventListener('mousemove', onMouseMove);
    //     document.removeEventListener('mouseup', onMouseUp);
    //   }
    // }
    // map.on('touchstart', function (e) {
    //   if (e.originalEvent.touches.length === 1) { // Single touch
    //     isDragging = true;

    //     const touch = e.originalEvent.touches[0];
    //     startMousePosition = [touch.clientX, touch.clientY];
    //     startBearing = map.getBearing();
    //     startPitch = map.getPitch();

    //     document.addEventListener('touchmove', onTouchMove);
    //     document.addEventListener('touchend', onTouchEnd);
    //   }
    // });

    // function onTouchMove(e) {
    //   if (!isDragging) return;

    //   const touch = e.touches[0];
    //   const currentMousePosition = [touch.clientX, touch.clientY];

    //   const dx = startMousePosition[0] - currentMousePosition[0];
    //   const dy = startMousePosition[1] - currentMousePosition[1];

    //   map.setBearing(startBearing - dx * 0.5);
    //   map.setPitch(Math.min(90, startPitch + dy * 0.5));
    // }

    // function onTouchEnd(e) {
    //   if (e.touches.length === 0 && isDragging) {
    //     isDragging = false;

    //     document.removeEventListener('touchmove', onTouchMove);
    //     document.removeEventListener('touchend', onTouchEnd);
    //   }
    // }
    // parameters to ensure the model is georeferenced correctly on the map
    let modelOrigin = mapCenter;

    // const modelAltitude = 20;
    const modelRotate = [Math.PI / 2, 0, 0];

    const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
      modelOrigin,
      0
    );

    // transformation parameters to position, rotate and scale the 3D model onto the map
    const modelTransform = {
      translateX: modelAsMercatorCoordinate.x,
      translateY: modelAsMercatorCoordinate.y,
      translateZ: modelAsMercatorCoordinate.z,
      rotateX: modelRotate[0],
      rotateY: modelRotate[1],
      rotateZ: modelRotate[2],
      scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
    };


    const addModelToThis = this.addModelToThis.bind(this)
    // configuration of the custom layer for a 3D model per the CustomLayerInterface
    const customLayer = {
      id: '3d-model',
      type: 'custom',
      renderingMode: '3d',
      onAdd: function (map, gl) {
        
        this.scene = new THREE.Scene();

        const ambientLight = new THREE.AmbientLight(0xffffff, 1.0);  // White color, full intensity
        this.scene.add(ambientLight);
        // use the three.js GLTF loader to add the 3D model to the three.js scene
        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/'); // Set path to DRACO
        loader.setDRACOLoader(dracoLoader);
        loader.load(
          modelUrl,
          (gltf) => {
            this.model = gltf.scene

            gltf.scene.traverse(function (node) {
              if (node.name.includes("bbox")) {
        
                node.visible = false
              }

            });
            addModelToThis(gltf.scene)
            this.scene.add(gltf.scene);

            if (projectDefaultView){
              if (projectDefaultView.lat){
                this.model.position.x = projectDefaultView.lat
              }
              if (projectDefaultView.lon){
                this.model.position.z = projectDefaultView.lon
              }
              if (projectDefaultView.rotationY){

                this.model.rotation.y = projectDefaultView.rotationY

              }
              if (projectDefaultView.modelHeight){
                this.model.position.y = projectDefaultView.modelHeight

              }
              
            }


          }
        );

        this.map = map;
        this.renderer = new THREE.WebGLRenderer({
          canvas: map.getCanvas(),
          context: gl,
          antialias: true
        });
        this.renderer.autoClear = false;

      },
      render: function (gl, matrix) {
        const rotationX = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(1, 0, 0),
          modelTransform.rotateX
        );
        const rotationY = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(0, 1, 0),
          modelTransform.rotateY
        );
        const rotationZ = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(0, 0, 1),
          modelTransform.rotateZ
        );

        const m = new THREE.Matrix4().fromArray(matrix);
        const l = new THREE.Matrix4()
          .makeTranslation(
            modelTransform.translateX,
            modelTransform.translateY,
            modelTransform.translateZ
          )
          .scale(
            new THREE.Vector3(
              modelTransform.scale,
              -modelTransform.scale,
              modelTransform.scale
            )
          )
          .multiply(rotationX)
          .multiply(rotationY)
          .multiply(rotationZ);

        camera.projectionMatrix = m.multiply(l);
        this.renderer.resetState();
        this.renderer.render(this.scene, camera);
        this.map.triggerRepaint();
      }
    };

    map.on('style.load', () => {
      map.addLayer(customLayer);
      this.loadSavedView()
      const myLayers = ['transit-label']
      // var allLayers = map.getStyle().layers;
      // allLayers.forEach(function(layer) {
      //     console.log(layer.id);
      //     // map.setLayoutProperty(layer, 'visibility', 'none');

      // });
      myLayers.forEach(function (layer) {

        map.setLayoutProperty(layer, 'visibility', 'none');

      });
    });


  },
  computed: {

    project() {
      return this.$store.getters.currentProject
    }

  },
  beforeUnmount() {
    // Clean up Three.js resources and listeners
    // this.renderer.dispose();
    window.removeEventListener('resize', this.onWindowResize);
    // console.log("beforeUnmount", this.map)
    // Clean up Mapbox resources
    this.map.remove();
  },

  methods: {
    addModelToThis(model){
      this.model = model
    },
    resetView() {
      // console.log(this.projectDefaultView)
      let currentView = {
        center: [this.center.lng, this.center.lat],
        zoom: this.map.getZoom(),
        bearing: this.map.getBearing(),
        pitch: this.map.getPitch()
      };
      const newSettings =  {
        ...this.projectDefaultView, 
        mapBoxDefaultView: currentView,
      }
      console.log(newSettings)
      this.$store.dispatch('saveProjectSettings', newSettings)
    },
    saveView() {

      let currentView = {
        center: this.map.getCenter().toArray(),
        zoom: this.map.getZoom(),
        bearing: this.map.getBearing(),
        pitch: this.map.getPitch()
      };
      const newSettings =  {
        ...this.projectDefaultView, 
        mapBoxDefaultView: currentView,
      }
      // console.log(newSettings)
      this.$store.dispatch('saveProjectSettings', newSettings)
    },
    loadSavedView() {
      let savedView = this.projectDefaultView.mapBoxDefaultView;

      this.map.jumpTo({
        center: savedView.center,
        zoom: savedView.zoom,
        bearing: savedView.bearing,
        pitch: savedView.pitch
      });
    },
    hideMap() {
      // this.$refs.mapContainer.style.display = 'none';
      this.map.getStyle().layers.forEach(layer => {
        this.map.setLayoutProperty(layer.id, 'visibility', 'none');
      });
    },
    showMap() {
      this.map.getStyle().layers.forEach(layer => {
        this.map.setLayoutProperty(layer.id, 'visibility', 'visible');
      });
    },
    onWindowResize() {
      // Update camera aspect ratio and renderer size
      if (this.camera)
        this.camera.aspect = window.innerWidth / window.innerHeight;
      // this.camera.updateProjectionMatrix();
      if (this.renderer)
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    handleModelHeight(height) {
      this.model.position.y = height

    },
    handleModelLat(offset) {
      this.lat = offset
      this.model.position.x = offset 

    },
    handleScale(scale) {
      // this.scale = scale
      this.model.scale.set(scale, scale, scale)

    },
    handleRotate(rotationy) {

      this.model.rotation.y = rotationy / 100

    },
    handleModelLon(offset) {

      this.lon = offset
      this.model.position.z = offset

    },

    // stringToCoords(coordsStr){
    //   // console.log("coordsStr", coordsStr)
    //     if(coordsStr){
    //         const cords = coordsStr.split(",")
    //         if(cords[0] && cords[1]){
    //             return {lat: parseFloat(cords[0]), lng: parseFloat(cords[1])}
    //         }
    //     }
    //     return null
    // },
    animate() {
      // console.log("rendering")
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
    async saveMapBoxSettings() {
			console.log(this.projectDefaultView)
      const newSettings =  {
        ...this.projectDefaultView, 
        modelHeight: this.modelHeight,
        mapboxModelScale: this.model.scale.x,
        lat: this.lat,
        lon: this.lon,
        rotationY: this.rotationY,

      }
      console.log(newSettings)
			await this.$store.dispatch('saveProjectSettings', newSettings)

		},


  }
};
</script>

<style scoped lang="scss">
/* Style for the Mapbox map */
.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}
.settings {
  position: absolute;
  display: block;
  z-index: 20;
  top: 0;
  left: 0;
  background-color: black;
  padding: 1rem;
  direction: ltr;
  width: 10rem;
  div{
    width: 100%;
  }
  input{
    width: 3rem;
  }
  button{
    color: white;
    display: block;
    margin: auto;
    margin-top: 1rem;
    border: 1px solid white;
    padding: .5rem;

  }
}

.ltr {
  .settings {
    right: 0;
    left: auto;

  }
}

</style>
