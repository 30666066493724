<template>
    <div class="entrepreneurs fade-in">
        <transition appear>
            <div class="entrepreneur-modal">
                <div class="headerBackgroundText gradientText mb-3">{{header}}</div>
                <div class="selectWrapper entrepreneurs-in-project" v-if="tabsMenu.length > 1">
                    <fp-custom-drop-down
                        :name="header === 'ARCHITECTS' ? $t('כל האדריכלים'): $t('כל היזמים')"
                        :options="tabsMenu" 
												@change="setSelectedItem"
										></fp-custom-drop-down>
                </div>
                <div class="entrepreneur-modal-content">
                    <div v-if="selectedItem">
                        <div v-if="showPostTitle" class="entrepreneur-intro mb-3">
                            {{selectedItem.post.post_title}}
                        </div>
                        <transition appear>
                            <div class="entrepreneur-about mb-1" v-html="selectedItem.post.post_content"></div>
                        </transition>
                    </div>
                    <div v-else>
                        <div class="entrepreneur-intro">
                            בחרנו את הכי טוב בשבילך
                        </div>
                        <!-- <fp-tabs v-model="tab" :tabs="{entrepreneur: 'יזם', architect: 'אדריכל'}"></fp-tabs> -->
                        <div class="entrepreneur-tabs-content scrollable-y">
                            <div class="tab" v-if="tab === 'entrepreneur'">
                                <entrepreneur-info :entrepreneurs="entrepreneurs"></entrepreneur-info>
                            </div>
                            <div class="tab" v-else-if="tab === 'architect'">
                                <entrepreneur-info :entrepreneurs="architects"></entrepreneur-info>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-thumb" v-if="header === 'ARCHITECTS'" >
                     <ImageGallery v-if="images.length" :images="images"/>
                     <img v-else :src="selectedItem.thumbnail" :alt="selectedItem.post_title">
                </div>
                <div class="main-thumb" v-else >
                     <ImageGallery v-if="images.length" :images="images" />
                     <img v-else :src="selectedItem.thumbnail" :alt="selectedItem.post_title" >
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import EntrepreneurInfo from "@/components/EntrepreneurInfo";
import ImageGallery from "@/components/ImageGallery";
import FpCustomDropDown from "@/components/buttons/FpCustomDropDown";


export default {
    name: "EntrepreneursComp",
    components: {
				EntrepreneurInfo,
				FpCustomDropDown,
				ImageGallery
    },
    computed: {
        tabsMenu(){
            const tabs = []
            console.log("this.items",this.items )
            if(this.items){
                this.items.forEach(item => {
                    tabs.push(item.post.post_title)
                })
                // console.log("tabs", tabs)
                return tabs
            }
            return {entrepreneur: 'יזם', architect: 'אדריכל'}
        },
        images(){
            // console.log(this.selectedItem)
            return this.selectedItem.images_slider ? this.selectedItem.images_slider.map(img => img.url) : []
        },
        showPostTitle(){
          if(this.selectedItem && this.selectedItem.post && typeof(this.selectedItem.post.show_title) !== 'undefined'){
              return this.selectedItem.post.show_title
          }
          return true
        }

    },
    props: {
        items: {
            type: Array,
            default: function() {
                return []
            },
            required: true
        },
        header: {
            type :String,
            default: "DEVELOPERS"
        }
    },
    data(){
        return {
            selectedItem: this.items[0],
        }
    },
    watch:{
        items(newVal){
            console.log("items", newVal)
            console.log("this.locale", this.locale)
            this.selectedItem = newVal[0]
        }
    },
    methods: {
        close(){
            this.$router.push({name: 'home'})
        },
        setSelectedItem(item){
						this.selectedItem = this.items.find(it => it.post.post_title === item)
        }
    },

}
</script>

<style scoped lang="scss">
.headerBackgroundText {
    left: 7rem;
    top: -4rem;

    
}
.entrepreneurs {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    height: 100%;
		.tab {

				&.v-enter-active,
				&.v-leave-active {
						transition: opacity .5s ease;
				}

				&.v-enter-from,
				&.v-leave-to {
						opacity: 0;
				}
		}
}
.entrepreneur-modal-content{
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    z-index: 7;
}
.entrepreneur-modal {
    position: relative;
    height: 720px;
    max-height: calc(100svh - 160px);
    width: 1300px;
    max-width: calc(100% - 2rem);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    padding-top: 2.875rem;
    padding-right: 5rem;
    display: flex;
    flex-direction: column;
    text-align: right;
    z-index: 6;
    &.v-enter-active,
    &.v-leave-active {
        transition: opacity .8s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        opacity: 0;
    }

    .close-btn {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .entrepreneur-intro {
        font-size: 1.875rem;
        font-weight: 700;
      
    }
    .entrepreneur-about {
        width:  23rem;
        &.v-enter-active,
        &.v-leave-active {
            transition: opacity .8s ease;
        }
    
        &.v-enter-from,
        &.v-leave-to {
            opacity: 0;
        }
    }

}
.main-thumb {
    position: absolute;
    bottom: 7.5rem;
    right: 50%;
    z-index: 2;
    width: 45vw;
    height: 29rem;
    object-fit: cover;

    &.rounded{
        width: 32rem;
        left: 20%;
        >img {
            border-radius: 50%;
            object-fit: cover;
            height: 100%;
        }
    }
}
.style-thumb {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    width: 15rem;
    height: 15rem;
    >img {
        object-fit: cover;
        height: 100%;
    }
   
}
.selectWrapper{
    width: 12rem;
    height: 5rem;
    z-index: 15;
}
.is-desktop,
.is-tablet {
    .entrepreneur-modal-content {
        max-height: 100%;
        z-index: 3;
        /* scrollbar design for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            width: 8px;
            background-color: #aaa;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--primary);
        }

        /* scrollbar design for Edge and Firefox */
        & {
            scrollbar-width: thin;
            scrollbar-color: var(--primary) #aaa;
        }
    }




}

.is-mobile {
    .page.entrepreneurs {
            align-items: flex-start;
    }
    .entrepreneur-about {
        max-height: 40svh;
        width: 100%;
        padding-top: 45px;
        overflow-y: auto;
        
    }
    .entrepreneur-intro {
        max-width: 60%;
        line-height: 1;
        margin-bottom: 1rem;
    }
    .headerBackgroundText {
        display: none;
    }
    .main-thumb {
        bottom: auto;
        right: auto;
        width: auto;
        height: auto;
        position: relative;

        &.rounded{
            width: 70vw !important;
            height: 70vw !important;

            right: 20%;
            >img {
                border-radius: 50%;
                object-fit: cover;
                height: 100%;
            }
        }
    }
    .style-thumb {
        position: absolute;
        bottom: 0;
        right: 1rem;
        transform: translateX(0);
        width: 50vw;
        height: 50vw;
       
    }
    .selectWrapper{
        width: 8rem;
        height: 3rem;
        position: absolute;
        left: 1rem;
        top: 6rem;
        
        :deep(.dropdown-toggle){
            font-size: .8rem !important;
        }
     
    }
    .entrepreneur-modal {
        max-width: none;
        width: 100vw;
        border: none;
        border-radius: 0;     
        background-color: #000;
        padding-bottom: 3rem;
        padding-top: 6rem;
        padding-right: 1rem;
        padding-left: 1rem !important;
    }
}

.fp-side-menu-wrapper + .fp-app-wrapper {
		.entrepreneur-modal {
				border-radius: 0;
				border: none;
				background-color: transparent;
				background-image: linear-gradient(to left, rgb(0 0 0 / 80%) 50%,rgb(0 0 0 / 90%) 100%);
				backdrop-filter: blur(5px);
				width: 100%;

				max-width: none;
		}

		.entrepreneur-tabs-content {
				margin-top: 30px;
		}

		.tabs-wrapper {
				display: flex;
				align-items: flex-end;
				justify-content: center;
				column-gap: 2rem;
		}
}

.ltr {
    .entrepreneur-modal {
        text-align: left;
        padding-right: 0;
        padding-left: 5rem;
    }
    .entrepreneur-intro {
        margin-top: 2rem;
        
    }
    .main-thumb {

        left: 50%;
        right: auto;   
       
    }
    .selectWrapper{

        right: 0;
        left: auto;

     
    }
}

</style>
