<template>
		<div v-if="floor" class="floor-box">
				<div
						class="floor-thumbnail"
						:class="{clickable: floorIsClickable}"
						v-if="floor.thumbnail && showThumbnail"
						tabindex="1"
						role="button"
						@click="goToFloorPage"
				>
						<img :src="floor.thumbnail" :alt="floor.name">
				</div>
				<div class="floor-details-wrapper">
						<div
								v-if="showTitle"
								class="floor-title"
								:class="{clickable: floorIsClickable}"
								tabindex="1"
								role="button"
								@click="goToFloorPage"
						>
								<span>{{floor.title}}</span>
						</div>
						<floor-features-list
								layout="dashed"
								:floor="floor"
								:show-icons="false"
						></floor-features-list>
				</div>
		</div>
</template>

<script>
import {mapGetters} from "vuex";
import FloorFeaturesList from "@/components/FloorFeaturesList.vue";

export default {
		name: "FloorBox",
		components: {FloorFeaturesList},
		props: {
				floor: {
						required: false,
						default: null
				},
				showTitle: {
						required: false,
						type: Boolean,
						default: true
				},
				navigateOnClick: {
						required: false,
						type: Boolean,
						default: false
				},
				showThumbnail: {
						required: false,
						type: Boolean,
						default: true
				}
		},
		computed: {
				floorIsClickable(){
						return this.floorPageEnabled
				},
				...mapGetters(['filterOptions', 'floorPageEnabled', 'currentProjectId'])
		},
		methods: {
				goToFloorPage(){
						if(this.floorIsClickable){
								if(this.navigateOnClick){
										this.$router.push({name: 'floor', params: {projectId: this.currentProjectId, apartmentId: this.floor.id}})
								}else{
										this.$emit('floor-clicked', this.floor)
								}
						}
				}
		}
}
</script>

<style scoped lang="scss">
.floor-box {
		width: 210px;
		position: relative;
		border: solid 1px #8B8985;
		border-radius: 3px;

		:deep(ul.apt-features-list) {
			padding: 0 0.812rem;
		}
}

.floor-box-footer {
		padding: 0 10px 10px;
}

.floor-box-footer hr {
		border-color: #5E5E5C;
}


.floor-thumbnail {
		border-bottom: solid 1px #8B8985;
		position: relative;

		:deep(span.fav-button-wrapper) {
				position: absolute;
				left: 5px;
				top: 5px;
				background-color: transparent;
				background-image: linear-gradient(to left, rgb(0 0 0 / 80%) 50%,rgb(0 0 0 / 90%) 100%);
				backdrop-filter: blur(5px);
				width: 30px;
				height: 30px;
				padding: 5px 0;
				border-radius: 30px;
		}

		&.clickable {
				cursor: pointer;
		}

		:deep(img) {
				max-width: 100%;
				height: 125px;
				object-fit: cover;
				object-position: center;
				display: block;
		}
}

.floor-details-wrapper {
		padding: 0.5rem 0.812rem;
}

.floor-title {
		padding: 0 0.812rem;
		font-size: 1rem;
		line-height: 1.2;
}

.floor-title {
		font-size: 1.125rem;
		font-weight: 700;

		&.clickable {
				cursor: pointer;
		}
}

.is-mobile {
		.floor-box {
				display: flex;
				flex-flow: row nowrap;
				width: auto;
		}

		.floor-thumbnail {
				border-bottom: none;

				:deep(img){
						max-width: none;
						width: 145px;
						height: 100%;
				}
		}
}
</style>
