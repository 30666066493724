<template>
    <nav ref="menuBarNav" class="menu-bar-wrapper" aria-label="Main">
        <div id="menuApartmentButtons" :style="{ bottom: footerPadding }"></div>
        <div class="menu-bar">
            <menu-bar-right></menu-bar-right>
            <menu-bar-middle></menu-bar-middle>
            <menu-bar-left v-if="!isMobile"></menu-bar-left>
        </div>
    </nav>
</template>

<script>
import MenuBarRight from "@/components/MenuBarRight";
import MenuBarLeft from "@/components/MenuBarLeft";
import { mapGetters } from "vuex";
import anime from "animejs";
import MenuBarMiddle from "@/components/MenuBarMiddle.vue";

export default {
    name: "MenuBar",
    components: { MenuBarMiddle, MenuBarLeft, MenuBarRight },
    computed: {
        footerPadding() {
            if (this.isMobile) {
                if (this.hasWhatsappButton || this.showFloatingContactButton) {
                    return "calc(100% + " + (this.mobileButtonsHeight + 70) + "px)"
                }
                return "calc(100% + " + (this.mobileButtonsHeight + 20) + "px)"
            }
            return "60px"
        },
        ...mapGetters([
            'menu',
            'isMobile',
            'mobileMenuOpen',
            'projectsPaneOpen',
            'currentProjectId',
            'hasWhatsappButton',
            'showFloatingContactButton',
            'mobileButtonsHeight'
        ])
    },
    watch: {
        isMobile() {
            this.updateHeight()
        }
    },
    methods: {
        closeMobileMenu() {
            if (this.isMobile && this.mobileMenuOpen) {
                this.$store.commit('mobileMenuOpen', false)
            }
            if (this.projectsPaneOpen) {
                this.$store.commit('projectsPaneOpen', false)
            }
        },
        updateHeight() {
            this.$nextTick(() => {
                if (this.$refs.menuBarNav) {
                    this.$store.commit('bottomBarHeight', this.$refs.menuBarNav.clientHeight)
                }
            })
        }
    },
    mounted() {
        anime({
            targets: this.$refs.menuBarNav,
            bottom: '0px',
            opacity: 1
        })

        this.updateHeight()
    }
}
</script>

<style scoped lang="scss">
.menu-bar-wrapper {

    .menu-bar {
        box-sizing: border-box;
        align-items: center;
        display: flex;
        min-height: 83px;
    }

    :deep(ul.middle-menu) {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        /* Hide scrollbars */
        scrollbar-width: none; /* Firefox */


        .menu-item {
            
        }
    }

    :deep(.btn-360) {
        background-image: url("~@/assets/gif/360-degrees.gif");
        width: 30px;
        height: 30px;
        background-position: top left;
        background-repeat: no-repeat;
        background-size: auto 100%;
        display: block;
    }



    @media(max-width: 767px) {
        :deep(ul:not(.middle-menu)) {
            display: none;
        }
    }
}

.is-mobile {
    .menu-bar-wrapper {
        .menu-bar {
            min-height: 60px;
        }

        :deep(ul) {

            /*TODO add separating line */
            &.middle-menu {
                justify-content: space-evenly;
                align-items: stretch;
                background: linear-gradient(180deg, rgba(28, 28, 28, 0.00) 0%, rgba(28, 28, 28, 0.90) 80.21%);
                li {
                    border: none;
                    padding: 1px 0;
                    position: relative;
                    display: flex;
                    align-items: center;

                    &.featured {
                        transform: scale(1.35) translateY(-8px);
                        background-color: rgb(0 0 0 / 0%);
                        box-shadow: 0 1px 4px 0 rgb(255 255 255 / 29%);
                        border-radius: 13px;
                        padding: 0 5px 4px;
                        backdrop-filter: blur(3px);
                        flex-shrink: 0;
                    }

                    a {
                        display: flex;
                        flex-flow: column;
                        text-align: center;
                        align-items: center;
                        font-size: .9rem;
                        gap: 1px;
                        overflow: hidden;
                        line-height: 1.2;
                    }

                    &.divider {
                        flex-shrink: 0;
                        flex-grow: 0;
                        width: 1px;
                        background-color: #fff;
                        display: block;
                    }

                    &.divider:last-child {
                        display: none;
                    }
                }

                li.btn-360::after {
                    display: none;
                }

                li:first-child, li:last-child {
                    scale: 0.8;
                }
            }

            li {
                a {
                    font-size: 1.2rem;
                }
            }
        }

        #menuApartmentButtons {
            position: absolute;
            bottom: 10px;
            right: 0;
            left: 0;
            width: 100vw;

            :deep(.apt-buttons) {
                display: flex;
                overflow-x: auto;
                overflow-y: hidden;
                gap: 15px;
                padding: 5px 0;
                margin-right: 10px;
                margin-left: 10px;

                button {
                    min-width: 0;
                    width: 20%;
                    flex-shrink: 0;
                    margin-bottom: .5rem;
                    overflow: hidden;

                    &:first-child {
                        margin-right: auto;
                    }

                    &:last-child {
                        margin-left: auto;
                    }
                }
            }

        }

    }
}

.is-desktop,
.is-tablet {
    .menu-bar :deep(ul) li a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 2px;
    }
}
</style>
