<template>
    <ul class="floor-features-list" :class="[`layout-${layout}`]">
        <template v-if="floor.floor_details && floor.floor_details.length">
            <li v-for="(detail, dIndex) in floor.floor_details" :key="dIndex">
                <img v-if="detail.icon && showIcons" :src="detail.icon" :alt="detail.label">
                <span class="param-name">{{detail.label}}</span><span class="param">{{detail.value}}</span>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    name: "FloorFeaturesList",
    props: {
        floor: [Object],
				layout: {
						default: 'piped',
						required: false,
						type: String
				},
				showIcons: {
						default: true,
						type: Boolean,
						required: false
				}
    }
}
</script>

<style scoped lang="scss">
ul.floor-features-list {

		li {
				padding: 0.2rem 0;
				display: flex;
				align-items: center;
				flex-flow: row nowrap;
				gap: 0.9375rem;

				image {
						max-width: 23px;
						height: auto;
				}

				span.param-name {
						width: 80px;
				}

				.param {
						white-space: nowrap;
						&::before {
								content: " ";
								height: 16px;
								width: 1px;
								margin: 0 0.9375rem 0 1.875rem;
								display: inline-block;
								vertical-align: middle;
								background-color: #fff;
						}
				}
		}

		&.layout-dashed {
				li {
						gap: 2px;

						span.param-name {
								width: auto;
						}

						.param::before {
								content: " - ";
								width: auto;
								height: auto;
								margin: 0 5px;
								background-color: transparent;
								color: #fff;
						}
				}
		}
}
</style>
