<template>
	<div class="masonry-gallery">
			<div class="grid" ref="grid">
					<div v-for="(image, imageIndex) in images"
						:key="imageIndex"
						class="grid-item"
						:style="{width: itemWidth}"
					>
							<a 	:href="image.url"
								:data-pswp-width="image.width"
								:data-pswp-height="image.height"
								target="_blank"
								rel="noreferrer"
							>
								<img :src="image.thumbnail_small" :alt="image.alt">
							</a>
							<illustration-only-disclaimer></illustration-only-disclaimer>
					</div>
			</div>
	</div>
</template>

<script>
import IllustrationOnlyDisclaimer from "@/components/IllustrationOnlyDisclaimer"
import Masonry from "masonry-layout"
import imagesLoaded from "imagesloaded"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import 'photoswipe/style.css'
import {mapGetters} from "vuex"

export default {
		name: "MasonryGallery",
		components: {IllustrationOnlyDisclaimer},
		props: {
				images: {
						required: false,
						default: []
				},
				itemsPerRow: {
						required: false,
						default: 4
				}
		},
		computed: {
				itemWidth(){
						const width = (100 / this.itemsPerRow)
						return `calc(${width}%)`
				},
				...mapGetters(['sideMenuShowTitles'])
		},
		watch: {
				sideMenuShowTitles(){
						if(this.masonry){
								// items positioning after animation of 400 ms
								setTimeout(() => {
										this.masonry.layout()
								}, 500)
						}
				},
				images(){
					console.log("ImagesChanged")
					setTimeout(() => {
							this.masonry.layout()
					}, 50)
					// this.$nextTick().then(() => {
					// 		this.initPs()
					// 		this.masonry.layout()
					// })
				}
		},
		data(){
				return {
						masonry: null
				}
		},
		methods:{
				initPs(){
						if (!this.lightbox) {
								this.lightbox = new PhotoSwipeLightbox({
										gallery: '.grid',
										children: '.grid-item a',
										pswpModule: () => import('photoswipe'),
								});
								this.lightbox.init();
						}
				},
				destroyPs(){
						if (this.lightbox) {
								this.lightbox.destroy();
								this.lightbox = null;
						}
				}
		},
		mounted() {
				this.masonry = new Masonry(this.$refs.grid, {
						// options
						columnWidth: '.grid-item',
						percentPosition: true
				});

				imagesLoaded(this.$refs.grid, () => {
						this.masonry.layout()
				})

				this.$nextTick().then(() => {
						this.initPs()
						this.masonry.layout()
				})

				setTimeout(() => {
						if(this.masonry && typeof(this.masonry.layout) === "function"){
								this.masonry.layout()
						}
				}, 1000);

				setTimeout(() => {
						if(this.masonry && typeof(this.masonry.layout) === "function"){
								this.masonry.layout()
						}
				}, 5000);
		}
}
</script>

<style scoped lang="scss">
		.grid {
			:deep(.grid-item) {
					position: relative;
					width: 25%;
					padding-left: 4px;
					padding-right: 4px;
					padding-top: 4px;
				

					a {
							display: block;
					}

					img {
						border-radius: 0.3125rem;
						box-shadow: 0px 0px 3px 0px #8980BE;
					}

					.illustration-only-disclaimer {
							position: absolute;
							top: 0;
							left: 0;
							margin-left: 10px;
							margin-right: 10px;
					}
			}
		}
</style>
